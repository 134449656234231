import React from "react";
import { exists } from "i18next";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import { Text } from "../../utils/Text";

export const AlertMessage = ({ message, type, style }) => {
  const isErrorExist = exists(`VALIDATION.ERRORS.${message}`);

  if (type === "error") {
    return (
      <p
        className="error-message"
        style={{
          color: "red",
          marginTop: "6px",
          textAlign: "center",
          ...style,
        }}
      >
        {isErrorExist ? <Text tid={`VALIDATION.ERRORS.${message}`} /> : message}
      </p>
    );
  }

  if (type === "success") {
    return (
      <Stack style={style}>
        <Alert
          severity="success"
          variant="outlined"
          style={{ color: "rgb(204, 232, 205)" }}
        >
          <Text tid={message} />
        </Alert>
      </Stack>
    );
  }

  return null;
};
