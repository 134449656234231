import { axios } from "../utils/request";
import { API } from "../constants/api";

export const SETTINGS_UPDATE_PHOTO = {
  LOADING: "SETTINGS_UPDATE_PHOTO.LOADING",
  SUCCESS: "SETTINGS_UPDATE_PHOTO.SUCCESS",
  ERROR: "SETTINGS_UPDATE_PHOTO.ERROR",
  RESET: "SETTINGS_UPDATE_PHOTO.RESET",
};

const setLoading = () => ({
  type: SETTINGS_UPDATE_PHOTO.LOADING,
});

const setSuccess = () => ({
  type: SETTINGS_UPDATE_PHOTO.SUCCESS,
});

const setError = (message) => ({
  type: SETTINGS_UPDATE_PHOTO.ERROR,
  message,
});

export const resetUpdatePhoto = () => ({
  type: SETTINGS_UPDATE_PHOTO.RESET,
});

export const updatePhoto = (payload) => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .patch(API.SETTINGS_UPDATE_PHOTO, payload)
      .then(() => {
        dispatch(setSuccess());
        return { success: true };
      })
      .catch(({ response: { data } }) => {
        dispatch(setError(data.message));
        return { sucess: false };
      });
  };
};
