import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import "./index.scss";

import CoinPopUPView from "./View";
import {
  selectBalance,
  selectFiatBalance,
  selectHideZeroBalance,
} from "../../actions/balance";

const CoinPopUP = ({
  setOpacityCoinPopUP,
  setIsCoinPopUPOpen,
  isCoinPopUPOpen,
  balances,
}) => {
  const dispatch = useDispatch();

  const { balanceHideZero, balanceFiat } = useSelector(({ balance }) => ({
    balanceHideZero: balance?.isHide,
    balanceFiat: balance?.isFiat,
  }));

  useEffect(() => {
    const handleClickOutsideCoin = (event) => {
      if (
        isCoinPopUPOpen &&
        !event.target.classList.contains("coin") &&
        event.target.closest(".user-coins") === null
      ) {
        setIsCoinPopUPOpen(false);
        setOpacityCoinPopUP(0);
      }
    };

    if (isCoinPopUPOpen)
      document.addEventListener("click", handleClickOutsideCoin);
    else document.removeEventListener("click", handleClickOutsideCoin);

    return () => document.removeEventListener("click", handleClickOutsideCoin);
  }, [isCoinPopUPOpen, setIsCoinPopUPOpen, setOpacityCoinPopUP]);

  const handleSelect = (balance) => {
    dispatch(selectBalance({ balance }));
    setIsCoinPopUPOpen(false);
    setOpacityCoinPopUP(0);
  };

  const handleHideZero = () => {
    dispatch(selectHideZeroBalance());
  };

  const handleFiat = () => {
    dispatch(selectFiatBalance());
  };

  return (
    <CoinPopUPView
      balanceHideZero={balanceHideZero}
      isCoinPopUPOpen={isCoinPopUPOpen}
      handleHideZero={handleHideZero}
      handleSelect={handleSelect}
      balanceFiat={balanceFiat}
      handleFiat={handleFiat}
      balances={balances}
    />
  );
};

export default CoinPopUP;
