import { GAME_CLASSIC } from "../actions/game";
import { initSocketState } from "../utils/store";

const initialState = initSocketState;

export const game = (state = initialState, action) => {
  switch (action.type) {
    case GAME_CLASSIC.CONNECTED:
      return {
        ...state,
        connected: true,
        connecting: false,
        data: action.payload,
      };
    case GAME_CLASSIC.UPDATING:
      return { ...state, updating: true, updated: false };
    case GAME_CLASSIC.CONNECTING:
      return { ...state, connecting: true, connected: false };
    case GAME_CLASSIC.UPDATED:
      return { ...state, updating: false, updated: true, data: action.payload };
    default:
      return state;
  }
};
