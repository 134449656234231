import rg from "../assets/img/rg.jpg";

export const colors = [
  { bg: "blueBg", text: "b", main: "367AFF" },
  { bg: "greenBg", text: "g", main: "36FFDB" },
  { bg: "redBg", text: "r", main: "FF3636" },
  { bg: "yellowBg", text: "y", main: "FFD336" },
  { bg: "purpleBg", text: "p", main: "9b36ff" },
  { bg: "orangeBg", text: "o", main: "ff8c36" },
  { bg: "pinkBg", text: "pink", main: "ff36a8" },
  { bg: "limeBg", text: "lime", main: "a1ff36" },
  { bg: "dGBg", text: "dG", main: "00c900" },
  { bg: "dRbg", text: "dR", main: "910000" },
];

export const setUserColors = (users = []) => {
  return users.map((user, index) => ({
    ...user,
    color: user?.color || colors[index % colors.length].bg,
    colortext: user?.colortext || colors[index % colors.length].text,
    colorMain: user?.colorMain || colors[index % colors.length].main,
  }));
};

export const installUserInfoInGame = (game) => {
  const { members = [], bets = [] } = game;

  const membersBuff = members.map((mem, i) => ({
    ...mem,
    color: mem?.color || colors[i % colors.length].bg,
    colortext: mem?.colortext || colors[i % colors.length].text,
    colorMain: mem?.colorMain || colors[i % colors.length].main,
  }));

  return {
    ...game,
    members: membersBuff,
    bets: bets.map((bet) => {
      const mem = membersBuff.find((m) => m._id === bet?.user._id);
      return mem ? { ...bet, user: mem } : bet;
    }),
  };
};

export const users = [
  {
    name: "YOU",
    avatar: rg,
    stakeAmount: 10,
    stakeAmountDemo: 10,
  },
  {
    name: "User 2",
    avatar: rg,
    stakeAmount: 25,
    stakeAmountDemo: 25,
  },
  {
    name: "User 3",
    avatar: rg,
    stakeAmount: 45,
    stakeAmountDemo: 45,
  },
  {
    name: "User 4",
    avatar: rg,
    stakeAmount: 5,
    stakeAmountDemo: 5,
  },
  {
    name: "User 5",
    avatar: rg,
    stakeAmount: 15,
    stakeAmountDemo: 15,
  },
  {
    name: "User 6",
    avatar: rg,
    stakeAmount: 20,
    stakeAmountDemo: 20,
  },
  {
    name: "User 7",
    avatar: rg,
    stakeAmount: 30,
    stakeAmountDemo: 30,
  },
  {
    name: "User 8",
    avatar: rg,
    stakeAmount: 35,
    stakeAmountDemo: 35,
  },
  {
    name: "User 9",
    avatar: rg,
    stakeAmount: 40,
    stakeAmountDemo: 40,
  },
  {
    name: "User 10",
    avatar: rg,
    stakeAmount: 50,
    stakeAmountDemo: 50,
  },
].map((user, index) => ({
  ...user,
  color: colors[index % colors.length].bg,
  colortext: colors[index % colors.length].text,
  colorMain: colors[index % colors.length].main,
}));

const totalStakeAmount = users.reduce(
  (total, user) => total + user.stakeAmount,
  0
);

users.forEach((user) => {
  user.percent = `${(
    (user.stakeAmount / totalStakeAmount) *
    100
  ).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}%`;
});

export const updateStakeAmount = (userName, newStakeAmount) => {
  const userIndex = users.findIndex((user) => user.name === userName);
  if (userIndex !== -1) {
    users[userIndex].stakeAmount = newStakeAmount;

    const totalStakeAmount = users.reduce(
      (total, user) => total + user.stakeAmount,
      0
    );

    const maxPercent = 100 - users.length * 0.01 - 0.01;

    users.forEach((user) => {
      const percent = (user.stakeAmount / totalStakeAmount) * 100;
      user.percent = `${Math.min(percent, maxPercent).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}%`;

      if (percent < 0.01) {
        user.percent = "0.01%";
      }
    });
  }
};

export const updateStakeAmountDemo = (userName, newStakeAmount) => {
  const userIndex = users.findIndex((user) => user.name === userName);
  if (userIndex !== -1) {
    users[userIndex].stakeAmountDemo = newStakeAmount;

    const totalStakeAmount = users.reduce(
      (total, user) => total + user.stakeAmountDemo,
      0
    );

    const maxPercent = 100 - users.length * 0.01 + 0.01;

    users.forEach((user) => {
      const percent = (user.stakeAmountDemo / totalStakeAmount) * 100;
      user.percentDemo = `${Math.min(percent, maxPercent).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}%`;

      if (percent < 0.01) {
        user.percentDemo = "0.01%";
      }
    });
  }
};
