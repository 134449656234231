import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getData } from "../../utils/store";
import { fix2 } from "../../utils/fixers";
import UserView from "./View";
import { selectBalance } from "../../actions/balance";

const User = ({
  handleWalletClick,
  handleWalletSettingsClick,
  setIsActive,
  isActive,
}) => {
  const dispatch = useDispatch();

  const {
    user,
    balances,
    totalUsdt,
    balanceIsFiat,
    selectedBalance,
    selectedCurrency,
  } = useSelector(({ user: userData, balance: balanceData }) => ({
    selectedBalance: balanceData.selected,
    balanceIsFiat: balanceData.isFiat,
    user: getData(userData.data),
    balances: balanceData.data,
    totalUsdt: balanceData.totalUsdt,
    selectedCurrency: balanceData.selected,
  }));

  const [isCoinPopUPOpen, setIsCoinPopUPOpen] = useState(false);
  const [opacityCoinPopUP, setOpacityCoinPopUP] = useState(0);

  const handleToggleCoinPopUP = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsCoinPopUPOpen((prevState) => !prevState);
    setOpacityCoinPopUP((prevState) => (prevState === 0 ? 1 : 0));
  };

  const getPerformedBalances = () => {
    if (!balances?.length) return null;

    const totalBalanceItem = {
      sumUsdt: fix2(totalUsdt),
      amount: fix2(totalUsdt),
      name: "Total USD",
      code: "usd",
    };

    const balanceCurrencyCode = String(
      localStorage.getItem("bnce-selected") || ""
    );

    const performedBalances = [totalBalanceItem, ...balances];

    if (
      selectedCurrency.code !== "usd" &&
      (balanceCurrencyCode === "usd" || !balanceCurrencyCode)
    ) {
      dispatch(selectBalance({ balance: totalBalanceItem }));
    }

    return performedBalances;
  };

  const performedBalances = getPerformedBalances();

  if (!performedBalances?.length) return null;

  return (
    <UserView
      user={user}
      balances={performedBalances}
      selectedBalance={selectedBalance}
      balanceIsFiat={balanceIsFiat}
      handleWalletClick={handleWalletClick}
      handleWalletSettingsClick={handleWalletSettingsClick}
      handleToggleCoinPopUP={handleToggleCoinPopUP}
      isCoinPopUPOpen={isCoinPopUPOpen}
      setIsCoinPopUPOpen={setIsCoinPopUPOpen}
      opacityCoinPopUP={opacityCoinPopUP}
      setOpacityCoinPopUP={setOpacityCoinPopUP}
      setIsActive={setIsActive}
      isActive={isActive}
    />
  );
};

export default User;
