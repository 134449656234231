import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { signup } from "../../actions/signup";
import { AUTH_USER } from "../../constants/fields";
import {
  getErrorMessage,
  isRequestError,
  isRequestPending,
} from "../../utils/store";

import { SignUpView } from "./View";

export const SignUp = () => {
  const captchaRef = useRef();

  const [isCaptchaSuccess, setIsCaptchaSuccess] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState({
    [AUTH_USER.PASSWORD]: false,
    [AUTH_USER.CONFIRM_PASSWORD]: false,
  });

  const { isLoading, isError, errorMessage } = useSelector(({ signup }) => ({
    isLoading: isRequestPending(signup.request),
    isError: isRequestError(signup.request),
    errorMessage: getErrorMessage(signup.request),
  }));

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [fields, setFields] = useState({});

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  useEffect(() => {
    if (errorMessage === "CAPTCHA_NOT_VALID") {
      captchaRef.current.reset();

      setIsCaptchaSuccess(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    const referrer = localStorage.getItem("referrer");

    if (referrer) {
      changeField(AUTH_USER.REFERRER, referrer);
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    const token = captchaRef.current.getValue();

    // captchaRef.current.reset();

    // setIsCaptchaSuccess(false);

    await dispatch(
      signup(
        {
          [AUTH_USER.EMAIL]: fields.email,
          [AUTH_USER.NICKNAME]: fields.nickname,
          [AUTH_USER.PASSWORD]: fields.password,
          [AUTH_USER.CAPTCHA]: token,
          [AUTH_USER.REFERRER]: fields.referrer,
        },
        navigate
      )
    );
  };

  const isFormDisabled =
    !fields.email ||
    !fields.password ||
    !fields.nickname ||
    fields.nickname > 20 ||
    !isCaptchaSuccess ||
    !fields.checked ||
    fields[AUTH_USER.PASSWORD] !== fields[AUTH_USER.CONFIRM_PASSWORD];

  return (
    <SignUpView
      fields={fields}
      changeField={changeField}
      onSubmit={onSubmit}
      isFormDisabled={isFormDisabled}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      captchaRef={captchaRef}
      setIsCaptchaSuccess={setIsCaptchaSuccess}
      isPasswordVisible={passwordVisibility}
      setIsPasswordVisible={setPasswordVisibility}
    />
  );
};
