import React from "react";
import "./UserProfileView.scss";
import Loader from "../Loader";

import stat from "../../assets/img/stat.svg";

export const UserProfileView = ({
  handleClose = () => {},
  isLoading = true,
  info = {},
}) => {
  if (isLoading) {
    return (
      <div className="user-profile-wrapper">
        <div className="user-profile">
          <div className="user-profile-title">
            <div className="user-profile-title--stat">
              <p>Статистика</p>
              <p
                className="user-profile-title--stat--close"
                onClick={handleClose}
              >
                ✕
              </p>
            </div>
          </div>
          <div className="user-profile-loader">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="user-profile-wrapper">
      <div className="user-profile">
        <div className="user-profile-title">
          <div className="user-profile-title--stat">
            <div className="user-profile-title--stat--icon">
              <img src={stat} alt="stat" />
              <p>Статистика</p>
            </div>
            <p
              className="user-profile-title--stat--close"
              onClick={handleClose}
            >
              ✕
            </p>
          </div>
          <div className="user-profile--user">
            <img src={info.photo} alt="avatar" />
            <p className="user-profile-title--name">{info.name}</p>
          </div>
        </div>
        <div style={{ display: "grid", gap: 6 }}>
          <div className="user-profile-info">
            <div className="user-profile-info-main first-box">
              <div className="user-profile-info-main-info">
                <p className="user-profile-info-main-info--title">
                  Зарегистрирован
                </p>
                <p className="user-profile-info-main-info--value">
                  {info.createdAt}
                </p>
              </div>
              <div className="user-profile-info-main-stat">
                <p className="user-profile-info-main-stat--title">Всего игр</p>
                <p className="user-profile-info-main-stat--number">
                  {info.gamesCount}
                </p>
              </div>
            </div>
          </div>
          <div className="user-profile-info--grid">
            <div className="user-profile-info-main">
              <div className="user-profile-info-main-info">
                <p className="user-profile-info-main-info--title">
                  Сделано ставок
                </p>
                <p className="user-profile-info-main-info--value">
                  {info.betsCount}
                </p>
              </div>
            </div>
            <div className="user-profile-info-main">
              <div className="user-profile-info-main-info">
                <p className="user-profile-info-main-info--title">Оборот</p>
                <p className="user-profile-info-main-info--value">
                  ${info.betsTurnover}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
