import { DEPOSIT_CREATE } from "../actions/depositCreate";
import {
  initRequestState,
  initRequestWithDataState,
  setRequestError,
  setRequestPending,
  setRequestSuccess,
} from "../utils/store";

const initialState = {
  request: initRequestState(),
  data: initRequestWithDataState(),
};

export const depositCreate = (state = initialState, action) => {
  switch (action.type) {
    case DEPOSIT_CREATE.SUCCESS:
      return {
        ...state,
        data: setRequestSuccess(state.data, action.data),
      };

    case DEPOSIT_CREATE.LOADING:
      return {
        ...state,
        data: setRequestPending(state.data),
      };

    case DEPOSIT_CREATE.ERROR:
      return {
        ...state,
        data: setRequestError(state.data, action.message),
      };

    case DEPOSIT_CREATE.REQUEST_SUCCESS:
      return {
        ...state,
        data: setRequestSuccess(state.request, action.data),
      };

    case DEPOSIT_CREATE.REQUEST_LOADING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };

    case DEPOSIT_CREATE.REQUEST_ERROR:
      return {
        ...state,
        request: setRequestError(state.request, action.message),
      };

    default:
      return state;
  }
};
