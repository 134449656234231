/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";

import { currencyCodeParams } from "../../utils/currencyOptions";
import { BetModalCurrency } from "./BetModalCurrency";
import { fix2, fix8 } from "../../utils/fixers";
import { BetModalAuth } from "./BetModalAuth";
import PrimaryButton from "../PrimaryButton";
import LoaderPulse from "../LoaderPulse";
import { Text } from "../../utils/Text";
import "./BetModal.scss";

import walletIcon from "../../assets/img/Wallet.svg";

const BetButton = ({
  isDisabled,
  setTimer,
  handle,
  iValue,
  timer,
  color,
  curr,
  isFiat,
}) => {
  const handleClick = (e) => {
    if (!timer) {
      handle(color);
      setTimer(setTimeout(() => setTimer(null), 1500));
    } else e.preventDefault();
  };

  return (
    <button disabled={isDisabled || timer} onClick={handleClick}>
      {iValue}
      {isFiat ? "$" : ""}
      <img
        src={currencyCodeParams[curr?.code]?.icon}
        className="double__bet__img"
        alt=""
      />
    </button>
  );
};

export const BetModalView = ({
  isCrash,
  rangeAmountLoading,
  usdAmountLoading,
  handleIncrease,
  handleDecrease,
  handleDouble,
  balanceData,
  setCurrency,
  handleInput,
  handleHalf,
  handleMax,
  handleMin,
  usdAmount,
  isClassic,
  handleBet,
  minAmount,
  maxAmount,
  userBets,
  currency,
  errMsg,
  chance,
  input,
  user,
  err,
  isFiat,
}) => {
  const [timer, setTimer] = useState(null);

  const red = Math.round((255 * (100 - chance)) / 100);
  const green = Math.round((255 * chance) / 100);

  const backgroundColor = `rgba(${red}, ${green}, 0, 0.1)`;
  const color = `rgba(${red}, ${green}, 0, 1)`;

  if (!user?.id) {
    return (
      <div className="betmodal">
        <div className="betmodal__title">
          <img src={walletIcon} alt="" />
          <span>
            <Text tid="CLASSIC.CLASSIC_TAB.MAKE_BID" />
          </span>
        </div>
        <BetModalAuth />
      </div>
    );
  }
  return (
    <div className="betmodal">
      <div
        className="betmodal__title"
        style={{
          display: isCrash ? "none" : "flex",
        }}
      >
        <img src={walletIcon} alt="" />
        <span>
          <Text tid="CLASSIC.CLASSIC_TAB.MAKE_BID" />
        </span>
      </div>
      <div
      // style={{
      //   display: isCrash ? "none" : "flex",
      // }}
      >
        <BetModalCurrency
          setCurrency={setCurrency}
          balance={balanceData}
          currency={currency}
          isFiat={isFiat}
        />
      </div>
      <div className="betmodal__control">
        <button className="betmodal__control-button" onClick={handleHalf}>
          1/2
        </button>
        <button className="betmodal__control-button" onClick={handleDouble}>
          2x
        </button>
        <button
          className="betmodal__control-button"
          disabled={rangeAmountLoading}
          onClick={handleMin}
        >
          <Text tid="DOUBLE.DOUBLE_TAB.MIN" />
        </button>
        <button
          className="betmodal__control-button"
          disabled={rangeAmountLoading}
          onClick={handleMax}
        >
          <Text tid="DOUBLE.DOUBLE_TAB.MAX" />
        </button>
      </div>
      <div className="betmodal__control-input">
        <button className="betmodal__control-button" onClick={handleDecrease}>
          <span style={{ marginTop: -5 }}>-</span>
        </button>
        <div
          className="betmodal__control-input__input"
          onClick={() => document.getElementById("betmodal-input").focus()}
        >
          <div>
            <input
              id="betmodal-input"
              onChange={handleInput}
              style={{ pointerEvents: "none" }}
              value={input}
            />
          </div>
          <div>
            {isFiat ? (
              <span
                style={{ color: "#367aff", fontWeight: 600, fontSize: "18px" }}
              >
                $
              </span>
            ) : (
              <img
                src={currencyCodeParams[currency?.code]?.icon}
                className="double__bet__img"
                alt=""
              />
            )}
          </div>
        </div>
        <button className="betmodal__control-button" onClick={handleIncrease}>
          <span>+</span>
        </button>
      </div>
      <div className="betmodal__bets">
        {!userBets?.length
          ? null
          : userBets.map((b, i) => {
              let colorBet = b.color.toLowerCase();
              if (colorBet === "zero") colorBet = "green";
              return (
                <div
                  key={i}
                  className={`betmodal__bets__bet ${colorBet} ${
                    isClassic ? "classic" : ""
                  }`}
                >
                  <span>
                    {isFiat ? `${fix2(b.sumUsdt)}$` : fix8(b.sumToken)}
                  </span>

                  <img src={currencyCodeParams[b?.tokenCode]?.icon} alt="" />
                </div>
              );
            })}
      </div>
      <div className={`betmodal__make-bet-info ${errMsg ? "error" : ""}`}>
        {!errMsg ? (
          <div className="betmodal__make-bet-info__title">
            <Text tid="CLASSIC.CLASSIC_TAB.BET" />:{" "}
            {usdAmountLoading ? (
              <>
                &nbsp;
                <LoaderPulse _color="#74c77b" />
              </>
            ) : (
              <>{fix2(usdAmount)}$</>
            )}
          </div>
        ) : (
          <div className="betmodal__make-bet-info__error">{errMsg}</div>
        )}
        <div className="betmodal__make-bet-info__min-max">
          <p>
            <Text tid="DOUBLE.DOUBLE_TAB.MIN" />
            &nbsp;
            <span>
              {rangeAmountLoading ? <LoaderPulse /> : <>{minAmount}</>}
              {isFiat ? (
                " $"
              ) : (
                <img
                  src={currencyCodeParams[currency?.code]?.icon}
                  className="double__bet__img"
                  alt=""
                />
              )}
            </span>
          </p>
          <p>
            <Text tid="DOUBLE.DOUBLE_TAB.MAX" />
            &nbsp;
            <span>
              {rangeAmountLoading ? <LoaderPulse /> : <>{maxAmount}</>}
              {isFiat ? (
                " $"
              ) : (
                <img
                  src={currencyCodeParams[currency?.code]?.icon}
                  className="double__bet__img"
                  alt=""
                />
              )}
            </span>
          </p>
        </div>
      </div>

      <div
        className={`betmodal__make-bet-buttons ${
          isCrash ? "crash" : !isClassic ? "double" : "classic"
        }`}
      >
        {isCrash ? (
          <PrimaryButton
            isCrash={isCrash}
            iValue={input}
            isFiat={isFiat}
            curr={currency}
          />
        ) : isClassic ? (
          <>
            <div style={{ color, backgroundColor }}>
              <Text tid="MAIN.MAIN.CHANCE" /> {chance}%
            </div>
            <button disabled={err} onClick={handleBet}>
              <Text tid="MAIN.MAIN.SAVE_BET" />
            </button>
          </>
        ) : (
          <>
            <BetButton
              isDisabled={err}
              setTimer={setTimer}
              handle={handleBet}
              curr={currency}
              iValue={input}
              timer={timer}
              isFiat={isFiat}
              color="red"
            />
            <BetButton
              isDisabled={err}
              curr={currency}
              setTimer={setTimer}
              handle={handleBet}
              iValue={input}
              timer={timer}
              isFiat={isFiat}
              color="green"
            />
            <BetButton
              isDisabled={err}
              curr={currency}
              iValue={input}
              setTimer={setTimer}
              handle={handleBet}
              timer={timer}
              isFiat={isFiat}
              color="black"
            />
          </>
        )}
      </div>
    </div>
  );
};
