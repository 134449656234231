import { axios, setAuthorization } from "../utils/request";

import { API } from "../constants/api";
import { ROUTES } from "../constants/routes";
import { convertRecoveryAccountUpdateData } from "../api/recovery";
import { setAuthData } from "./auth";
import { getUser } from "./user";

export const RECOVERY_ACCOUNT_UPDATE = {
  LOAD_SUCCESS: "RECOVERY_ACCOUNT_UPDATE.LOAD_SUCCESS",
  LOAD_PENDING: "RECOVERY_ACCOUNT_UPDATE.LOAD_PENDING",
  LOAD_FAIL: "RECOVERY_ACCOUNT_UPDATE.LOAD_FAIL",
};

const setFail = (message) => ({
  type: RECOVERY_ACCOUNT_UPDATE.LOAD_FAIL,
  message,
});

const setSuccess = () => ({
  type: RECOVERY_ACCOUNT_UPDATE.LOAD_SUCCESS,
});

const setLoading = () => ({
  type: RECOVERY_ACCOUNT_UPDATE.LOAD_PENDING,
});

export const sendRecoveryAccountUpdate = (payloadData, navigate) => {
  return async (dispatch) => {
    dispatch(setLoading());
    const payload = convertRecoveryAccountUpdateData(payloadData);

    try {
      const { data } = await axios.post(API.RECOVERY_ACCOUNT_UPDATE, payload);
      dispatch(setSuccess());
      setAuthorization(data.accessToken);
      dispatch(setAuthData(data.accessToken));
      dispatch(getUser());
      navigate(ROUTES.HOME);
    } catch ({ response: { data = {} } = { response: { data: {} } } }) {
      dispatch(setFail(data.message));
    }
  };
};
