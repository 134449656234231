import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import React from "react";

import { Text } from "../../utils/Text";
import "./index.scss";
import { HELP } from "../../constants/help";

export const FaqView = () => {
  return (
    <div className="question-answer">
      <h1>
        <Text tid="MAIN.MAIN.FAQ.TITLE" />
      </h1>
      <div className="question-answer-accod">
        <Accordion>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography>
              <Text tid="MAIN.MAIN.FAQ.F1_TITLE" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Text tid="MAIN.MAIN.FAQ.F1_DESC" />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography>
              <Text tid="MAIN.MAIN.FAQ.F2_TITLE" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Text tid="MAIN.MAIN.FAQ.F2_DESC" />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography>
              <Text tid="MAIN.MAIN.FAQ.F3_TITLE" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Text tid="MAIN.MAIN.FAQ.F3_DESC" />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography>
              <Text tid="MAIN.MAIN.FAQ.F4_TITLE" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Text
                tid="MAIN.MAIN.FAQ.F4_DESC"
                values={{ email: HELP.SUPPORT }}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography>
              <Text tid="MAIN.MAIN.FAQ.F5_TITLE" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Text tid="MAIN.MAIN.FAQ.F5_DESC" />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
