import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "../../utils/Text";
import PrimaryButton from "../../components/PrimaryButton";
import { AlertMessage } from "../../components/AlertMessage";
import NetworkSelect from "../NetworkSelect";
import CurrencySelect from "../CurrencySelect";
import { networkParams } from "../../utils/currencyOptions";

const WithdrawView = ({
  copyRef,
  address,
  setAddress,
  handleSubmit,
  loading,
  error,
  errorMessage,
  disabled,
  opacity,
  zIndex,
  handleBackgroundClick,
  data,
}) => {
  const { t } = useTranslation();

  const network = { code: "trc20", ...networkParams.trc20 };
  const currency = { code: "usdt", name: "USDT" };

  return (
    <div
      className="bg"
      style={{ opacity, zIndex }}
      onClick={handleBackgroundClick}
      role="button"
      tabIndex={0}
    >
      <div className="wallet" style={{ opacity, zIndex }}>
        <div className="wallet__container">
          <form onSubmit={handleSubmit}>
            <div className="wallet__box">
              <div className="wallet__currency-box">
                <div className="wallet__currency">
                  <p className="wallet__currency-text">
                    <Text tid="MAIN.MAIN.CURRENCY" />
                  </p>
                  <CurrencySelect
                    handleSelect={() => {}}
                    selectedBalance={currency}
                    balances={[]}
                  />
                </div>
                <div className="wallet__currency">
                  <p className="wallet__currency-text">
                    <Text tid="MAIN.MAIN.NETWORK" />
                  </p>
                  <NetworkSelect
                    handleSelectNet={() => {}}
                    selectedNet={network}
                    nets={[]}
                  />
                </div>
              </div>
              <div className="wallet__adress">
                <p className="wallet__adress-text">
                  <Text tid="WALLET.WALLET_POPUP.WITHDRAW_ADDRESS" />
                </p>
                <input
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="wallet__adress-box"
                  placeholder={t(
                    "WALLET.WALLET_POPUP.WITHDRAW_ADDRESS_PLACEHOLDER"
                  )}
                  role="button"
                  tabIndex={0}
                />
              </div>
              <div className="wallet__adress">
                <p
                  className="wallet__adress-text"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    <Text tid="WALLET.WALLET_POPUP.WITHDRAW_AMOUNT" />
                  </span>
                </p>

                <input
                  value={data.totalUsdt}
                  className="wallet__adress-box input-with-max-button"
                  ref={copyRef}
                  role="button"
                  tabIndex={0}
                  placeholder={t("WALLET.WALLET_POPUP.ENTER_WITHDRAW_AMOUNT")}
                  disabled
                  readOnly
                />
              </div>
            </div>
            <div
              className="wallet__footer"
              style={{
                boxSizing: "border-box",
              }}
            >
              <PrimaryButton
                className="wallet__footer-btn"
                type="submit"
                disabled={disabled}
                loading={loading}
              >
                <p className="wallet__footer-btn-text">
                  <Text tid="WALLET.WALLET_POPUP.WITHDRAW" />
                </p>
              </PrimaryButton>
              {error && (
                <AlertMessage
                  message={errorMessage}
                  type="error"
                  style={{ marginTop: "16px" }}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WithdrawView;
