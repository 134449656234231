import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../constants/routes";
import { sendRecoveryAccountUpdate } from "../../actions/recoveryAccountUpdate";
import { RECOVERY_ACCOUNT_UPDATE } from "../../constants/fields";
import {
  getErrorMessage,
  isRequestError,
  isRequestPending,
  getData,
} from "../../utils/store";

import { RecoveryAccountUpdateView } from "./View";

export const RecoveryAccountUpdate = () => {

  const [passwordVisibility, setPasswordVisibility] = useState({
    [RECOVERY_ACCOUNT_UPDATE.PASSWORD]: false,
    [RECOVERY_ACCOUNT_UPDATE.REPEAT_PASSWORD]: false,
  });

  const { isLoading, isError, errorMessage, recoveryData } = useSelector(
    ({ recoveryAccountUpdate, recoveryAccountConfirm }) => ({
      isLoading: isRequestPending(recoveryAccountUpdate.request),
      isError: isRequestError(recoveryAccountUpdate.request),
      errorMessage: getErrorMessage(recoveryAccountUpdate.request),
      recoveryData: getData(recoveryAccountConfirm.request),
    })
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [fields, setFields] = useState({});

  useEffect(() => {
    if (
      !recoveryData[RECOVERY_ACCOUNT_UPDATE.CODE] ||
      !recoveryData[RECOVERY_ACCOUNT_UPDATE.EMAIL]
    ) {
      navigate(ROUTES.RECOVERY_ACCOUNT_RESET);
    }
  }, []);

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    await dispatch(
      sendRecoveryAccountUpdate(
        {
          code: recoveryData[RECOVERY_ACCOUNT_UPDATE.CODE],
          email: recoveryData[RECOVERY_ACCOUNT_UPDATE.EMAIL],
          password: fields[RECOVERY_ACCOUNT_UPDATE.PASSWORD],
        },
        navigate
      )
    );
  };

  const isFormDisabled =
    !fields[RECOVERY_ACCOUNT_UPDATE.PASSWORD] ||
    !fields[RECOVERY_ACCOUNT_UPDATE.REPEAT_PASSWORD] ||
    fields[RECOVERY_ACCOUNT_UPDATE.PASSWORD] !==
      fields[RECOVERY_ACCOUNT_UPDATE.REPEAT_PASSWORD];

  return (
    <RecoveryAccountUpdateView
      fields={fields}
      changeField={changeField}
      onSubmit={onSubmit}
      isFormDisabled={isFormDisabled}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      passwordVisibility={passwordVisibility}
      setPasswordVisibility={setPasswordVisibility}
    />
  );
};
