import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { chatConnect, chatSendMessage } from "../../actions/chat";

import "./index.scss";

import { ChatView } from "./View";
import { ROUTES } from "../../constants/routes";

const Chat = ({ setIsChatOpened, isChatOpened }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [messageValue, setMessageValue] = useState("");

  const {
    isConnecting,
    isConnected,
    chatData,
    auth,
    socket: st,
  } = useSelector(({ chat, auth: authData, socket }) => ({
    isConnecting: chat.connecting,
    isConnected: chat.connected,
    chatData: chat.data,
    auth: authData,
    socket,
  }));

  const { pathname } = useLocation();

  const isChatVisible =
    pathname === ROUTES.HOME ||
    pathname === ROUTES.CLASSIC ||
    pathname === ROUTES.DOUBLE ||
    pathname === ROUTES.CARDS ||
    pathname === ROUTES.CRASH;

  const scrollToBottom = () => {
    const list = document.getElementById("chat-messages");

    if (list) {
      list.scrollTo(0, list.scrollHeight);
    }
  };

  useLayoutEffect(() => {
    if (isChatOpened) {
      scrollToBottom();
    }
  }, [chatData?.messages, isChatOpened]);

  useEffect(() => {
    if (isChatOpened && !isConnecting && !isConnected) {
      dispatch(chatConnect({ socket: st }));
    }
  }, [isChatOpened, isConnecting, isConnected]);

  const handleSendMessage = (e) => {
    e.preventDefault();

    if (auth.logged) {
      let v = messageValue;
      v = v.replace(/\\n/g, "");
      v = v.replace(/\s/g, "");
      if (!v.length) return setMessageValue("");
      dispatch(chatSendMessage({ message: messageValue }));
      setMessageValue("");
    } else {
      navigate(ROUTES.LOGIN);
    }
  };

  if (!isChatVisible) {
    setIsChatOpened(false);
    return null;
  }

  return (
    <ChatView
      messageValue={messageValue}
      setMessageValue={setMessageValue}
      isChatOpened={isChatOpened}
      setIsChatOpened={setIsChatOpened}
      handleSendMessage={handleSendMessage}
      messages={chatData?.messages}
      auth={auth}
    />
  );
};

export default Chat;
