import meta from "./meta.json";
import airdrop from "./airdrop.json";
import double from "./double.json";
import classic from "./classic.json";
import auth from "./auth.json";
import main from "./main.json";
import wallet from "./wallet.json";
import settings from "./settings.json";
import validation from "./validation.json";
import cards from "./cards.json";
import crash from "./crash.json";

const translation = {
  translation: {
    META: meta,
    AIRDROP: airdrop,
    DOUBLE: double,
    CLASSIC: classic,
    AUTH: auth,
    MAIN: main,
    WALLET: wallet,
    SETTINGS: settings,
    VALIDATION: validation,
    CARDS: cards,
    CRASH: crash,
  },
};

export default translation;
