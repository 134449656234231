/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import Select from "react-select";
import "./index.scss";

import { currencyCodeParams } from "../../utils/currencyOptions";

const CustomOption = ({ innerProps, data }) => (
  <div
    {...innerProps}
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "95%",
      padding: "2.5px 3px",
      gap: "10px",
      fontSize: "13px",
      color: "#fff",
      cursor: "pointer",
      marginRight: "0px",
    }}
    className="option"
  >
    {data.name}
    <img
      src={currencyCodeParams[data.code].icon}
      className="currency-icon"
      alt=""
    />
  </div>
);

const CustomSingleValue = ({ innerProps, data }) => (
  <div
    className="custom-single-value"
    {...innerProps}
    style={{
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%",
      gap: "10px",
      position: "relative",
      top: "-8px",
      color: "#fff",
    }}
  >
    <p>{data?.name}</p>
    <img
      style={{
        paddingBottom: "2px",
      }}
      src={currencyCodeParams[data?.code]?.icon}
      alt=""
      className="currency-icon"
    />
  </div>
);

const CurrencySelect = ({ balances, selectedBalance, handleSelect }) => {
  const { t } = useTranslation();

  useEffect(() => {}, [selectedBalance]);

  return (
    <Select
      styles={{
        control: (base) => ({
          ...base,
          width: "100%",
          backgroundColor: "#242424",
          border: "none",
          "&:hover": {
            opacity: "0.8",
          },
        }),
        menu: (base) => ({
          ...base,
          borderRadius: "5px",
          marginTop: "10px",
          boxShadow: "none",
          border: "solid 1px #367AFF",
          backgroundColor: "#242424",
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isSelected ? "#F5F5F5" : "#F5F5F5",
          color: "#000000",
          "&:hover": {
            opacity: "0.8",
            color: "#367AFF",
          },
          "&:active": {
            backgroundColor: "#F5F5F5",
            color: "#000000",
          },
        }),
        placeholder: (base) => ({
          ...base,
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          height: "100%",
          gap: "10px",
        }),
      }}
      options={balances}
      className="wallet__currency-select-box"
      onChange={handleSelect}
      placeholder={t("CLASSIC.CLASSIC_TAB.SELECT")}
      value={selectedBalance}
      isSearchable={false}
      components={{
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
    />
  );
};

export default CurrencySelect;
