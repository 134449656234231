/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CircularProgressComponent from "@mui/material/CircularProgress";
import "./index.scss";
import { GAME_STATUSES } from "../../constants/games";

const WheelView = ({
  handleAnimationEnd,
  // timeBeforePause,
  isAlreadyInGame,
  numeroCasillas,
  anguloCasillas,
  getRandomColor,
  tamanyoRuleta,
  alturaCasilla,
  shouldMove,
  // isSpin,
  isRecruit,
  ruletaRef,
  winNumber,
  gameData,
  timeLeft,
}) => {
  const [isPlayed, setIsPlayed] = useState(false);

  const recrStep = gameData?.doubleSteps?.[GAME_STATUSES.RECRUITED] || 0;
  const spinStep = gameData?.doubleSteps?.[GAME_STATUSES.SPIN] || 13;

  let transitionSec = spinStep - recrStep;
  const laps = transitionSec * 1.5 + 0.5;

  const winPosition = 360 * laps - anguloCasillas * winNumber;

  const restartTime = 3;
  const restartPosition = 360 * laps;
  const isRestartStep = recrStep - timeLeft < recrStep - restartTime;

  if (isRestartStep && isPlayed) transitionSec = restartTime - 1;
  else if (isRecruit) transitionSec = 0;

  if (!isAlreadyInGame) transitionSec = 0;

  const check = () => {
    if (isPlayed && !isRestartStep && isRecruit) setIsPlayed(false);
    if (!isPlayed && !isRecruit) setIsPlayed(true);
  };

  useEffect(() => {
    check();
  }, [gameData?._id, isRecruit, isRestartStep]);

  return (
    <div className="wheel">
      <div className="wheel__container">
        <div className="wheel__double">
          <div className="contenedor-ruletaWheel">
            <div
              ref={ruletaRef}
              className={`ruletaWheel `}
              onAnimationEnd={handleAnimationEnd}
              style={{
                width: `${tamanyoRuleta}px`,
                height: `${tamanyoRuleta}px`,
                "--borderBottomWidth": `${alturaCasilla / 8}px`,
                "--borderRightWidth": `${tamanyoRuleta / 8}px`,
                "--borderLeftWidth": `${tamanyoRuleta / 8}px`,
                "--transitionDuration": `${transitionSec}s`,
              }}
            >
              <div className="wheel-colors-wrapper">
                {Array.from({ length: numeroCasillas }).map((_, i) => {
                  const beforeContent = `"${i}"`;
                  let pos = anguloCasillas * i;

                  if (shouldMove && !isRecruit) {
                    if (isAlreadyInGame) pos += winPosition;
                    else pos += winPosition;
                  } else if (isRecruit && isRestartStep) pos += restartPosition;
                  return (
                    <div
                      key={i}
                      className={`opcionWheel opcionWheel-${i}`}
                      style={{
                        borderBottomColor: getRandomColor(i),
                        transform: `rotate(${pos}deg)`,
                      }}
                    >
                      <style>
                        {`.opcionWheel-${i}::before {content: ${beforeContent}}`}
                      </style>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="ruletaWheel__center">
            <div className="ruletaWheel__center__text">
              <div className="ruletaWheel__center__leader">
                <div className="ruletaWheel__center__timer">
                  {isRecruit ? (
                    <span>{timeLeft}</span>
                  ) : (
                    <CircularProgress size={60} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CircularProgress = styled(CircularProgressComponent)`
  & svg {
    color: #367aff;
  }
`;

export default WheelView;
