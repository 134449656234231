import React from "react";
import "./index.scss";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

import { Text } from "../../utils/Text";

const HeaderTopDouble = () => {
  return (
    <div className="load">
      <div className="load__container">
        <div style={{ width: "100%" }}>
          <div className="load__items">
            <div className="load__item-box">
              <NavLink
                exact
                to={ROUTES.DOUBLE}
                className="load__item game"
                activeClassName="active"
              >
                <Text tid="DOUBLE.DOUBLE_TAB.DOUBLE_GAME" />
              </NavLink>
            </div>
            <div className="load__items-box">
              <div className="load__item active">
                <Text tid="MAIN.MAIN.TODAY" />
              </div>
              <div className="load__item">
                <Text tid="MAIN.MAIN.YESTERDAY" />
              </div>
              <div className="load__item">
                <Text tid="MAIN.MAIN.LAST_YEAR" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopDouble;
