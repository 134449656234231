import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import React, { useEffect, useLayoutEffect } from "react";

import { ReduxWrapper } from "./containers/redux-wrapper";
import { AppRoutes } from "./containers/routes";
import { Onload } from "./containers/onload";
import { AppGateway } from "./AppGateway";

import "./utils/normalize.scss";
import "./utils/i18n";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
  typography: { fontFamily: ["Montserrat", "sans-serif"].join(",") },
  colors: { primary: "#367aff" },
});

function App() {
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);

    const referrer = search.get("ref");

    if (referrer) {
      localStorage.setItem("referrer", referrer);
    }
  }, []);

  useLayoutEffect(() => {
    function setVh() {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight}px`
      );
    }

    setVh();

    window.addEventListener("resize", setVh);

    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet>
        <title>{t("META.HOME.TITLE")}</title>
        <meta name="description" content={t("META.HOME.DESCRIPTION")} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <div className="App">
          <ReduxWrapper>
            <AppGateway />
            <Onload>
              <AppRoutes />
            </Onload>
          </ReduxWrapper>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
