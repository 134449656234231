import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SettingsPartnerApplicationView } from "./View";

import {
  resetSubmitApplication,
  submitApplication,
} from "../../actions/settingsPartnerApplication";
import { PARTNER_APPLICATION } from "../../constants/fields";
import {
  getData,
  getErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../utils/store";

export const SettingsPartnerApplication = () => {
  const { isLoading, isSuccess, isError, errorMessage, user } = useSelector(
    ({ settingsPartnerApplication, user: userData }) => ({
      isLoading: isRequestPending(settingsPartnerApplication.request),
      isSuccess: isRequestSuccess(settingsPartnerApplication.request),
      isError: isRequestError(settingsPartnerApplication.request),
      errorMessage: getErrorMessage(settingsPartnerApplication.request),
      user: getData(userData.data),
    })
  );

  const dispatch = useDispatch();

  const [fields, setFields] = useState({});

  useEffect(() => {
    return () => {
      dispatch(resetSubmitApplication());
    };
  }, []);

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    await dispatch(
      submitApplication({
        [PARTNER_APPLICATION.DESCRIPTION]: fields.description,
        [PARTNER_APPLICATION.TELEGRAM]: fields.telegram,
      })
    );
  };

  const isFormDisabled = !fields.description || !fields.telegram;

  return (
    <SettingsPartnerApplicationView
      fields={fields}
      changeField={changeField}
      disabled={isFormDisabled}
      onSubmit={onSubmit}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      success={isSuccess}
    />
  );
};
