import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import BidPopUpView from "./View";

const BidPopUp = ({
  setOpacityBidPopUp,
  opacityBidPopUp,
  setCurrency,
  currency,
}) => {
  const { balances } = useSelector(({ balance: balancesData }) => ({
    balances: balancesData?.data,
  }));

  const handleBackgroundClick = (event) => {
    if (event.target.classList.contains("bg")) setOpacityBidPopUp(0);
  };

  const zIndex = opacityBidPopUp === 1 ? 99999 : -1;

  useEffect(() => {
    if (balances?.length && !currency) setCurrency(balances[0]);
  }, [balances?.length]);

  useEffect(() => {
    if (balances?.length && currency?.code) {
      const curr = currency;
      const findedBalance = balances.find((b) => b.code === curr.code);
      setCurrency(findedBalance);
    }
  }, [balances]);

  return (
    <BidPopUpView
      handleBackgroundClick={handleBackgroundClick}
      setOpacityBidPopUp={setOpacityBidPopUp}
      opacityBidPopUp={opacityBidPopUp}
      zIndex={zIndex}
    />
  );
};

export default BidPopUp;
