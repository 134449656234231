import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { login } from "../../actions/login";
import { AUTH_USER } from "../../constants/fields";
import {
  getErrorMessage,
  isRequestError,
  isRequestPending,
} from "../../utils/store";

import { LogInView } from "./View";

export const LogIn = () => {
  const captchaRef = useRef();

  const [isCaptchaSuccess, setIsCaptchaSuccess] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const { isLoading, isError, errorMessage } = useSelector(({ login }) => ({
    isLoading: isRequestPending(login.request),
    isError: isRequestError(login.request),
    errorMessage: getErrorMessage(login.request),
  }));

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [fields, setFields] = useState({});

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  useEffect(() => {
    if (errorMessage === "CAPTCHA_NOT_VALID") {
      captchaRef.current.reset();

      setIsCaptchaSuccess(false);
    }
  }, [errorMessage]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const token = captchaRef.current.getValue();

    // captchaRef.current.reset();

    // setIsCaptchaSuccess(false);

    await dispatch(
      login(
        {
          [AUTH_USER.EMAIL]: fields.email,
          [AUTH_USER.PASSWORD]: fields.password,
          [AUTH_USER.CAPTCHA]: token,
        },
        navigate
      )
    );
  };

  const isFormDisabled = !fields.email || !fields.password || !isCaptchaSuccess;

  return (
    <LogInView
      fields={fields}
      changeField={changeField}
      onSubmit={onSubmit}
      isFormDisabled={isFormDisabled}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      captchaRef={captchaRef}
      setIsCaptchaSuccess={setIsCaptchaSuccess}
      isPasswordVisible={passwordVisibility}
      setIsPasswordVisible={setPasswordVisibility}
    />
  );
};
