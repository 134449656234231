import { axios } from "../utils/request";
import { API } from "../constants/api";

export const SETTINGS_PARTNER_DATA = {
  LOADING: "SETTINGS_PARTNER_INFO.LOADING",
  SUCCESS: "SETTINGS_PARTNER_INFO.SUCCESS",
  ERROR: "SETTINGS_PARTNER_INFO.ERROR",
};

const setLoading = () => ({
  type: SETTINGS_PARTNER_DATA.LOADING,
});

const setSuccess = (data) => ({
  type: SETTINGS_PARTNER_DATA.SUCCESS,
  data,
});

const setError = (message) => ({
  type: SETTINGS_PARTNER_DATA.ERROR,
  message,
});

export const getPartnerInfo = () => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .get(API.USER_PARTNER_DATA)
      .then(({ data }) => dispatch(setSuccess(data)))
      .catch(({ response: { data } }) => dispatch(setError(data.message)));
  };
};
