import React from "react";
import "./index.scss";

const LineChanceView = ({ members, totalPercent, maxFlexBasis }) => {
  return (
    <div
      className="chance__line"
      style={{
        display: "flex",
      }}
    >
      {members.map((member, i) => (
        <div
          key={i}
          style={{
            flexBasis:
              // `${(parseInt(user.percent) / totalPercent) * 100}%`,
              `${Math.max(
                Math.min((member.chance / totalPercent) * 100, maxFlexBasis),
                1
              )}%`,
            background: `#${member.colorMain}`,
          }}
        />
      ))}
    </div>
  );
};

export default LineChanceView;
