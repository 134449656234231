import React from "react";
import Join from "../../components/Join";
import Roulette from "../Roulette";
import LoadLine from "../LoadLine";
import "./roulette.scss";

export const ClassicGameRoulette = ({
  isAlredyInGame,
  shouldMove,
  gameData,
}) => {
  return (
    <div className="classic-wrapper__roulette">
      <Roulette
        isAlredyInGame={isAlredyInGame}
        shouldMove={shouldMove}
        gameData={gameData}
      />
      <LoadLine gameData={gameData} />
      <Join gameData={gameData} />
    </div>
  );
};
