import React from "react";

import "./index.scss";

import { Text } from "../../utils/Text";

import parachute from "../../assets/img/pr.svg";
import PrizeAmount from "../../components/PrizeAmount";
import PrimaryButton from "../../components/PrimaryButton";
import { SettingsReferralLink } from "../settings-referral-link";
import { AirdropPromo } from "./Promo";

const AirdropWheelView = ({
  Prizes,
  tamanyoRuleta,
  anguloCasillas,
  alturaCasilla,
  getRandomColor,
  isSpinning,
  availableSpins,
  prizeAmount,
  showPopup,
  handleAnimationEnd,
  startAnimation,
  setShowPopup,
}) => {
  return (
    <div className="AirdropWheel">
      <div className="AirdropWheel__container">
        <div className="AirdropWheel__double">
          <div className="contenedor-ruleta">
            <div
              className={`ruleta `}
              onAnimationEnd={handleAnimationEnd}
              style={{
                width: `${tamanyoRuleta}px`,
                height: `${tamanyoRuleta}px`,
              }}
            >
              {Prizes.map((prize, i) => {
                const beforeContent = `"${prize.amount}"`;
                return (
                  <div
                    key={i}
                    className={`opcionAirdrop opcionAirdrop-${i}`}
                    data-content={prize.number}
                    data-ancho={`${tamanyoRuleta / 2}px`}
                    data-line={`${tamanyoRuleta / 2}px`}
                    style={{
                      transform: `rotate(${anguloCasillas * i}deg)`,
                      borderBottomColor: getRandomColor(i),
                      borderBottomWidth: `${alturaCasilla / 2}px`,
                      borderRightWidth: `${tamanyoRuleta / 2}px`,
                      borderLeftWidth: `${tamanyoRuleta / 2}px`,
                      transitionDuration: "3s",
                    }}
                  >
                    <style>
                      {`.opcionAirdrop-${i}::before {content: ${beforeContent}}`}
                    </style>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="Airdropruleta__centerAirdorp">
            <div className="Airdropruleta__centerAirdorp__img">
              <img src={parachute} alt="" />
            </div>
          </div>
        </div>
        <div className="AirdropWheel__buttonBox">
          <PrimaryButton
            className="AirdropWheel__button"
            disabled={isSpinning}
            onClick={startAnimation}
          >
            <span
              style={{
                position: "relative",
                zIndex: 1,
                width: "205px",
                padding: "10px 30px",
              }}
            >
              <Text tid="AIRDROP.AIRDROP.SPIN" />
            </span>
          </PrimaryButton>
        </div>
        <div className="AirdropWheel__counter-text">
          <div className="AirdropWheel__counter">
            <Text tid="AIRDROP.AIRDROP.AVAILABLE" /> : <p>{availableSpins}</p>
          </div>
        </div>
      </div>
      <div className="AirdropWheel__referral">
        <div className="ref__adress">
          <SettingsReferralLink withReferralInfo={false} />
          <AirdropPromo />
          <ul className="ref__adress-list">
            <p className="ref__adress-list-title">
              <Text tid="AIRDROP.AIRDROP.INSTRUCTIONS" />
            </p>
            <li className="ref__adress-list-item">
              1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Justo
              nec ultrices dui sapien eget. Gravida in fermentum et
            </li>
            <li className="ref__adress-list-item">
              2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Justo
              nec ultrices dui sapien eget. Gravida in fermentum et
            </li>
            <li className="ref__adress-list-item">
              3. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Justo
              nec ultrices dui sapien eget. Gravida in fermentum et
            </li>
          </ul>
        </div>
      </div>
      {showPopup && (
        <PrizeAmount
          prizeAmount={prizeAmount}
          setShowPopup={setShowPopup}
          showPopup={showPopup}
        />
      )}
    </div>
  );
};

export default AirdropWheelView;
