/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import "./index.scss";
import { Text } from "../../utils/Text";
import { fix2 } from "../../utils/fixers";

const TicketView = ({ formattedTime, winTicket, totalBank, winner }) => {
  return (
    <div className="ticket">
      <div className="ticket-info ticket-info__winner">
        <div className="ticket-info__winner--wrapper">
          <p className="ticket-info__winner--title">
            <Text tid="CLASSIC.CLASSIC_TAB.WINNER" />:
          </p>
          <p
            className="ticket-info__winner--name"
            style={{ color: `#${winner?.colorMain}` }}
          >
            {winner?.nickname}
          </p>
        </div>
        <div className="ticket-info__winner--amount">
          <div>${fix2(totalBank)}</div>
        </div>
      </div>
      <div className="ticket-info ticket-info__ticket">
        <p className="ticket-info__ticket--title">
          <Text tid="CLASSIC.CLASSIC_TAB.WINNING_TICKET" />:
        </p>
        <p className="ticket-info__ticket--number">#{winTicket}</p>
      </div>
      <div className="ticket-info ticket-info__timer">
        <div>{formattedTime}</div>
      </div>
    </div>
  );
};

export default TicketView;
