import React from "react";
import { useTranslation } from "react-i18next";

import "./index.scss";

import { Text } from "../../utils/Text";
import { SETTINGS } from "../../constants/fields";
import PrimaryButton from "../../components/PrimaryButton";
import { AlertMessage } from "../../components/AlertMessage";

export const SettingsUpdatePhotoView = ({
  photo,
  changePhoto,
  disabled,
  onSubmit,
  loading,
  error,
  errorMessage,
  success,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div>
        <p className="settings-security__password-box-title">
          <Text tid="SETTINGS.SETTINGS_TAB.PHOTO" />
        </p>
        <form className="settings-security__password-box" onSubmit={onSubmit}>
          <div className="settings-photo-container">
            <input type="file" className="file-input" onChange={changePhoto} />
            <img className="settings-photo" src={photo.preview} />
          </div>

          {photo.data ? (
            <PrimaryButton
              type="submit"
              disabled={disabled}
              loading={loading}
              // className="settings-security__password-box-button button"
            >
              <span>
                <Text tid="MAIN.MAIN.SAVE" />
              </span>
            </PrimaryButton>
          ) : (
            <PrimaryButton
              style={{ position: "relative" }}
              // className="settings-security__password-box-button button"
            >
              <input
                type="file"
                className="file-input"
                onChange={changePhoto}
              />
              <span>
                <Text tid="SETTINGS.SETTINGS_TAB.CHANGE_PHOTO" />
              </span>
            </PrimaryButton>
          )}

          {error && <AlertMessage message={errorMessage} type="error" />}
          {success && (
            <AlertMessage
              message="SETTINGS.SETTINGS_TAB.UPDATE_PHOTO_SUCCESS"
              type="success"
            />
          )}
        </form>
      </div>
    </React.Fragment>
  );
};
