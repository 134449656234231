import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import React from "react";
import { BetModalCurrency } from "../BetModal/BetModalCurrency";
import { currencyCodeParams } from "../../utils/currencyOptions";
import { BetModalAuth } from "../BetModal/BetModalAuth";
import walletIcon from "../../assets/img/Wallet.svg";
import { fix2, fix8 } from "../../utils/fixers";
import { ROUTES } from "../../constants/routes";
import PrimaryButton from "../PrimaryButton";
import { CardHashPopup } from "../CardHash";
import { Text } from "../../utils/Text";
import "./index.scss";


const BetCardsGame = ({ setCurrency, currency }) => {
  const { balanceData, gameData, isFiat, user } = useSelector(
    ({ game, balance, auth }) => ({
      gameTotalBank: game?.data?.totalBank,
      balanceData: balance?.data,
      isFiat: balance?.isFiat,
      isHide: balance?.isHide,
      gameData: game?.data,
      user: auth?.user,
    })
  );

  return (
    <div className="BetCardsGame">
      <div className="BetCardsGame__container">
        {user?.id ? (
          <BetModalCurrency
            setCurrency={setCurrency}
            balance={balanceData}
            currency={currency}
            isFiat={isFiat}
          />
        ) : (
          <div className="betmodal">
            <div className="betmodal__title">
              <img src={walletIcon} alt="" />
              <span>
                <Text tid="CLASSIC.CLASSIC_TAB.MAKE_BID" />
              </span>
            </div>
            <BetModalAuth />
          </div>
        )}
        <div className="BetCardsGame__costCard">
          <span className="BetCardsGame__costCard__title">
            <Text tid="CARDS.CARDS_TAB.COST_CARD" />
          </span>
          {user?.id ? (
            <div className="BetCardsGame__costCard__img">
              {isFiat ? `${fix2(currency?.sumUsdt)}$` : fix8(currency?.amount)}

              <img src={currencyCodeParams[currency?.code]?.icon} alt="card" />
            </div>
          ) : (
            <div className="BetCardsGame__costCard__img">{`${fix2(10)}$`}</div>
          )}
        </div>
        <CardHashPopup roundHash={gameData.roundHash} />
        <div className="BetCardsGame__ads">
          <div className="BetCardsGame__ads__texBox">
            <span className="BetCardsGame__ads__text">
              <Text tid="CARDS.CARDS_TAB.HAVE_AIR" />
            </span>
            <span className="BetCardsGame__ads__subtext">
              <Text tid="CARDS.CARDS_TAB.TRY_LUCK" />
              <span className="BetCardsGame__ads__textgradient">
                CryptoFast!
              </span>
            </span>
          </div>
          <div className="BetCardsGame__ads__buttonBox">
            {/* <span className="BetCardsGame__ads__textbutton">
              <Text tid="CARDS.CARDS_TAB.FREE_SCROLLS" />
              <span className="BetCardsGame__ads__text__count">3</span>
            </span> */}
            <Link to={ROUTES.AIR_DROP}>
              <PrimaryButton className="BetCardsGame__ads-btn" type="submit">
                <p className="wallet__footer-btn-text">
                  <Text tid="CARDS.CARDS_TAB.TRY" />
                </p>
              </PrimaryButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetCardsGame;
