import {
  initUsersTopClassicActions,
  topClassicReducerDefaultData,
} from "../utils/store";

const initialState = topClassicReducerDefaultData;

export const USERS_TOP_CLASSIC = initUsersTopClassicActions("USERS_TOP_CLASSIC");

export const topClassic = (state = initialState, action) => {
  switch (action.type) {
    case USERS_TOP_CLASSIC.LOADING:
      return { ...state, isLoading: true, isLoaded: false, data: null };

    case USERS_TOP_CLASSIC.LOADED:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.payload,
      };

    case USERS_TOP_CLASSIC.LOADING_TODAY_TOP:
      return {
        ...state,
        isTodayTopLoading: true,
        isTodayTopLoaded: false,
        todayTop: null,
      };

    case USERS_TOP_CLASSIC.LOADED_TODAY_TOP:
      return {
        ...state,
        isTodayTopLoading: false,
        isTodayTopLoaded: true,
        todayTop: action.payload,
      };

    default:
      return state;
  }
};
