/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */
export const SOCKET = { INSTALL: "SOCKET.INSTALL" };
const initialState = {};

export const socket = (state = initialState, action) => {
  switch (action.type) {
    case SOCKET.INSTALL:
      return action.payload?.socket || {};
    default:
      return state;
  }
};
