import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

import CurrencySelect from "../CurrencySelect";
import NetworkSelect from "../NetworkSelect";

import { Text } from "../../utils/Text";
import PrimaryButton from "../../components/PrimaryButton";
import { AlertMessage } from "../../components/AlertMessage";
import LoaderPulse from "../../components/LoaderPulse";
import { currencyCodeParams as codeParams } from "../../utils/currencyOptions";
import { InputMaxButton } from "../../components/InputMaxButton";

const WithdrawView = ({
  copyRef,
  handleSelectCurrency,
  selectedCurrency,
  handleSelectNet,
  selectedNet,
  currencies,
  nets,
  amount,
  rangeAmountLoading,
  minAmount,
  maxAmount,
  setAmount,
  address,
  setAddress,
  handleSubmit,
  loading,
  error,
  errorMessage,
  disabled,
  activeTab,
  setActiveTab,
  selectedFiatCurrency,
  handleSelectFiatCurrency,
  fiatCurrencies,
}) => {
  const { t } = useTranslation();

  const minError = Number(amount) < Number(minAmount) && amount !== "";
  const maxError = Number(amount) > Number(maxAmount) && amount !== "";

  return (
    <form onSubmit={handleSubmit}>
      <hr className="walletSettings__header-line" />
      <div className="wallet__tabs small">
        <div
          className={`wallet__tab ${
            activeTab === "crypto" ? "wallet__tab-active" : ""
          }`}
          onClick={() => setActiveTab("crypto")}
        >
          <p className="wallet__tab-text">
            <Text tid="WALLET.WALLET_POPUP.CRYPTO" />
          </p>
        </div>
        <div
          className={`wallet__tab ${
            activeTab === "fiat" ? "wallet__tab-active" : ""
          }`}
          onClick={() => setActiveTab("fiat")}
        >
          <p className="wallet__tab-text">
            <Text tid="WALLET.WALLET_POPUP.FIAT" />
          </p>
        </div>
      </div>
      <div className="wallet__box">
        <div className="wallet__currency-box">
          <div className="wallet__currency">
            <p className="wallet__currency-text">
              <Text tid="MAIN.MAIN.CURRENCY" />
            </p>
            <CurrencySelect
              handleSelect={
                activeTab === "crypto"
                  ? handleSelectCurrency
                  : handleSelectFiatCurrency
              }
              selectedBalance={
                activeTab === "crypto" ? selectedCurrency : selectedFiatCurrency
              }
              balances={activeTab === "crypto" ? currencies : fiatCurrencies}
            />
          </div>
          {activeTab === "crypto" ? (
            <div className="wallet__currency">
              <p className="wallet__currency-text">
                <Text tid="MAIN.MAIN.NETWORK" />
              </p>
              <NetworkSelect
                handleSelectNet={handleSelectNet}
                selectedNet={selectedNet}
                nets={nets}
              />
            </div>
          ) : (
            <div className="wallet__currency">
              <p className="wallet__currency-text">
                <Text tid="WALLET.WALLET_POPUP.CURRENCY_DEPOSIT" />
              </p>
              <CurrencySelect
                handleSelect={handleSelectCurrency}
                selectedBalance={selectedCurrency}
                balances={currencies}
              />
            </div>
          )}
        </div>
        <div className="wallet__adress">
          <p className="wallet__adress-text">
            <Text tid="WALLET.WALLET_POPUP.WITHDRAW_ADDRESS" />
          </p>
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="wallet__adress-box"
            placeholder={t("WALLET.WALLET_POPUP.WITHDRAW_ADDRESS_PLACEHOLDER")}
            ref={copyRef}
            role="button"
            tabIndex={0}
          />
        </div>
        <div className="wallet__adress">
          <p
            className="wallet__adress-text"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>
              <Text tid="WALLET.WALLET_POPUP.WITHDRAW_AMOUNT" />
            </span>
            <div className="wallet__currencyBox">
              <span
                style={{
                  color: "#666",
                  display: "flex",
                  alignItems: "center",
                  gap: 6,
                }}
              >
                <Text tid="WALLET.WALLET_POPUP.MIN_AMOUNT_WARNING" />
                &nbsp; {rangeAmountLoading ? <LoaderPulse /> : <>{minAmount}</>}
                &nbsp;{" "}
                <img
                  src={
                    codeParams[selectedCurrency?.code?.toLowerCase?.()]?.icon
                  }
                  className="currency-icon"
                  alt=""
                />
              </span>
              <span
                style={{
                  color: "#666",
                  display: "flex",
                  alignItems: "center",
                  gap: 6,
                }}
              >
                <Text tid="WALLET.WALLET_POPUP.MAX_AMOUNT_WARNING" />
                &nbsp; {rangeAmountLoading ? <LoaderPulse /> : <>{maxAmount}</>}
                <img
                  src={
                    codeParams[selectedCurrency?.code?.toLowerCase?.()]?.icon
                  }
                  className="currency-icon"
                  alt=""
                />
              </span>
            </div>
          </p>
          <InputMaxButton
            handle={() => {
              if (!isNaN(selectedCurrency?.amount)) {
                setAmount(
                  selectedCurrency.amount > maxAmount
                    ? maxAmount
                    : selectedCurrency.amount
                );
              }
            }}
          >
            <input
              value={amount}
              onChange={(e) => {
                const v = e.target.value;
                if (isNaN(Number(v))) e.preventDefault();
                else setAmount(v);
              }}
              className="wallet__adress-box input-with-max-button"
              ref={copyRef}
              role="button"
              tabIndex={0}
              placeholder={t("WALLET.WALLET_POPUP.ENTER_WITHDRAW_AMOUNT")}
            />
          </InputMaxButton>
          {minError ? (
            <span style={{ color: "red", marginTop: 6, fontSize: 14 }}>
              <Text
                tid="WALLET.WALLET_POPUP.MIN_AMOUNT_ERROR"
                values={{ min: minAmount }}
              />
            </span>
          ) : maxError ? (
            <span style={{ color: "red", marginTop: 6, fontSize: 14 }}>
              <Text
                tid="WALLET.WALLET_POPUP.MAX_AMOUNT_ERROR"
                values={{ max: maxAmount }}
              />
            </span>
          ) : null}
        </div>
      </div>
      <div
        className="wallet__footer"
        style={{
          boxSizing: "border-box",
        }}
      >
        <PrimaryButton
          className="wallet__footer-btn"
          disabled={disabled}
          loading={loading}
          type="submit"
        >
          <p className="wallet__footer-btn-text">
            <Text tid="WALLET.WALLET_POPUP.CONCLUSION" />
          </p>
        </PrimaryButton>
        {error && (
          <AlertMessage
            message={errorMessage}
            type="error"
            style={{ marginTop: "16px" }}
          />
        )}
      </div>
    </form>
  );
};

export default WithdrawView;
