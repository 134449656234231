import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { axios } from "../../utils/request";

import AirdropLoginView from "./View";
import { API } from "../../constants/api";
import { ROUTES } from "../../constants/routes";

const AirdropLogin = ({ status, fetchData }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isCheckLoading, setIsCheckLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const telegramWidgetRef = useRef(null);

  const handleTelegramAuthCallback = async (data) => {
    try {
      await axios.get(API.TELEGRAM_AUTH(), { params: data });

      await fetchData();
    } catch (e) {
      console.error("Telegram auth error", e);
    }
  };

  const handleDiscordAuthCallback = async () => {
    try {
      const code = searchParams.get("code");
      const redirect = `https://${location.hostname}${location.pathname}`;

      await axios.get(API.DISCORD_AUTH(), { params: { code, redirect } });

      navigate(ROUTES.AIR_DROP);

      await fetchData();
    } catch (e) {
      navigate(ROUTES.AIR_DROP);
      console.error("Discord auth error", e);
    }
  };

  const handleTwitterAuthCallback = async () => {
    try {
      const code = searchParams.get("code");
      const redirect = `https://${location.hostname}${location.pathname}`;

      await axios.get(API.TWITTER_AUTH(), { params: { code, redirect } });

      navigate(ROUTES.AIR_DROP);

      await fetchData();
    } catch (e) {
      navigate(ROUTES.AIR_DROP);
      console.error("Twitter auth error", e);
    }
  };

  useEffect(() => {
    if (searchParams.has("code") && pathname.includes("discord")) {
      handleDiscordAuthCallback();
    }

    if (searchParams.has("code") && pathname.includes("twitter")) {
      handleTwitterAuthCallback();
    }
  }, [pathname]);

  const handleCheckClick = async () => {
    setIsCheckLoading(true);

    try {
      await axios.get(API.SOCIALS_CHECK());

      await fetchData();

      setIsCheckLoading(false);
    } catch (e) {
      setIsCheckLoading(false);
      console.error(e);
    }
  };

  const handleDiscordAuth = async () => {
    const SCOPES = "identify";

    const REDIRECT_URI = `${location.href}/discord`;

    const authUrl = `https://discord.com/api/oauth2/authorize?client_id=${status.discord.id}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPES}`;

    window.location.href = authUrl;
  };

  const handleTwitterAuth = async () => {
    const SCOPES = "users.read tweet.read follows.read offline.access";

    const REDIRECT_URI = `${location.href}/twitter`;

    const authUrl = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${status.twitter.id}&redirect_uri=${REDIRECT_URI}&scope=${SCOPES}&code_challenge=challenge&code_challenge_method=plain&state=state`;

    window.location.href = authUrl;
  };

  useEffect(() => {
    if (telegramWidgetRef.current && status) {
      const script = document.createElement("script");
      script.src = "https://telegram.org/js/telegram-widget.js?22";
      script.setAttribute("data-telegram-login", "cryptofastdevbot");
      script.setAttribute("data-onauth", "handleTelegramAuthCallback(user)");
      script.setAttribute("data-request-access", "write");
      script.setAttribute("data-size", "medium");
      script.async = true;

      telegramWidgetRef.current.appendChild(script);

      window.handleTelegramAuthCallback = handleTelegramAuthCallback;
    }
  }, [status]);

  return (
    <AirdropLoginView
      handleCheckClick={handleCheckClick}
      status={status}
      telegramWidgetRef={telegramWidgetRef}
      handleDiscordAuth={handleDiscordAuth}
      isCheckLoading={isCheckLoading}
      handleTwitterAuth={handleTwitterAuth}
    />
  );
};

export default AirdropLogin;
