import { GAME_STATUSES, GAME_TYPES } from "../constants/games";

const fixResult = (value) => {
  if (!value) return 0;
  return value;
};

export const gameGetTimeLeft = (game) => {
  const { classicSteps, doubleSteps, type, status, timer } = game;
  if (status !== GAME_STATUSES.RECRUITED) return 0;
  if (type === GAME_TYPES.CLASSIC)
    return fixResult(classicSteps[status] - timer);
  if (type === GAME_TYPES.DOUBLE) return fixResult(doubleSteps[status] - timer);
  return fixResult(timer);
};

export const gameGetTimeBeforePause = (game) => {
  const { classicSteps, doubleSteps, type, timer } = game;
  if (type === GAME_TYPES.CLASSIC)
    return fixResult(classicSteps[GAME_STATUSES.SPIN] - timer);
  if (type === GAME_TYPES.DOUBLE)
    return fixResult(doubleSteps[GAME_STATUSES.SPIN] - timer);
  return fixResult(timer);
};

export const gameGetTimeBeforeBegin = (game) => {
  const { classicSteps, doubleSteps, type, timer } = game;
  if (type === GAME_TYPES.CLASSIC)
    return fixResult(classicSteps[GAME_STATUSES.PAUSED] - timer);
  if (type === GAME_TYPES.DOUBLE)
    return fixResult(doubleSteps[GAME_STATUSES.PAUSED] - timer);
  return fixResult(timer);
};
