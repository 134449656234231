import React from "react";
import { Text } from "../../utils/Text";
import { HELP } from "../../constants/help";
import "./index.scss";

export const PolicyView = () => {
  return (
    <div className="container-policy">
      <h1>
        <Text tid="AUTH.AUTH.POLICY" />
      </h1>
      <div className="policy-text">
        <div style={{ marginBottom: 12, fontWeight: "bold" }}>
          <Text
            tid="AUTH.AUTH.POLICY-T.B1_DATE"
            values={{ date: HELP.POLICY_DATE }}
          />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B1_S1" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.POLICY-T.B2_TITLE" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B2_S1" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B2_S2" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B2_S3" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.POLICY-T.B3_TITLE" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B3_S1" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B3_S2" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B3_S3" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B3_S4" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.POLICY-T.B4_TITLE" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B4_S1" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B4_S2" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.POLICY-T.B5_TITLE" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B5_S1" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B5_S2" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.POLICY-T.B6_TITLE" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B6_S1" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.POLICY-T.B7_TITLE" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B7_S1" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.POLICY-T.B8_TITLE" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text tid="AUTH.AUTH.POLICY-T.B8_S1" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.POLICY-T.B9_TITLE" />
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 8,
            paddingLeft: 6,
            marginBottom: 8,
            borderLeft: "1px solid #666",
          }}
        >
          <Text
            tid="AUTH.AUTH.POLICY-T.B9_S1"
            values={{ email: HELP.SUPPORT }}
          />
        </div>
        {/* ------------- */}
      </div>
    </div>
  );
};
