import React from "react";

import LineChanceView from "./View";

const LineChance = ({ members }) => {
  const totalPercent = members.reduce((total, member) => {
    return total + member.chance;
  }, 0);

  const maxFlexBasis = 100 - members.length * 0.1;

  return (
    <LineChanceView
      totalPercent={totalPercent}
      maxFlexBasis={maxFlexBasis}
      members={members}
    />
  );
};

export default LineChance;
