import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Recaptcha from "react-google-recaptcha";

import "./index.scss";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { Text } from "../../utils/Text";
import { ROUTES } from "../../constants/routes";
import { AUTH_USER } from "../../constants/fields";
import { AlertMessage } from "../../components/AlertMessage";
import PrimaryButton from "../../components/PrimaryButton";

import EyeOpen from "../../assets/img/eye-open-2.svg";
import EyeClosed from "../../assets/img/eye-close-3.svg";

export const SignUpView = ({
  fields,
  changeField,
  onSubmit,
  isFormDisabled,
  loading,
  error,
  errorMessage,
  captchaRef,
  setIsCaptchaSuccess,
  isPasswordVisible,
  setIsPasswordVisible,
}) => {
  const { t } = useTranslation();

  const captchaSize = window.matchMedia("(max-width: 400px)").matches
    ? "compact"
    : "normal";

  return (
    <form className="SignIn" onSubmit={onSubmit}>
      <h1 className="SignIn__title">
        <Text tid="AUTH.AUTH.REGISTRATION" />
      </h1>
      <div className="SignIn__formBox">
        <div className="SignIn__form">
          <div className="SignIn__inputBox">
            <span className="SignIn__inputTitle">
              <Text tid="AUTH.AUTH.NIKNAME" />
            </span>
            <input
              className="SignIn__input"
              type="text"
              placeholder={t("AUTH.AUTH.ENTER_NIKNAME")}
              value={fields[AUTH_USER.NICKNAME]}
              onChange={(e) => changeField(AUTH_USER.NICKNAME, e.target.value)}
              required
              minLength={4}
              maxLength={30}
            />
          </div>
          <div className="SignIn__inputBox">
            <span className="SignIn__inputTitle">
              <Text tid="MAIN.MAIN.EMAIL" />
            </span>
            <input
              className="SignIn__input"
              type="text"
              placeholder={t("AUTH.AUTH.ENTER_EMAIL")}
              value={fields[AUTH_USER.EMAIL]}
              onChange={(e) => changeField(AUTH_USER.EMAIL, e.target.value)}
              required
              minLength={4}
              maxLength={44}
            />
          </div>
          <div className="SignIn__inputBox">
            <span className="SignIn__inputTitle">
              <Text tid="AUTH.AUTH.PASSWORD" />
            </span>
            <div style={{ position: "relative" }}>
              <input
                className="SignIn__input"
                // style={{ paddingRight: "40px" }}
                type={
                  isPasswordVisible[AUTH_USER.PASSWORD] ? "text" : "password"
                }
                placeholder={t("AUTH.AUTH.ENTER_PASSWORD")}
                value={fields[AUTH_USER.PASSWORD]}
                onChange={(e) =>
                  changeField(AUTH_USER.PASSWORD, e.target.value)
                }
                required
                minLength={8}
                maxLength={30}
              />
              <span
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setIsPasswordVisible((prevState) => ({
                    ...prevState,
                    [AUTH_USER.PASSWORD]: !prevState[AUTH_USER.PASSWORD],
                  }))
                }
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={
                    isPasswordVisible[AUTH_USER.PASSWORD] ? EyeOpen : EyeClosed
                  }
                  alt="toggle visibility"
                />
              </span>
            </div>
          </div>
          <div className="SignIn__inputBox">
            <span className="SignIn__inputTitle">
              <Text tid="AUTH.AUTH.CONFIRM_PASSWORD" />
            </span>
            <div style={{ position: "relative" }}>
              <input
                className="SignIn__input"
                type={
                  isPasswordVisible[AUTH_USER.CONFIRM_PASSWORD]
                    ? "text"
                    : "password"
                }
                placeholder={t("AUTH.AUTH.ENTER_PASSWORD")}
                value={fields[AUTH_USER.CONFIRM_PASSWORD]}
                onChange={(e) =>
                  changeField(AUTH_USER.CONFIRM_PASSWORD, e.target.value)
                }
                required
                minLength={8}
                maxLength={30}
              />
              <span
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setIsPasswordVisible((prevState) => ({
                    ...prevState,
                    [AUTH_USER.CONFIRM_PASSWORD]:
                      !prevState[AUTH_USER.CONFIRM_PASSWORD],
                  }))
                }
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={
                    isPasswordVisible[AUTH_USER.CONFIRM_PASSWORD]
                      ? EyeOpen
                      : EyeClosed
                  }
                  alt="toggle visibility"
                />
              </span>
            </div>
          </div>
          <div className="SignIn__inputBox">
            <span className="SignIn__inputTitle">
              <Text tid="AUTH.AUTH.REFERRER" />
            </span>
            <input
              className="SignIn__input"
              type="text"
              placeholder={t("AUTH.AUTH.ENTER_REFERRER")}
              value={fields[AUTH_USER.REFERRER]}
              onChange={(e) => changeField(AUTH_USER.REFERRER, e.target.value)}
              minLength={8}
              maxLength={30}
              // disabled={referrer}
            />
          </div>
          <Recaptcha
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            ref={captchaRef}
            size={captchaSize}
            style={{ margin: "0 auto", maxWidth: "100%" }}
            theme="dark"
            onChange={() => setIsCaptchaSuccess(true)}
          />
          <FormControlLabel
            // required
            control={<Checkbox color="primary" />}
            value={fields[AUTH_USER.CHECKED]}
            onChange={(e) => changeField(AUTH_USER.CHECKED, e.target.checked)}
            style={{ cursor: "auto" }}
            label={
              <>
                {t("AUTH.AUTH.AGREE_WITH")}{" "}
                <span className="Policy-link">
                  <Link to={ROUTES.POLICY} style={{ textDecoration: "none" }}>
                    {t("AUTH.AUTH.OF_TERMS_OF_USE")}
                  </Link>
                </span>{" "}
                <span className="Policy-link">
                  {t("AUTH.AUTH.TERMS_AND_POLICY")}
                </span>{" "}
                <span className="Policy-link">
                  <Link
                    to={ROUTES.TERMS_OF_USE}
                    style={{ textDecoration: "none" }}
                  >
                    {t("AUTH.AUTH.OF_POLICY")}
                  </Link>
                </span>
              </>
            }
          />
          <PrimaryButton
            className="SignIn__button"
            type="submit"
            disabled={isFormDisabled}
            loading={loading}
          >
            <span className="">
              <Text tid="AUTH.AUTH.SIGN_UP" />
            </span>
          </PrimaryButton>

          {error && <AlertMessage message={errorMessage} type="error" />}

          <div className="SignIn__registration">
            <Link to={ROUTES.LOGIN}>
              <span className="SignIn__registrationTitle">
                <Text tid="AUTH.AUTH.HAVE_ACCOUNT" />{" "}
                <span className="SignIn__registrationLink">
                  <Text tid="AUTH.AUTH.LOGIN" />
                </span>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};
