import { HISTORY } from "../reducers/historyClassic";
import { setUserColors } from "../utils/UserData";
import { axios } from "../utils/request";
import { API } from "../constants/api";

const setUpdated = (payload) => ({ type: HISTORY.UPDATED, payload });
const setLoaded = (payload) => ({ type: HISTORY.LOADED, payload });
const setUpdating = () => ({ type: HISTORY.UPDATING });
const setLoading = () => ({ type: HISTORY.LOADING });

export const getClassicHistory = () => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const {
        data: { data, skip, historyLength },
      } = await axios.post(API.GET_HISTORY_CLASSIC, { skip: 0 });
      dispatch(
        setLoaded({
          data: data.map((g) => {
            const membersWithColors = setUserColors(g.members);
            return { ...g, members: membersWithColors };
          }),
          skip,
          historyLength,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
};

export const getClassicHistoryPagination = () => {
  return async (dispatch, getState) => {
    const { historyLength } = getState().historyClassic.meta || {};
    const { pending } = getState().historyClassic.request || {};
    const { data } = getState().historyClassic.request || {};
    const { skip } = getState().historyClassic.meta || {};

    if (pending) return null;
    if (!data?.length) return null;
    if (historyLength === 0) return null;

    dispatch(setUpdating());

    try {
      const { data: res } = await axios.post(API.GET_HISTORY_CLASSIC, { skip });

      const newHistoryLength = res.historyLength;
      const historyData = res.data;
      const newSkip = res.skip;

      dispatch(
        setUpdated({
          data: historyData.map((g) => {
            const membersWithColors = setUserColors(g.members);
            return { ...g, members: membersWithColors };
          }),
          skip: newSkip,
          historyLength: newHistoryLength,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
};
