const DEFAULT_DATA = {
  errorMessage: null,
  updating: null,
  pending: null,
  success: null,
  updated: null,
  error: null,
};

/* INITTERS */

export const initSocketState = {
  connecting: false,
  connected: false,
  updating: false,
  updated: false,
  data: {},
};

export const initRequestState = () => ({
  errorMessage: null,
  pending: null,
  success: null,
  email: null,
  error: null,
});

export const initRequestWithDataState = (
  subData = null,
  additional = null,
  initData = null
) => ({
  ...DEFAULT_DATA,
  data: initData || {},
  ...(subData ? { data: subData } : null),
  ...(additional ? { additional } : null),
});

/* PENDING */

export const setUpdatePending = (state = {}) => ({
  ...state,
  errorMessage: DEFAULT_DATA.errorMessage,
  error: DEFAULT_DATA.error,
  updating: true,
  updated: false,
});

export const setRequestPending = (state = {}) => ({
  ...state,
  errorMessage: DEFAULT_DATA.errorMessage,
  success: DEFAULT_DATA.success,
  error: DEFAULT_DATA.error,
  pending: true,
});

export const setUpdateWithPaginationSuccess = (state = {}, data = null) => ({
  ...state,
  updating: false,
  updated: true,
  data: data?.pagination
    ? {
        pagination: data.pagination,
        list: data.pagination.skip ? data.list : [...state.list, ...data.list],
        ...data.dataAlso,
      }
    : data,
});

/* SUCCESS */

export const setRequestSuccess = (state = {}, data = null) => ({
  ...state,
  data: data || state.data,
  errorMessage: false,
  pending: false,
  success: true,
  error: false,
});

export const setRequestError = (state = {}, message = null) => ({
  ...state,
  errorMessage: message,
  updating: false,
  success: false,
  updated: false,
  pending: false,
  error: true,
});

/* RESET */

export const resetRequestStatus = (state = {}) => ({
  ...state,
  errorMessage: null,
  success: null,
  updated: null,
  error: null,
});

export const resetErrorStatus = (state = {}) => ({
  ...state,
  errorMessage: null,
  error: null,
});

export const initUsersTopClassicActions = (type, additionalTypes = []) => {
  const additional = {};

  additionalTypes.forEach((additionalType) => {
    additional[additionalType] = `${type}.${additionalType}`;
  });

  return {
    LOADING_TODAY_TOP: `${type}.LOADING_TODAY_TOP`,
    LOADED_TODAY_TOP: `${type}.LOADED_TODAY_TOP`,
    LOADING: `${type}.LOADING`,
    LOADED: `${type}.LOADED`,
    ERROR: `${type}.ERROR`,
    ...additional,
  };
};

export const initRequestActions = (type, additionalTypes = []) => {
  const additional = {};

  additionalTypes.forEach((additionalType) => {
    additional[additionalType] = `${type}.${additionalType}`;
  });

  return {
    UPDATING: `${type}.UPDATING`,
    UPDATED: `${type}.UPDATED`,
    LOADING: `${type}.LOADING`,
    LOADED: `${type}.LOADED`,
    ERROR: `${type}.ERROR`,
    ...additional,
  };
};

export const topClassicReducerDefaultData = {
  errorMessage: null,
  isLoading: false,
  isLoaded: false,
  isError: false,
  data: null,
  todayTop: null,
  isTodayTopLoading: false,
  isTodayTopLoaded: false,
};

export const topClassicBestUsersReducerDefaultData = {
  errorMessage: null,
  isLoading: false,
  isLoaded: false,
  isError: false,
  data: null,
};

export const doubleHistoryReducerDefaultData = {
  errorMessage: null,
  isLoading: false,
  isLoaded: false,
  isError: false,
  data: null,
};

/* GETTERS */

export const getErrorMessage = (state = {}) => state.errorMessage || null;
export const isRequestPending = (state = {}) => !!state.pending;
export const isRequestSuccess = (state = {}) => !!state.success;
export const isUpdatePending = (state = {}) => !!state.updating;
export const isUpdateSuccess = (state = {}) => !!state.updated;
export const isRequestError = (state = {}) => !!state.error;
export const getData = (state = {}, defaultValue = {}) =>
  state.data || defaultValue;
