import React from "react";
import "./index.scss";

import { EXTERNAL_LINKS } from "../../constants/help";

import parachute from "../../assets/img/ParachuteL.svg";
import telegram from "../../assets/img/Telegram.svg";
import discord from "../../assets/img/Discord.svg";
import x from "../../assets/img/X.svg";
import tick from "../../assets/img/tick.png";
import check from "../../assets/img/check.png";
import PrimaryButton from "../../components/PrimaryButton";

import { Text } from "../../utils/Text";

const AirdropLoginView = ({
  handleCheckClick,
  status,
  telegramWidgetRef,
  handleDiscordAuth,
  isCheckLoading,
  handleTwitterAuth,
}) => {
  return (
    <div className="AirdropLogin">
      <div className="AirdropLogin__container">
        <div className="AirdropLogin__container__title">
          <img src={parachute} alt="parachute" />
          <h1>
            <Text tid="AIRDROP.AIRDROP.TITLE" />
          </h1>
        </div>
        <div className="AirdropLogin__container__subtitle">
          <span>
            <Text tid="AIRDROP.AIRDROP.TO_PARTICIPATE" />
            <br />
            <Text tid="AIRDROP.AIRDROP.SUBSCRIBED" />
          </span>
        </div>
        <div className="AirdropLogin__container__buttons">
          <div className="AirdropLogin__container__button-container">
            <a
              href={EXTERNAL_LINKS.TELEGRAM}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="AirdropLogin__container__social">
                <img src={telegram} alt="parachute" />
                <Text tid="AIRDROP.AIRDROP.OUR" />{" "}
                <Text tid="AIRDROP.AIRDROP.TELEGRAM" />
              </button>
            </a>
            <div className="AirdropLogin__container__status">
              <div className="AirdropLogin__container__status-inner">
                {status?.telegram?.connected ? (
                  <img src={check} alt="check" />
                ) : (
                  <img src={tick} alt="tick" />
                )}
                &nbsp;
                <Text tid="AIRDROP.AIRDROP.CONNECT_LABEL" />
              </div>

              {status?.telegram?.connected &&
              status?.telegram?.data?.username ? (
                <p>({status?.telegram?.data?.username})</p>
              ) : null}
            </div>
            <div className="AirdropLogin__container__status">
              <div className="AirdropLogin__container__status-inner">
                {status?.telegram?.subscribed ? (
                  <img src={check} alt="check" />
                ) : (
                  <img src={tick} alt="tick" />
                )}
                &nbsp;
                <Text tid="AIRDROP.AIRDROP.SUBSCRIBE_LABEL" />
              </div>
            </div>
            {!status?.telegram?.connected ? (
              <div
                className="AirdropLogin__container__login-button"
                ref={telegramWidgetRef}
              />
            ) : !status?.telegram?.subscribed ? (
              <a
                href={EXTERNAL_LINKS.TELEGRAM}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrimaryButton>
                  <span>
                    <Text tid="AIRDROP.AIRDROP.SUBSCRIBE_BUTTON" />
                  </span>
                </PrimaryButton>
              </a>
            ) : null}
          </div>

          <div className="AirdropLogin__container__button-container">
            <a
              href={EXTERNAL_LINKS.DISCORD}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="AirdropLogin__container__social">
                <img src={discord} alt="parachute" />
                <Text tid="AIRDROP.AIRDROP.OUR" />{" "}
                <Text tid="AIRDROP.AIRDROP.DISCORD" />
              </button>
            </a>
            <div className="AirdropLogin__container__status">
              <div className="AirdropLogin__container__status-inner">
                {status?.discord?.connected ? (
                  <img src={check} alt="check" />
                ) : (
                  <img src={tick} alt="tick" />
                )}
                &nbsp;
                <Text tid="AIRDROP.AIRDROP.CONNECT_LABEL" />
              </div>

              {status?.discord?.connected && status?.discord?.data?.username ? (
                <p>({status?.discord?.data?.username})</p>
              ) : null}
            </div>
            <div className="AirdropLogin__container__status">
              <div className="AirdropLogin__container__status-inner">
                {status?.discord?.subscribed ? (
                  <img src={check} alt="check" />
                ) : (
                  <img src={tick} alt="tick" />
                )}
                &nbsp;
                <Text tid="AIRDROP.AIRDROP.SUBSCRIBE_LABEL" />
              </div>
            </div>
            {!status?.discord?.connected ? (
              <PrimaryButton onClick={handleDiscordAuth}>
                <span>
                  <Text tid="AIRDROP.AIRDROP.CONNECT_BUTTON" />
                </span>
              </PrimaryButton>
            ) : !status?.discord?.subscribed ? (
              <a
                href={EXTERNAL_LINKS.DISCORD}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrimaryButton>
                  <span>
                    <Text tid="AIRDROP.AIRDROP.SUBSCRIBE_BUTTON" />
                  </span>
                </PrimaryButton>
              </a>
            ) : null}
          </div>
          <div className="AirdropLogin__container__button-container">
            <a
              href={EXTERNAL_LINKS.TWITTER}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="AirdropLogin__container__social">
                <img src={x} alt="parachute" />
                <Text tid="AIRDROP.AIRDROP.OUR" />{" "}
                <Text tid="AIRDROP.AIRDROP.TWITTER" />
              </button>
            </a>
            <div className="AirdropLogin__container__status">
              <div className="AirdropLogin__container__status-inner">
                {status?.twitter?.connected ? (
                  <img src={check} alt="check" />
                ) : (
                  <img src={tick} alt="tick" />
                )}
                &nbsp;
                <Text tid="AIRDROP.AIRDROP.CONNECT_LABEL" />
              </div>

              {status?.twitter?.connected && status?.twitter?.data?.username ? (
                <p>({status?.twitter?.data?.username})</p>
              ) : null}
            </div>
            <div className="AirdropLogin__container__status">
              <div className="AirdropLogin__container__status-inner">
                {status?.twitter?.subscribed ? (
                  <img src={check} alt="check" />
                ) : (
                  <img src={tick} alt="tick" />
                )}
                &nbsp;
                <Text tid="AIRDROP.AIRDROP.SUBSCRIBE_LABEL" />
              </div>
            </div>
            {!status?.twitter?.connected ? (
              <PrimaryButton onClick={handleTwitterAuth}>
                <span>
                  <Text tid="AIRDROP.AIRDROP.CONNECT_BUTTON" />
                </span>
              </PrimaryButton>
            ) : !status?.twitter?.subscribed ? (
              <a
                href={EXTERNAL_LINKS.TWITTER}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrimaryButton>
                  <span>
                    <Text tid="AIRDROP.AIRDROP.SUBSCRIBE_BUTTON" />
                  </span>
                </PrimaryButton>
              </a>
            ) : null}
          </div>
        </div>
        <div className="AirdropLogin__container__check">
          <PrimaryButton
            className="AirdropLogin__container__check__button"
            onClick={handleCheckClick}
            loading={isCheckLoading}
          >
            <p className="AirdropLogin__container__check__text">
              <Text tid="AIRDROP.AIRDROP.CHECK" />
            </p>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default AirdropLoginView;
