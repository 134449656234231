import React from "react";
import HeaderDouble from "../../containers/HeaderDouble/index";
import History from "../../containers/ClassicGameHistory";
import Chance from "../Chance";
import Crypto from "../Crypto";
import "./index.scss";

const HistoryTabDouble = () => {
  return (
    <div className="body" style={{ position: "relative" }}>
      <HeaderDouble />
      <History />
      <Chance />
      <Crypto />
    </div>
  );
};

export default HistoryTabDouble;
