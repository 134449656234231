import { axios } from "../utils/request";
import { API } from "../constants/api";

export const SETTINGS_REFERRAL_LINK = {
  LOADING: "SETTINGS_REFERRAL_LINK.LOADING",
  SUCCESS: "SETTINGS_REFERRAL_LINK.SUCCESS",
  ERROR: "SETTINGS_REFERRAL_LINK.ERROR",
};

const setLoading = () => ({
  type: SETTINGS_REFERRAL_LINK.LOADING,
});

const setSuccess = (data) => ({
  type: SETTINGS_REFERRAL_LINK.SUCCESS,
  data,
});

const setError = (message) => ({
  type: SETTINGS_REFERRAL_LINK.ERROR,
  message,
});

export const getReferralInfo = () => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .get(API.USER_REFERRAL_DATA)
      .then(({ data }) => {
        dispatch(setSuccess(data));
      })
      .catch(({ response: { data } }) => {
        dispatch(setError(data.message));
      });
  };
};
