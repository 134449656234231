import React from "react";
import { useSelector } from "react-redux";

import { getData } from "../../utils/store";

import { SettingsPartnerApplication } from "../settings-partner-application";
import { SettingsPartnerData } from "../settings-partner-data";
import { USER_ROLE } from "../../constants/user-role";

export const SettingsPartner = () => {
  const { user } = useSelector(({ user: userData }) => ({
    user: getData(userData.data),
  }));

  if (user?.role === USER_ROLE.PARTNER) {
    return <SettingsPartnerData />;
  }

  return <SettingsPartnerApplication />;
};
