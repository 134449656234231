import React, { useState } from "react";
import { useSelector } from "react-redux";

import { SETTINGS } from "../../constants/fields";

import { SettingsConfirmCodeView } from "./View";
import { getData } from "../../utils/store";

export const SettingsConfirmCode = ({
  onSubmit,
  isLoading,
  isError,
  errorMessage,
}) => {
  const { user } = useSelector(({ user: userData }) => ({
    user: getData(userData.data),
  }));

  const [fields, setFields] = useState({});

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const submit = async (e) => {
    e.preventDefault();

    onSubmit({
      code: fields[SETTINGS.CONFIRM_CODE],
    });
  };

  const isFormDisabled = !fields[SETTINGS.CONFIRM_CODE];

  return (
    <SettingsConfirmCodeView
      fields={fields}
      changeField={changeField}
      onSubmit={submit}
      isFormDisabled={isFormDisabled}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      user={user}
    />
  );
};
