import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import GamesView from "./View";

const Games = () => {
  const [activeGame, setActiveGame] = useState("");

  const handleGameClick = (game) => {
    setActiveGame(game);
  };

  const location = useLocation();
  const isSettingsPage = location.pathname.startsWith("/settings/");

  const backgroundStyle = isSettingsPage
    ? "#1e1e1e"
    : "linear-gradient(120.72deg, rgba(54, 122, 255, 0.1) 0%, rgba(54, 122, 255, 0.1) 0%), #1e1e1e";

  return (
    <GamesView
      activeGame={activeGame}
      handleGameClick={handleGameClick}
      backgroundStyle={backgroundStyle}
    />
  );
};

export default Games;
