/* eslint-disable no-param-reassign */
export const registerAudio = (url = "") => {
  const isMuted = JSON.parse(localStorage.getItem("muted") || "false");
  const audio = new Audio(url);
  audio.muted = isMuted;
  return audio;
};

export const audioPlay = (audio) => {
  const isMuted = JSON.parse(localStorage.getItem("muted") || "false");
  if (!isMuted && audio?.play) {
    audio.play().catch(() => {});
  }
};

export const audioSetOn = () => {
  localStorage.setItem("muted", "false");
  document.querySelectorAll("video, audio").forEach((el) => {
    el.muted = false;
  });
};

export const audioSetOff = () => {
  localStorage.setItem("muted", "true");
  document.querySelectorAll("video, audio").forEach((el) => {
    el.muted = true;
  });
};
