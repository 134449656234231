import React from "react";
import "./index.scss";
import TopOf from "../TopOf";
import TopWinners from "../../containers/TopWinners/index";
import HeaderTopDouble from "../HeaderTopDouble";

const TopTabDouble = () => {
  return (
    <div className="body">
      <HeaderTopDouble />
      <TopOf />
      <TopWinners />
    </div>
  );
};

export default TopTabDouble;
