import { axios } from "../utils/request";
import { API } from "../constants/api";

export const DEPOSIT_LIST = {
  LOADING: "DEPOSIT_LIST.LOADING",
  SUCCESS: "DEPOSIT_LIST.SUCCESS",
  ERROR: "DEPOSIT_LIST.ERROR",
};

const setLoading = () => ({
  type: DEPOSIT_LIST.LOADING,
});

const setSuccess = (data) => ({
  type: DEPOSIT_LIST.SUCCESS,
  data,
});

const setError = (message) => ({
  type: DEPOSIT_LIST.ERROR,
  message,
});

export const getDepositList = () => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .get(API.DEPOSIT_LIST)
      .then(({ data }) => dispatch(setSuccess(data)))
      .catch(({ response: { data } }) => dispatch(setError(data.message)));
  };
};
