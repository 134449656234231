import en from "./en";
import ru from "./ru";
import ua from "./ua";
import es from "./es";
import tr from "./tr";
import ind from "./ind";

export default {
  ua,
  en,
  ru,
  es,
  tr,
  ind,
};
