export const ONLINE = {
  UPDATING_TOTAL: "ONLINE.UPDATING",
  UPDATED_TOTAL: "ONLINE.UPDATED",
  CONNECTING: "ONLINE.CONNECTING",
  CONNECTED: "ONLINE.CONNECTED",
};

const setUpdatedTotal = (payload) => ({ type: ONLINE.UPDATED_TOTAL, payload });

export const updateTotalOnline = (total) => {
  return (dispatch) => {
    dispatch(setUpdatedTotal(total));
  };
};
