import React, { useState, useEffect } from "react";

import { axios } from "../../utils/request";
import { API } from "../../constants/api";

import AirdropView from "./View";

const Airdrop = () => {
  const [status, setStatus] = useState(null);
  const [authorization, setAuthorization] = useState(false);

  const fetchData = async () => {
    const { data } = await axios.get(API.AIRDROP_GET_STATUS());

    setStatus(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (status) {
      const isAllSubscribed = Object.keys(status).every(
        (item) => status[item].connected && status[item].subscribed
      );

      if (isAllSubscribed) {
        setAuthorization(true);
      }
    }
  }, [status]);

  return (
    <AirdropView
      authorization={authorization}
      status={status}
      fetchData={fetchData}
    />
  );
};

export default Airdrop;
