import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import React from "react";

import { ROUTES } from "../../constants/routes";
import wheel from "../../assets/img/Wheel.svg";
import crown from "../../assets/img/Crown.png";
import cards from "../../assets/img/Cards.svg";
import crash from "../../assets/img/carshicon.svg";
import { Text } from "../../utils/Text";
import "./index.scss";
import { EXTERNAL_LINKS } from "../../constants/help";

const GamesView = ({ activeGame, handleGameClick }) => {
  const totalOnline = useSelector(({ online }) => online?.data?.total || 0);

  return (
    <div className="games">
      <div className="games__container">
        <div className="games__list">
          <div className="games__list-title">
            <h3>
              <Text tid="MAIN.MAIN.GAMES" />
            </h3>
          </div>
          <Link
            to={ROUTES.HOME}
            className={`games__list-item ${
              activeGame === "classic" ||
              window.location.pathname === "/" ||
              window.location.pathname === "/classic" ||
              window.location.pathname === "/classic/history" ||
              window.location.pathname === "/classic/top"
                ? "activeGame"
                : ""
            }`}
            onClick={() => handleGameClick("classic")}
          >
            <img src={crown} alt="" />
            <div className="games__list-item-title">
              <h4 style={{ margin: "0 0 0 0" }}>
                <Text tid="CLASSIC.CLASSIC_TAB.CLASSIC" />
              </h4>
            </div>
            {(window.location.pathname === "/" ||
              window.location.pathname === "/classic" ||
              window.location.pathname === "/classic/history" ||
              window.location.pathname === "/classic/top") && (
              <div className="activeGame__border"></div>
            )}
          </Link>
          <Link
            to={ROUTES.DOUBLE}
            className={`games__list-item ${
              activeGame === "double" ||
              window.location.pathname === "/double" ||
              window.location.pathname === "/double/history" ||
              window.location.pathname === "/double/top"
                ? "activeGame"
                : ""
            }`}
            onClick={() => handleGameClick("double")}
          >
            <img src={wheel} alt="" />
            <div className="games__list-item-title">
              <h4 style={{ margin: "0 0 0 0" }}>
                <Text tid="DOUBLE.DOUBLE_TAB.DOUBLE" />
              </h4>
            </div>
            {(window.location.pathname === "/double" ||
              window.location.pathname === "/double/history" ||
              window.location.pathname === "/double/top") && (
              <div className="activeGame__border"></div>
            )}
          </Link>
          <Link
            to={ROUTES.CARDS}
            className={`games__list-item ${
              activeGame === "cards" ||
              window.location.pathname === "/cards" ||
              window.location.pathname === "/cards/history" ||
              window.location.pathname === "/cards/top"
                ? "activeGame"
                : ""
            }`}
            onClick={() => handleGameClick("cards")}
          >
            <img src={cards} alt="" />
            <div className="games__list-item-title">
              <h4 style={{ margin: "0 0 0 0" }}>
                <Text tid="CARDS.CARDS_TAB.CARDS" />
              </h4>
            </div>
            {(window.location.pathname === "/cards" ||
              window.location.pathname === "/cards/history" ||
              window.location.pathname === "/cards/top") && (
              <div className="activeGame__border"></div>
            )}
          </Link>
          <Link
            to={ROUTES.CRASH}
            className={`games__list-item ${
              activeGame === "crash" ||
              window.location.pathname === "/crash" ||
              window.location.pathname === "/crash/history" ||
              window.location.pathname === "/crash/top"
                ? "activeGame"
                : ""
            }`}
            onClick={() => handleGameClick("crash")}
          >
            <img src={crash} alt="crash" />
            <div className="games__list-item-title">
              <h4 style={{ margin: "0 0 0 0" }}>
                <Text tid="CRASH.CRASH_TAB.CRASH" />
              </h4>
            </div>
            {(window.location.pathname === "/crash" ||
              window.location.pathname === "/crash/history" ||
              window.location.pathname === "/crash/top") && (
              <div className="activeGame__border"></div>
            )}
          </Link>
          <div className="games__more-game">
            <Text tid="MAIN.MAIN.MORE_GAMES" />
          </div>
        </div>
        <div className="games__online">
          <div className="games__online-players">
            <div className="games__online-players-title">
              <h4 style={{ margin: "0 0 0 0" }}>
                <Text tid="MAIN.MAIN.PLAERS" />: {totalOnline}
              </h4>
              {/* <span></span> */}
            </div>
          </div>

          <div className="games__social">
            <div className="games__social-title">
              <p className="games__social-p">
                <Text tid="MAIN.MAIN.ALL_RIGHTS_RESERVED" />.
              </p>
              <div className="games__social-item">
                <a
                  href={EXTERNAL_LINKS.TELEGRAM}
                  className="games__social-link telegram"
                />
                <a
                  href={EXTERNAL_LINKS.TWITTER}
                  className="games__social-link twitter"
                />
                <a
                  href={EXTERNAL_LINKS.DISCORD}
                  className="games__social-link discord"
                />
              </div>
            </div>
          </div>
          <hr
            className="settings__header-line"
            style={{ opacity: 0.35, height: 1 }}
          />
          <div className="settings__list" style={{ alignItems: "center" }}>
            <Link
              to={ROUTES.POLICY}
              className={`settings__list-item ${
                location.pathname === "/settings/general" ? "active" : ""
              }`}
            >
              <p className="settings__list-item-text">
                <Text tid="AUTH.AUTH.POLICY" />
              </p>
            </Link>
            <Link
              to={ROUTES.TERMS_OF_USE}
              className={`settings__list-item ${
                location.pathname === "/settings/general" ? "active" : ""
              }`}
            >
              <p className="settings__list-item-text">
                <Text tid="AUTH.AUTH.TERMS_OF_USE" />
              </p>
            </Link>
            <Link
              to={ROUTES.ABOUT}
              className={`settings__list-item ${
                location.pathname === "/settings/general" ? "active" : ""
              }`}
            >
              <p className="settings__list-item-text">
                <Text tid="AUTH.AUTH.FAQ" />
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamesView;
