/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/button-has-type */
import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

import parashut from "../../assets/img/Parachute.svg";
import logo from "../../assets/img/CRYPTOFAST.svg";
import LanguageSelect from "../CustomSelect";

import User from "../User";
import SettingsPopUp from "../SettingsPopUp";
import { ROUTES } from "../../constants/routes";
import { Text } from "../../utils/Text";
import QuestionsPopUp from "../QuestionsPopUp";
import PrimaryButton from "../../components/PrimaryButton";

const HeaderView = ({
  handleWalletClick,
  setOpacity,
  handleWalletSettingsClick,
  isActive,
  handleBurgerClick,
  handleLanguageChange,
  languages,
  selectedLanguage,
  handleToggleSettingsPopUp,
  isSettingsPopUpOpen,
  opacitySettings,
  handleVoiceClick,
  voiceOn,
  setIsSettingsPopUpOpen,
  isLogged,
  setOpacitySettings,
  setIsActive,
  isQuestionsPopUpOpen,
  setIsQuestionsPopUpOpen,
  setOpacityQuestions,
}) => {
  return (
    <header className="header">
      <div className="container">
        <Link to={ROUTES.HOME} className="logo-box">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <nav className="navigation">
          <Link
            to={ROUTES.AIR_DROP}
            className={
              window.innerWidth <= 330
                ? "navigation__drop-330px"
                : "navigation__drop"
            }
            style={{ media: "420px" ? "680px" : "930px" }}
          >
            <img src={parashut} alt="parashut" className="parashut" />
            <span className="navigation__drop-text">
              {window.innerWidth < 500 ? "" : "AIRDROP"}
            </span>
          </Link>
          <div className="navigation__language">
            <LanguageSelect
              languages={languages}
              selectedLanguage={selectedLanguage}
              onChange={handleLanguageChange}
            />
          </div>
          <span className="media">
            {!isLogged ? (
              <React.Fragment>
                <Link to={ROUTES.LOGIN}>
                  <div className="navigation__login">
                    <span className="navigation__login-link">
                      <Text tid="MAIN.MAIN.SIGN_IN" />
                    </span>
                  </div>
                </Link>
                <Link to={ROUTES.SIGNUP}>
                  <PrimaryButton>
                    <span
                      style={{
                        textWrap: "nowrap",
                      }}
                    >
                      <Text tid="AUTH.AUTH.SIGN_UP" />
                    </span>
                  </PrimaryButton>
                </Link>
              </React.Fragment>
            ) : (
              <User
                handleWalletClick={handleWalletClick}
                setOpacity={setOpacity}
                handleWalletSettingsClick={handleWalletSettingsClick}
                setIsActive={setIsActive}
                isActive={isActive}
              />
            )}
          </span>
          <span className="media">
            <div className="navigation__box">
              {isLogged && (
                <button
                  className="navigation__link gear"
                  onClick={handleToggleSettingsPopUp}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleToggleSettingsPopUp(e);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                />
              )}

              <div
                className="navigation__link voiceOff"
                style={{ display: !voiceOn ? "flex" : "none" }}
                onClick={handleVoiceClick}
                title="tours"
              />
              <div
                className="navigation__link voice"
                style={{ display: voiceOn ? "flex" : "none" }}
                onClick={handleVoiceClick}
                title="tours"
              />
              <div
                className="navigation__link help"
                onClick={() => setIsQuestionsPopUpOpen(true)}
                title="stories"
              />
            </div>
          </span>
          <div
            className={`burger ${isActive ? "lineactive" : ""}`}
            id="burgericon"
            onClick={handleBurgerClick}
          >
            <div className="burgerline" />
            <div className="burgerline" />
            <div className="burgerline" />
          </div>
        </nav>
      </div>
      <SettingsPopUp
        isSettingsPopUpOpen={isSettingsPopUpOpen}
        setIsSettingsPopUpOpen={setIsSettingsPopUpOpen}
        opacitySettings={opacitySettings}
        setOpacitySettings={setOpacitySettings}
      />
      <QuestionsPopUp
        setIsSettingsPopUpOpen={setIsQuestionsPopUpOpen}
        isSettingsPopUpOpen={isQuestionsPopUpOpen}
        setOpacitySettings={setOpacityQuestions}
      />
    </header>
  );
};

export default HeaderView;
