import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  isRequestPending,
  isRequestError,
  getErrorMessage,
} from "../../utils/store";
import { networkParams } from "../../utils/currencyOptions";

import WithdrawView from "./View";
import { createWithdraw } from "../../actions/withdrawCreate";
import { axios } from "../../utils/request";
import { API } from "../../constants/api";
import { getWithdrawList } from "../../actions/withdrawList";

const Withdraw = ({ handlePopupClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("crypto");
  const copyRef = useRef(null);
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedFiatCurrency, setSelectedFiatCurrency] = useState(null);
  const [selectedNet, setSelectedNet] = useState(null);

  const [rangeAmountLoading, setRangeAmountLoading] = useState(null);
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);

  const [fiatCurrencies, setFiatCurrencies] = useState([]);

  const { balanceData, isLoading, isError, errorMessage } = useSelector(
    ({ balance, withdrawCreate }) => ({
      balanceData: balance.data,
      isLoading: isRequestPending(withdrawCreate.request),
      isError: isRequestError(withdrawCreate.request),
      errorMessage: getErrorMessage(withdrawCreate.request),
    })
  );

  const fetchFiatCurrencies = async () => {
    const { data } = await axios.get(API.GET_FIAT_DEPOSIT_CURRENCIES);

    setFiatCurrencies(data.currencies);
  };

  useEffect(() => {
    fetchFiatCurrencies();
  }, []);

  useEffect(() => {
    if (activeTab) {
      setRangeAmountLoading(true);
      setSelectedCurrency(null);
      setSelectedFiatCurrency(null);
    }
  }, [activeTab]);

  useEffect(() => {
    if (selectedCurrency) {
      setRangeAmountLoading(true);
    }
  }, [selectedCurrency]);

  useEffect(() => {
    if (
      (activeTab === "crypto" && selectedNet && selectedCurrency) ||
      (activeTab === "fiat" && selectedFiatCurrency)
    ) {
      setRangeAmountLoading(true);

      setTimeout(() => {
        axios
          .get(
            API.RANGE_AMOUNT_WITHDRAW(
              activeTab === "crypto"
                ? selectedCurrency?.code
                : selectedFiatCurrency?.code,
              selectedNet?.code
            )
          )
          .then(({ data }) => {
            setMinAmount(data.min);
            setMaxAmount(data.max);
            setRangeAmountLoading(false);
          })
          .catch(() => {});
      }, 200);
    }
  }, [selectedCurrency, selectedFiatCurrency, selectedNet]);

  if (!balanceData?.length) return null;

  const handleSelectCurrency = (currency) => {
    setSelectedCurrency(currency);
    setSelectedNet(null);
    setAmount("");
  };

  const handleSelectFiatCurrency = (currency) => {
    setSelectedFiatCurrency(currency);
    setAmount("");
  };

  const handleSelectNet = (network) => {
    setSelectedNet(network);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      currency: selectedCurrency.code,
      fiatCurrency: selectedFiatCurrency?.code,
      network: selectedNet?.code,
      value: Number(amount),
      address,
    };

    const { success } = await dispatch(createWithdraw(body));

    if (success) {
      await dispatch(getWithdrawList());
      handlePopupClose();
    }
  };

  const currencies = [];
  const nets = [];

  balanceData.forEach((currency) => currencies.push(currency));

  if (selectedCurrency) {
    selectedCurrency.network.forEach((n) => {
      nets.push({ code: n, ...networkParams[n] });
    });
  }

  const currentBalance = balanceData.find(
    (item) => item?.code === selectedCurrency?.code
  );

  const isDisabledFiat = !selectedFiatCurrency || !selectedCurrency;
  const isDisabledCrypto = !selectedCurrency || !selectedNet;

  const isDisabled =
    (activeTab === "crypto" ? isDisabledCrypto : isDisabledFiat) ||
    !address ||
    !amount ||
    isNaN(amount) ||
    Number(amount) > currentBalance?.amount ||
    !minAmount ||
    Number(amount) < minAmount ||
    !maxAmount ||
    Number(amount) > maxAmount;

  let error = null;

  if (Number(amount) > currentBalance?.amount) {
    error = t("CLASSIC.CLASSIC_TAB.INSUFFICIENT_FUNDS");
  }

  return (
    <WithdrawView
      handleSelectCurrency={handleSelectCurrency}
      selectedCurrency={selectedCurrency}
      handleSelectNet={handleSelectNet}
      selectedNet={selectedNet}
      currencies={currencies}
      fiatCurrencies={fiatCurrencies}
      nets={nets}
      setAddress={setAddress}
      address={address}
      amount={amount}
      setAmount={setAmount}
      copyRef={copyRef}
      rangeAmountLoading={rangeAmountLoading}
      minAmount={minAmount}
      maxAmount={maxAmount}
      handleSubmit={handleSubmit}
      loading={isLoading}
      error={isError || !!error}
      errorMessage={errorMessage || error}
      disabled={isDisabled}
      selectedFiatCurrency={selectedFiatCurrency}
      handleSelectFiatCurrency={handleSelectFiatCurrency}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};

export default Withdraw;
