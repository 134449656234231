import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Text } from "../../utils/Text";

export const AirdropPromo = () => {
  const { t } = useTranslation();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [promoInputValue, setPromoInputValue] = useState("");

  const handleCancel = () => {
    setIsConfirmed(false);
  };

  const handleConfirm = () => {
    setIsConfirmed(true);
  };

  const handleBackgroundClick = (event) => {
    if (event.target.classList.contains("bg")) handleCancel();
  };

  const handlePromoInput = (e) => {
    setPromoInputValue(e.target.value);
  };

  const isDisabled = promoInputValue.trim() === "";

  return (
    <React.Fragment>
      <div style={{ marginBottom: "20px" }}>
        <p className="ref__adress-list-title">
          <Text tid="AIRDROP.AIRDROP.PROMO_CODE" />
        </p>
        <button
          className="AirdropLogin__container__check__button airdrop-form__cancel-button"
          onClick={handleConfirm}
        >
          <span className="airdrop-form__button-text">
            <Text tid="AIRDROP.AIRDROP.ENTER_PROMO_CODE" />
          </span>
        </button>
      </div>
      {isConfirmed ? (
        <>
          <div
            className="bg"
            style={{
              opacity: 1,
              zIndex: 99999,
              color: "white",
              width: "100%",
              height: "100%",
            }}
            onClick={handleBackgroundClick}
          >
            <div className="bidPopUp" style={{ opacity: 1, zIndex: 9999 }}>
              <div className="bidPopUp__container" style={{ height: "auto" }}>
                <Text tid="AIRDROP.AIRDROP.ENTER_PROMO_CODE" />
                <hr className="bidPopUp__header-line" />
                <div
                  style={{
                    textAlign: "center",
                    color: "#CACACA",
                    fontWeight: 400,
                    fontSize: 13,
                  }}
                >
                  <div className="airdrop-form">
                    <div className="airdrop-form__input">
                      <input
                        className="wallet__currency-select-box input"
                        placeholder={`${t("AIRDROP.AIRDROP.PROMO_CODE")}...`}
                        onChange={(e) => handlePromoInput(e)}
                        value={promoInputValue}
                      />
                    </div>
                    <div className="airdrop-form__buttons">
                      <button
                        className="AirdropLogin__container__check__button airdrop-form__cancel-button"
                        onClick={handleCancel}
                      >
                        <span className="airdrop-form__button-text">
                          <Text tid="AIRDROP.AIRDROP.CANCEL" />
                        </span>
                      </button>
                      <button
                        className="AirdropLogin__container__check__button airdrop-form__confirm-button"
                        onClick={handleConfirm}
                        style={{
                          opacity: isDisabled ? 0.5 : 1,
                          pointerEvents: isDisabled ? "none" : "auto",
                        }}
                        disabled={isDisabled}
                      >
                        <span className="airdrop-form__button-text">
                          <Text tid="AIRDROP.AIRDROP.CONFIRM" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </React.Fragment>
  );
};
