export const GAME_RULET_TYPES = {
  DEFAULT: "DEFAULT",
};

export const GAME_TYPES = {
  CLASSIC: "CLASSIC",
  DOUBLE: "DOUBLE",
  CRASH: "CRASH",
};

export const GAME_STATUSES = {
  /** Recruting users */
  RECRUITED: "RECRUITED",
  /** Game in archive */
  FINISHED: "FINISHED",
  /** Show results */
  PAUSED: "PAUSED",
  /** Game broken */
  FAILED: "FAILED",
  /** Show spin */
  SPIN: "SPIN",
};
