import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { getPartnerInfo } from "../../actions/settingsPartnerData";
import { getData, isRequestSuccess } from "../../utils/store";
import { SettingsPartnerDataView } from "./View";
import { QUERY_REFERRER } from "../../constants";

export const SettingsPartnerData = () => {
  const [opacityWithdraw, setOpacityWithdraw] = useState(0);

  const handleWithdrawClick = () => {
    setOpacityWithdraw(1);
  };

  const MIN_WITHDRAW = 100;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const copyRef = useRef(null);

  const { user, isLoaded, data } = useSelector(
    ({ user: userData, settingsPartnerData }) => ({
      isLoaded: isRequestSuccess(settingsPartnerData.data),
      data: getData(settingsPartnerData.data),
      user: getData(userData.data),
    })
  );

  useEffect(() => {
    dispatch(getPartnerInfo());
  }, []);

  const link = `https://${window.location.hostname}?${QUERY_REFERRER}=${user.uuid}`;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(link).then(() => {
      toast.success(`${t("MAIN.MAIN.COPY")}`, {
        autoClose: 5000,
        className: "toast-message",
      });
    });
  };

  return (
    <SettingsPartnerDataView
      handleCopyClick={handleCopyClick}
      copyRef={copyRef}
      link={link}
      loaded={isLoaded}
      data={data}
      opacityWithdraw={opacityWithdraw}
      setOpacityWithdraw={setOpacityWithdraw}
      handleWithdrawClick={handleWithdrawClick}
      MIN_WITHDRAW={MIN_WITHDRAW}
    />
  );
};
