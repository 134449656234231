import { axios } from "../utils/request";
import { API } from "../constants/api";

export const DEPOSIT_CREATE = {
  LOADING: "DEPOSIT_CREATE.LOADING",
  SUCCESS: "DEPOSIT_CREATE.SUCCESS",
  ERROR: "DEPOSIT_CREATE.ERROR",
  REQUEST_LOADING: "DEPOSIT_CREATE.REQUEST_LOADING",
  REQUEST_SUCCESS: "DEPOSIT_CREATE.REQUEST_SUCCESS",
  REQUEST_ERROR: "DEPOSIT_CREATE.REQUEST_ERROR",
};

const setLoading = () => ({
  type: DEPOSIT_CREATE.LOADING,
});

export const setSuccess = (data) => ({
  type: DEPOSIT_CREATE.SUCCESS,
  data,
});

const setError = (message) => ({
  type: DEPOSIT_CREATE.ERROR,
  message,
});

const setRequestLoading = () => ({
  type: DEPOSIT_CREATE.REQUEST_LOADING,
});

const setRequestSuccess = (data) => ({
  type: DEPOSIT_CREATE.REQUEST_SUCCESS,
  data,
});

const setRequestError = (message) => ({
  type: DEPOSIT_CREATE.REQUEST_ERROR,
  message,
});

export const createDeposit = (payload) => {
  return (dispatch) => {
    dispatch(setRequestLoading());

    return axios
      .post(API.DEPOSIT_CREATE, {
        ...payload,
        currency: payload.currency.code,
        fiatCurrency: payload.fiatCurrency.code,
        // network: payload.network.code,
      })
      .then(({ data }) => {
        dispatch(
          setRequestSuccess({
            ...data,
            currency: payload.currency,
            network: payload.network,
          })
        );
        return { success: true, data };
      })
      .catch(({ response: { data } }) => {
        dispatch(setRequestError(data.message));
        return { success: false };
      });
  };
};

export const getDepositData = () => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .get(API.GET_DEPOSIT_DATA)
      .then(({ data }) => {
        dispatch(setSuccess(data));
      })
      .catch(({ response: { data } }) => {
        dispatch(setError(data.message));
      });
  };
};

export const createWalletsData = () => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .get(API.CREATE_WALLETS_DATA)
      .then(({ data }) => {
        dispatch(setSuccess(data));
      })
      .catch(({ response: { data } }) => {
        dispatch(setError(data.message));
      });
  };
};

export const cancelDeposit = (payload) => {
  return () => {
    return axios
      .post(API.DEPOSIT_CANCEL, payload)
      .then(({ data }) => {
        return { success: true, data };
      })
      .catch(({ response: { data } }) => {
        return { success: false };
      });
  };
};
