import axios from "axios";
import { DOUBLE_HISTORY } from "../reducers/historyDouble";
import { API } from "../constants/api";

const setLoading = () => {
  return { type: DOUBLE_HISTORY.LOADING };
};

const setLoaded = (payload) => {
  return { type: DOUBLE_HISTORY.LOADED, payload };
};

const seUpdated = (payload) => {
  return { type: DOUBLE_HISTORY.LOADED, payload };
};

const setUpdating = () => {
  return { type: DOUBLE_HISTORY.LOADING };
};

export const getDoubleHistory = () => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const response = await axios.get(API.GET_HISTORY_DOUBLE);
      dispatch(setLoaded(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};
