/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./index.scss";

import User from "../User/index";
import parashut from "../../assets/img/Parachute.svg";
import { Text } from "../../utils/Text";
import gear from "../../assets/img/gear.svg";

import crown from "../../assets/img/Crown.png";
import wheel from "../../assets/img/Wheel.svg";
import cards from "../../assets/img/Cards.svg";
import crash from "../../assets/img/carshicon.svg";
import { ROUTES } from "../../constants/routes";
import PrimaryButton from "../../components/PrimaryButton";
import QuestionsPopUp from "../QuestionsPopUp";

const BurgerPopUpView = ({
  handleWalletClick,
  setOpacity,
  handleWalletSettingsClick,
  handleVoiceClick,
  voiceOn,
  handleGameClick,
  burgerStyles,
  isLogged,
  handelSettingsClick,
  setIsActive,
  isActive
}) => {
  const { pathname } = useLocation();

  const [isQuestionsPopUpOpen, setIsQuestionsPopUpOpen] = useState(false);

  return (
    <header className="header12">
      <div className="container12" id="game" style={burgerStyles}>
        <nav
          className="navigation12"
          style={{ width: !isLogged ? "520px" : "820px" }}
        >
          <div className="navigation12__list">
            {!isLogged ? (
              <React.Fragment>
                <Link to={ROUTES.LOGIN}>
                  <div className="navigation12__login">
                    <a
                      className="navigation12__login-link"
                      onClick={() => setIsActive(false)}
                    >
                      <Text tid="MAIN.MAIN.SIGN_IN" />
                    </a>
                  </div>
                </Link>
                <Link to={ROUTES.SIGNUP}>
                  <PrimaryButton
                    onClick={() => setIsActive(false)}
                    style={{
                      minWidth: "120px",
                      padding: "3px 8px",
                    }}
                  >
                    <span
                      style={{
                        textWrap: "nowrap",
                      }}
                    >
                      <Text tid="AUTH.AUTH.SIGN_UP" />
                    </span>
                  </PrimaryButton>
                </Link>
              </React.Fragment>
            ) : (
              <User
                handleWalletSettingsClick={handleWalletSettingsClick}
                handleWalletClick={handleWalletClick}
                setIsActive={setIsActive}
                setOpacity={setOpacity}
              />
            )}

            <Link
              to={ROUTES.AIR_DROP}
              className="navigation__drop"
              onClick={handelSettingsClick}
            >
              <img src={parashut} alt="parashut" className="parashut" />
              <span className="navigation__drop-text">AIRDROP</span>
            </Link>
            <Link
              to={ROUTES.SETTINGS_GENERAL}
              className="navigation__gear"
              onClick={() => setIsActive(false)}
            >
              <img
                src={gear}
                alt="parashut"
                className="gear"
                style={{
                  height: 20,
                  width: 20,
                }}
              />
              <span className="navigation__gear-text">
                <Text tid="MAIN.MAIN.SETTINGS_GEAR" />
              </span>
            </Link>
            <div className="navigation12__games">
              <div className="navigation12__list-title">
                <h3>
                  <Text tid="MAIN.MAIN.GAME" />
                </h3>
              </div>

              <Link
                to={ROUTES.HOME}
                className={`navigation12__list-item ${
                  pathname === ROUTES.HOME || pathname.includes(ROUTES.CLASSIC)
                    ? "activeGame"
                    : ""
                }`}
                onClick={() => {
                  handleGameClick("classic");
                  handelSettingsClick();
                }}
              >
                {(pathname === ROUTES.HOME ||
                  pathname.includes(ROUTES.CLASSIC)) && (
                  <div className="activeGame__border" />
                )}
                <img src={crown} alt="" />
                <div className="navigation12__list-item-title">
                  <h4 style={{ margin: "0 0 0 0" }}>
                    <Text tid="CLASSIC.CLASSIC_TAB.CLASSIC" />
                  </h4>
                </div>
              </Link>
              <Link
                to={ROUTES.DOUBLE}
                className={`navigation12__list-item ${
                  pathname.includes(ROUTES.DOUBLE) ? "activeGame" : ""
                }`}
                onClick={() => {
                  handleGameClick("double");
                  handelSettingsClick();
                }}
              >
                {pathname.includes(ROUTES.DOUBLE) && (
                  <div className="activeGame__border" />
                )}
                <img src={wheel} alt="" />
                <div className="navigation12__list-item-title">
                  <h4 style={{ margin: "0 0 0 0" }}>
                    <Text tid="DOUBLE.DOUBLE_TAB.DOUBLE" />
                  </h4>
                </div>
              </Link>
              <Link
                to={ROUTES.CARDS}
                className={`navigation12__list-item ${
                  pathname.includes(ROUTES.CARDS) ? "activeGame" : ""
                }`}
                onClick={() => {
                  handleGameClick("cards");
                  handelSettingsClick();
                }}
              >
                {pathname.includes(ROUTES.CARDS) && (
                  <div className="activeGame__border" />
                )}
                <img src={cards} alt="" />
                <div className="navigation12__list-item-title">
                  <h4 style={{ margin: "0 0 0 0" }}>
                    <Text tid="CARDS.CARDS_TAB.CARDS" />
                  </h4>
                </div>
              </Link>
              <Link
                to={ROUTES.CRASH}
                className={`navigation12__list-item ${
                  pathname.includes(ROUTES.CRASH) ? "activeGame" : ""
                }`}
                onClick={() => {
                  handleGameClick("crash");
                  handelSettingsClick();
                }}
              >
                {pathname.includes(ROUTES.CRASH) && (
                  <div className="activeGame__border" />
                )}
                <img src={crash} alt="" />
                <div className="navigation12__list-item-title">
                  <h4 style={{ margin: "0 0 0 0" }}>
                    <Text tid="CRASH.CRASH_TAB.CRASH" />
                  </h4>
                </div>
              </Link>
              <div className="navigation12__more-game">
                <Text tid="MAIN.MAIN.MORE_GAMES" />
              </div>
            </div>
          </div>
          <div className="navigation12__box" style={{ position: "relative" }}>
            <div
              className={`navigation12__link ${voiceOn ? "voice" : "voiceOff"}`}
              title="tours"
              onClick={handleVoiceClick}
            />
            <div
              onClick={() => setIsQuestionsPopUpOpen(true)}
              className="navigation12__link help"
              title="tours"
            />
            <QuestionsPopUp
              setIsSettingsPopUpOpen={setIsQuestionsPopUpOpen}
              isSettingsPopUpOpen={isQuestionsPopUpOpen && isActive}
              isMob
            />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default BurgerPopUpView;
