import { SETTINGS_UPDATE_EMAIL } from "../actions/settingsUpdateEmail";
import {
  initRequestState,
  setRequestError,
  setRequestPending,
  setRequestSuccess,
} from "../utils/store";

const initialState = {
  request: initRequestState(),
};

export const settingsUpdateEmail = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_UPDATE_EMAIL.SUCCESS:
      return {
        ...state,
        request: setRequestSuccess(state.request),
      };

    case SETTINGS_UPDATE_EMAIL.LOADING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };

    case SETTINGS_UPDATE_EMAIL.ERROR:
      return {
        ...state,
        request: setRequestError(state.request, action.message),
      };

    case SETTINGS_UPDATE_EMAIL.RESET:
      return {
        ...state,
        request: initRequestState(),
      };

    default:
      return state;
  }
};
