export const AUTH_USER = {
  ID: "id",
  ROLE: "role",
  EMAIL: "email",
  NICKNAME: "nickname",
  PASSWORD: "password",
  CONFIRM_CODE: "code",
  CAPTCHA: "captcha",
  CHECKED: "checked",
  REFERRER: "referrer",
  PHOTO: "photo",
};

export const RECOVERY_ACCOUNT_RESET = {
  EMAIL: "email",
};

export const RECOVERY_ACCOUNT_UPDATE = {
  EMAIL: "email",
  CODE: "code",
  PASSWORD: "password",
  REPEAT_PASSWORD: "repeatPassword",
};

export const RECOVERY_ACCOUNT_CONFIRM = {
  EMAIL: "email",
  CODE: "code",
};

export const SETTINGS = {
  CURRENT_PASSWORD: "currentPassword",
  NEW_PASSWORD: "newPassword",
  REPEAT_NEW_PASSWORD: "repeatNewPassword",
  PASSWORD: "password",
  CONFIRM_CODE: "code",
  NICKNAME: "nickname",
  EMAIL: "email",
  PHOTO: "photo",
};

export const PARTNER_APPLICATION = {
  DESCRIPTION: "description",
  TELEGRAM: "telegram",
};
