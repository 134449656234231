import React from "react";

import { Text } from "../../utils/Text";
import PrimaryButton from "../../components/PrimaryButton";

export const SettingsLogoutView = ({ onSubmit }) => {
  return (
    <React.Fragment>
      <div>
        <p className="settings-security__password-box-title">
          <Text tid="SETTINGS.SETTINGS_TAB.LOGOUT" />
        </p>
        <form className="settings-security__password-box" onSubmit={onSubmit}>
          <PrimaryButton type="submit">
            <span>
              <Text tid="SETTINGS.SETTINGS_TAB.LOGOUT_BUTTON" />
            </span>
          </PrimaryButton>
        </form>
      </div>
    </React.Fragment>
  );
};
