import {
  initRequestState,
  setUpdatePending,
  setUpdateWithPaginationSuccess,
  setRequestPending,
  setRequestSuccess,
  initRequestActions,
} from "../utils/store";

const initialState = {
  request: initRequestState(),
};

export const HISTORY = initRequestActions("HISTORY");

export const historyClassic = (state = initialState, action) => {
  switch (action.type) {
    case HISTORY.LOADING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };

    case HISTORY.LOADED:
      return {
        ...state,
        request: setRequestSuccess(state.request, action.payload?.data),
        meta: {
          historyLength: action.payload.historyLength,
          skip: action.payload.skip,
        },
      };

    case HISTORY.UPDATING:
      return {
        ...state,
        request: setUpdatePending(state.request),
      };

    case HISTORY.UPDATED:
      return {
        ...state,
        request: setUpdateWithPaginationSuccess(state.request, [
          ...state.data,
          ...action.payload.data,
        ]),
        meta: {
          historyLength: action.payload.historyLength,
          skip: action.payload.skip,
        },
      };

    default:
      return state;
  }
};
