import React from "react";

import LoadingButton from "@mui/lab/LoadingButton";

import "./index.scss";
import { currencyCodeParams } from "../../utils/currencyOptions";

const PrimaryButton = ({
  children,
  className,
  onClick,
  disabled,
  loading,
  isCrash,
  iValue,
  isFiat,
  curr,
  ...props
}) => {
  return (
    <LoadingButton
      loading={loading}
      className={`PrimaryButton ${className}`}
      onClick={onClick}
      disabled={disabled}
      varitant="outlined"
      {...props}
    >
      {isCrash ? (
        <span 
        style={{
          position: "relative",
          zIndex: 19,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",

        }}
        >
          {iValue}
          {isFiat ? "$" : ""}
          <img
            src={currencyCodeParams[curr?.code]?.icon}
            className="double__bet__img"
            alt=""
          />
        </span>
      ) : null}
      {children}
    </LoadingButton>
  );
};

export default PrimaryButton;
