import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { getData, isRequestSuccess } from "../../utils/store";
import { SettingsReferralLinkView } from "./View";
import { getReferralInfo } from "../../actions/settingsReferralLink";
import { QUERY_REFERRER } from "../../constants";

export const SettingsReferralLink = ({ withReferralInfo = true }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const copyRef = useRef(null);

  const { user, isLoaded, data } = useSelector(
    ({ user: userData, settingsReferralLink }) => ({
      isLoaded: isRequestSuccess(settingsReferralLink.data),
      data: getData(settingsReferralLink.data),
      user: getData(userData.data),
    })
  );

  useEffect(() => {
    if (withReferralInfo) {
      dispatch(getReferralInfo());
    }
  }, []);

  const link = `https://${window.location.hostname}?${QUERY_REFERRER}=${user.uuid}`;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(link).then(() => {
      toast.success(`${t("MAIN.MAIN.COPY")}`, {
        autoClose: 5000,
        className: "toast-message",
      });
    });
  };

  return (
    <SettingsReferralLinkView
      handleCopyClick={handleCopyClick}
      copyRef={copyRef}
      link={link}
      loaded={isLoaded}
      data={data}
      withReferralInfo={withReferralInfo}
    />
  );
};
