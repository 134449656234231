import React from "react";
import { ClipLoader } from "react-spinners";
import "./index.scss";

const Loader = () => {
  return (
    <ClipLoader
      className="clip-loader"
      speedMultiplier={0.6}
      color="#525252"
      loading
    />
  );
};

export default Loader;
