import { DEPOSIT_LIST } from "../actions/depositList";
import {
  setRequestError,
  setRequestPending,
  setRequestSuccess,
  initRequestWithDataState,
} from "../utils/store";

const initialState = {
  data: initRequestWithDataState(),
};

export const depositList = (state = initialState, action) => {
  switch (action.type) {
    case DEPOSIT_LIST.SUCCESS:
      return {
        ...state,
        data: setRequestSuccess(state.data, action.data),
      };

    case DEPOSIT_LIST.LOADING:
      return {
        ...state,
        data: setRequestPending(state.data),
      };

    case DEPOSIT_LIST.ERROR:
      return {
        ...state,
        data: setRequestError(state.data, action.message),
      };

    default:
      return state;
  }
};
