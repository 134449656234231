import axios from "axios";
import { BEST_USERS_CLASSIC } from "../reducers/topClassicBestUsers";
import { API } from "../constants/api";

const setLoading = () => {
  return { type: BEST_USERS_CLASSIC.LOADING };
};

const setLoaded = (payload) => {
  return { type: BEST_USERS_CLASSIC.LOADED, payload };
};

export const getBestUsersClassic = () => {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const response = await axios.get(API.GET_HISTORY_CLASSIC_BEST_USERS);
      dispatch(setLoaded(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};
