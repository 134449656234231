import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  getData,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../utils/store";

import { getWithdrawList } from "../../actions/withdrawList";
import { getWithdrawPartnerList } from "../../actions/withdrawPartnerList";
import { getDepositList } from "../../actions/depositList";

import { PaymentListView } from "./View";

export const PaymentList = ({
  type,
  handleWalletClick,
  setWalletActiveTab,
}) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const {
    isWithdrawListLoading,
    isWithdrawListSuccess,
    isWithdrawListError,
    withdrawList,
    isDepositListLoading,
    isDepositListSuccess,
    isDepositListError,
    depositList,
    isWithdrawPartnerListLoading,
    isWithdrawPartnerListSuccess,
    isWithdrawPartnerListError,
    withdrawPartnerList,
  } = useSelector(
    ({
      withdrawList: withdrawListData,
      withdrawPartnerList: withdrawPartnerListData,
      depositList: depositListData,
    }) => ({
      isWithdrawListLoading: isRequestPending(withdrawListData.data),
      isWithdrawListSuccess: isRequestSuccess(withdrawListData.data),
      isWithdrawListError: isRequestError(withdrawListData.data),
      withdrawList: getData(withdrawListData.data),
      isDepositListLoading: isRequestPending(depositListData.data),
      isDepositListSuccess: isRequestSuccess(depositListData.data),
      isDepositListError: isRequestError(depositListData.data),
      depositList: getData(depositListData.data),
      isWithdrawPartnerListLoading: isRequestPending(
        withdrawPartnerListData.data
      ),
      isWithdrawPartnerListSuccess: isRequestSuccess(
        withdrawPartnerListData.data
      ),
      isWithdrawPartnerListError: isRequestError(withdrawPartnerListData.data),
      withdrawPartnerList: getData(withdrawPartnerListData.data),
    })
  );

  const fetchData = () => {
    return type === "DEPOSIT"
      ? dispatch(getDepositList())
      : type === "WITHDRAW"
        ? dispatch(getWithdrawList())
        : type === "WITHDRAW_PARTNER"
          ? dispatch(getWithdrawPartnerList())
          : null;
  };

  const handleClickButton = () => {
    handleWalletClick();
    setWalletActiveTab(type.toLowerCase());
  };

  const isButtonExists = type === "DEPOSIT" || type === "WITHDRAW";

  useEffect(() => {
    fetchData();
  }, [location.pathname]);

  const getIsLoading = () => {
    return type === "DEPOSIT"
      ? isDepositListLoading
      : type === "WITHDRAW"
        ? isWithdrawListLoading
        : type === "WITHDRAW_PARTNER"
          ? isWithdrawPartnerListLoading
          : null;
  };

  const getIsLoaded = () => {
    return type === "DEPOSIT"
      ? isDepositListSuccess
      : type === "WITHDRAW"
        ? isWithdrawListSuccess
        : type === "WITHDRAW_PARTNER"
          ? isWithdrawPartnerListSuccess
          : null;
  };

  const getIsError = () => {
    return type === "DEPOSIT"
      ? isDepositListError
      : type === "WITHDRAW"
        ? isWithdrawListError
        : type === "WITHDRAW_PARTNER"
          ? isWithdrawPartnerListError
          : null;
  };

  const getList = () => {
    return type === "DEPOSIT"
      ? depositList
      : type === "WITHDRAW"
        ? withdrawList
        : type === "WITHDRAW_PARTNER"
          ? withdrawPartnerList
          : null;
  };

  const loading = getIsLoading();
  const loaded = getIsLoaded();
  const error = getIsError();

  const list = getList();

  return (
    <PaymentListView
      loading={loading}
      loaded={loaded}
      error={error}
      list={list}
      isButtonExists={isButtonExists}
      handleClickButton={handleClickButton}
      type={type}
    />
  );
};
