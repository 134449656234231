import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { TopOfView } from "./View";
import "./index.scss";

const TopOf = () => {
  const { users, dateFromBackend } = useSelector(({ topClassic }) => ({
    dateFromBackend: topClassic.data?.date,
    users: topClassic.data?.top,
  }));

  const initedDate = moment.utc(dateFromBackend);

  const [timeToSummarizing, setTimeToSummarizing] = useState("");
  const [currentDate, setCurrentDate] = useState(null);

  const showRemainingTime = () => {
    if (!currentDate) setCurrentDate(initedDate.clone());
    else setCurrentDate(moment.utc(currentDate).add(1, "second").clone());

    const endOfDay = moment().endOf("day");

    const remainingTime = moment
      .duration(endOfDay.diff(currentDate || initedDate))
      .asMilliseconds();

    setTimeToSummarizing(moment(remainingTime).format("HH:mm:ss"));
  };

  useEffect(() => {
    const interval = setInterval(showRemainingTime, 1000);
    return () => clearInterval(interval);
  }, [currentDate]);

  useEffect(() => {
    if (!currentDate && dateFromBackend) showRemainingTime();
  }, [dateFromBackend]);

  return <TopOfView users={users} timeToSummarizing={timeToSummarizing} />;
};

export default TopOf;
