import React from "react";
import "./index.scss";

import { Text } from "../../utils/Text";

const TopWinnersView = ({
  numberItems,
  userItems,
  totalWinsItems,
  profitItems,
  usersTopData,
}) => {
  return (
    <div className="topWinners">
      {!usersTopData?.length ? (
        <div className="topWinners__top-is-empty">
          <Text tid="MAIN.MAIN.TOP_FOR_TIME_EMPTY" />
        </div>
      ) : (
        <div className="topWinners__container">
          <div className="topWinners__users-position">
            <div className="topWinners__users">
              <div className="topWinners__number">
                <div className="topWinners__number-title">№</div>
                {numberItems}
              </div>
              <div className="topWinners__user">
                <div className="topWinners__user-title">
                  <Text tid="MAIN.MAIN.USERS" />
                </div>
                {userItems}
              </div>
            </div>
          </div>
          <div className="topWinners__profit-position">
            <div className="topWinners__users">
              <div className="topWinners__wins">
                <div className="topWinners__wins-title">
                  <Text tid="MAIN.MAIN.TOTAL_WINS" />
                </div>
                {totalWinsItems}
              </div>
              <div className="topWinners__profit">
                <div className="topWinners__profit-title">
                  <Text tid="MAIN.MAIN.PROFIT" />
                </div>
                {profitItems}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopWinnersView;
