/* eslint-disable consistent-return */
import { useEffect, useState } from "react";

export const useChatTrigger = ({ isChatOpened }) => {
  const [isOpenTrigger, setIsOpenTrigger] = useState(false);
  const [chatTimer, setChatTimer] = useState(null);

  useEffect(() => {
    if (isChatOpened) setIsOpenTrigger(true);
    else if (isOpenTrigger) {
      if (chatTimer) clearTimeout(chatTimer);
      const timeout = setTimeout(() => {
        setIsOpenTrigger(false);
        setChatTimer(null);
      }, 250);
      setChatTimer(timeout);
      return () => {
        clearTimeout(chatTimer);
        setChatTimer(null);
      };
    }
  }, [isChatOpened]);

  return { isOpenTrigger, chatTimer };
};
