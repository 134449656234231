import React from "react";

import { Text } from "../../utils/Text";

const LoadLineView = ({ formattedTime, membersCount, lineWidth, total }) => {
  return (
    <div
      className="load__box"
      style={{
        position: "relative",
        overflow: "hidden",
        marginTop: "12px",
        height: "125px",
      }}
    >
      <div
        className="load__line"
        style={{
          backgroundColor: "#303030",
          alignItems: "center",
          position: "absolute",
          display: "flex",
          height: 78,
          zIndex: 5,
          top: 0,
        }}
      >
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          <div
            style={{
              top: 0,
              left: 0,
              height: "100%",
              position: "absolute",
              width: `${lineWidth}%`,
              background: `linear-gradient(90deg, #367AFF 0%, #FF3636 100%)`,
              transition: "width 1s ease",
            }}
          />
          <div className="load__line-progress" />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <span
              className="players"
              style={{
                fontWeight: 500,
                marginBottom: 6,
                color: "white",
                fontSize: 14,
              }}
            >
              <Text tid="CLASSIC.CLASSIC_TAB.PLAYRES" />
            </span>
            <span
              className="players-counter"
              style={{ color: "white", fontSize: 26, fontWeight: 600 }}
            >{`${membersCount}/${total}`}</span>
          </div>
        </div>
      </div>
      <div
        className="load__timer"
        style={{ position: "absolute", zIndex: 4, bottom: 0 }}
      >
        {formattedTime}
      </div>
    </div>
  );
};

export default LoadLineView;
