import { SETTINGS_REFERRAL_LINK } from "../actions/settingsReferralLink";
import {
  initRequestWithDataState,
  setRequestError,
  setRequestPending,
  setRequestSuccess,
} from "../utils/store";

const initialState = {
  data: initRequestWithDataState(),
};

export const settingsReferralLink = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_REFERRAL_LINK.SUCCESS:
      return {
        ...state,
        data: setRequestSuccess(state.data, action.data),
      };

    case SETTINGS_REFERRAL_LINK.LOADING:
      return {
        ...state,
        data: setRequestPending(state.data),
      };

    case SETTINGS_REFERRAL_LINK.ERROR:
      return {
        ...state,
        data: setRequestError(state.data, action.message),
      };

    default:
      return state;
  }
};
