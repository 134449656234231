import React from "react";
import { Text } from "../../utils/Text";
import "./index.scss";

export const TermsOfConditionsView = () => {
  return (
    <div className="termsOfConditionsView-container">
      <h1>
        <Text tid="AUTH.AUTH.TERMS_OF_USE" />
      </h1>
      <div>
        <div style={{ marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.TERMS-T.B1_TITLE" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B1_S1" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B1_S2" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.TERMS-T.B2_TITLE" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B2_S1" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B2_S2" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B2_S3" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.TERMS-T.B3_TITLE" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B3_S1" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B3_S2" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.TERMS-T.B4_TITLE" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B4_S1" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B4_S2" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.TERMS-T.B5_TITLE" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B5_S1" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.TERMS-T.B6_TITLE" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B6_S1" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B6_S2" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B6_S3" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.TERMS-T.B7_TITLE" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B7_S1" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B7_S2" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.TERMS-T.B8_TITLE" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B8_S1" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B8_S2" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.TERMS-T.B9_TITLE" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B9_S1" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B9_S2" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.TERMS-T.B10_TITLE" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B10_S1" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B10_S2" />
        </div>
        {/* ------------- */}
        <div style={{ marginTop: 32, marginBottom: 12, fontWeight: "bold" }}>
          <Text tid="AUTH.AUTH.TERMS-T.B11_TITLE" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B11_S1" />
        </div>
        <div style={{ fontSize: 12, marginTop: 8, marginBottom: 8 }}>
          <Text tid="AUTH.AUTH.TERMS-T.B11_S2" />
        </div>
        {/* ------------- */}
      </div>
    </div>
  );
};
