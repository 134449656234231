import { axios, setAuthorization } from "../utils/request";

import { API } from "../constants/api";
import { ROUTES } from "../constants/routes";
import { setAuthData } from "./auth";
import { getUser } from "./user";

export const SIGNUP_CONFIRM_CODE = {
  LOADING: "SIGNUP_CONFIRM_CODE.LOADING",
  SUCCESS: "SIGNUP_CONFIRM_CODE.SUCCESS",
  ERROR: "SIGNUP_CONFIRM_CODE.ERROR",
};

const setError = (message) => ({
  type: SIGNUP_CONFIRM_CODE.ERROR,
  message,
});

const setSuccess = (token) => ({
  type: SIGNUP_CONFIRM_CODE.SUCCESS,
  token,
});

const setLoading = () => ({
  type: SIGNUP_CONFIRM_CODE.LOADING,
});

export const confirmCode = (payload, navigate) => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .post(API.SIGNUP_CONFIRM, payload)
      .then(({ data }) => {
        setAuthorization(data.accessToken);
        dispatch(setAuthData(data.accessToken));
        dispatch(setSuccess(data.accessToken));
        dispatch(getUser());
        navigate(ROUTES.HOME);
      })
      .catch(({ response: { data } }) => {
        dispatch(setError(data.message));
      });
  };
};
