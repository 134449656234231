import { axios } from "../utils/request";
import { API } from "../constants/api";

export const SETTINGS_UPDATE_PASSWORD = {
  LOADING: "SETTINGS_UPDATE_PASSWORD.LOADING",
  SUCCESS: "SETTINGS_UPDATE_PASSWORD.SUCCESS",
  ERROR: "SETTINGS_UPDATE_PASSWORD.ERROR",
  RESET: "SETTINGS_UPDATE_PASSWORD.RESET",
};

const setLoading = () => ({
  type: SETTINGS_UPDATE_PASSWORD.LOADING,
});

const setSuccess = () => ({
  type: SETTINGS_UPDATE_PASSWORD.SUCCESS,
});

const setError = (message) => ({
  type: SETTINGS_UPDATE_PASSWORD.ERROR,
  message,
});

export const resetUpdatePassword = () => ({
  type: SETTINGS_UPDATE_PASSWORD.RESET,
});

export const updatePassword = (payload) => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .patch(API.SETTINGS_UPDATE_PASSWORD, payload)
      .then(() => {
        dispatch(setSuccess());
        return { success: true };
      })
      .catch(({ response: { data } }) => {
        dispatch(setError(data.message));
        return { sucess: false };
      });
  };
};
