import { axios } from "../utils/request";
import { API } from "../constants/api";

export const SETTINGS_UPDATE_NICKNAME = {
  LOADING: "SETTINGS_UPDATE_NICKNAME.LOADING",
  SUCCESS: "SETTINGS_UPDATE_NICKNAME.SUCCESS",
  ERROR: "SETTINGS_UPDATE_NICKNAME.ERROR",
  RESET: "SETTINGS_UPDATE_NICKNAME.RESET",
};

const setLoading = () => ({
  type: SETTINGS_UPDATE_NICKNAME.LOADING,
});

const setSuccess = () => ({
  type: SETTINGS_UPDATE_NICKNAME.SUCCESS,
});

const setError = (message) => ({
  type: SETTINGS_UPDATE_NICKNAME.ERROR,
  message,
});

export const resetUpdateNickname = () => ({
  type: SETTINGS_UPDATE_NICKNAME.RESET,
});

export const updateNickname = (payload) => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .patch(API.SETTINGS_UPDATE_NICKNAME, payload)
      .then(() => {
        dispatch(setSuccess());
        return { success: true };
      })
      .catch(({ response: { data } }) => {
        dispatch(setError(data.message));
        return { sucess: false };
      });
  };
};
