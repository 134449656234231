import React from "react";
import { useTranslation } from "react-i18next";

import "./index.scss";

import { Text } from "../../utils/Text";
import { SETTINGS } from "../../constants/fields";
import PrimaryButton from "../../components/PrimaryButton";
import { AlertMessage } from "../../components/AlertMessage";

export const SettingsUpdateNicknameView = ({
  fields,
  changeField,
  disabled,
  onSubmit,
  loading,
  error,
  errorMessage,
  success,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div>
        <p className="settings-security__password-box-title">
          <Text tid="SETTINGS.SETTINGS_TAB.NICKNAME" />
        </p>
        <form className="settings-security__password-box" onSubmit={onSubmit}>
          <div>
            <p className="settings-security__password-box-text text">
              <Text tid="SETTINGS.SETTINGS_TAB.NICKNAME" />
            </p>
            <div className="settings-security__password-box-input-wrapper">
              <input
                className="settings-security__password-box-input input"
                type="text"
                placeholder={t("SETTINGS.SETTINGS_TAB.ENTER_NICKNAME")}
                value={fields[SETTINGS.NICKNAME]}
                onChange={(e) => changeField(SETTINGS.NICKNAME, e.target.value)}
                required
                minLength={4}
                maxLength={30}
              />
            </div>
          </div>

          <PrimaryButton
            type="submit"
            disabled={disabled}
            loading={loading}
            // className="settings-security__password-box-button button"
          >
            <span>
              <Text tid="MAIN.MAIN.SAVE" />
            </span>
          </PrimaryButton>

          {error && <AlertMessage message={errorMessage} type="error" />}
          {success && (
            <AlertMessage
              message="SETTINGS.SETTINGS_TAB.UPDATE_NICKNAME_SUCCESS"
              type="success"
            />
          )}
        </form>
      </div>
    </React.Fragment>
  );
};
