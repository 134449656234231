/* eslint-disable react/no-array-index-key */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersTopClassic } from "../../actions/topClassic";
import { UserProfile } from "../../components/UserProfile";
import { TOP_TYPES } from "../../constants/top-type";
import Loader from "../../components/Loader";
import { fix2 } from "../../utils/fixers";
import TopWinnersView from "./View";

const TopWinners = () => {
  const dispatch = useDispatch();

  const { usersTopData } = useSelector(({ topClassic }) => ({
    usersTopData: topClassic.data?.top,
  }));

  useEffect(() => {
    dispatch(getUsersTopClassic(TOP_TYPES.TODAY));
  }, []);

  if (!usersTopData) {
    return (
      <div className="topWinners">
        <Loader />
      </div>
    );
  }

  const numberItems = usersTopData.map((user, index) => (
    <div key={index} className="topWinners__number-item">
      {index + 1}
    </div>
  ));

  const userItems = usersTopData.map((user, index) => (
    <div key={index} className="topWinners__user-item">
      <UserProfile user={user}>
        <img src={user.photo} alt="avatar" />
      </UserProfile>
      <div className="topWinners__user-name">{user.name}</div>
    </div>
  ));

  const totalWinsItems = usersTopData.map((user, index) => (
    <div key={index} className="topWinners__wins-item">
      {user.totalWins}
    </div>
  ));

  const profitItems = usersTopData.map((user, index) => (
    <div key={index} className="topWinners__profit-item">
      {fix2(user.profit)}$
    </div>
  ));

  return (
    <TopWinnersView
      numberItems={numberItems}
      userItems={userItems}
      totalWinsItems={totalWinsItems}
      profitItems={profitItems}
      usersTopData={usersTopData}
    />
  );
};

export default TopWinners;
