import React, { useState } from "react";
import { useDispatch } from "react-redux";

import "./index.scss";
import { getUsersTopClassic } from "../../actions/topClassic";
import { TOP_TYPES } from "../../constants/top-type";
import { HeaderTopView } from "./View";

const HeaderTop = () => {
  const dispatch = useDispatch();

  const [currentTopTime, setCurrentTopTime] = useState(TOP_TYPES.TODAY);

  const handleShowTodayTop = () => {
    if (currentTopTime !== TOP_TYPES.TODAY) {
      setCurrentTopTime(TOP_TYPES.TODAY);
      dispatch(getUsersTopClassic(TOP_TYPES.TODAY));
    }
  };

  const handleShowYesterdayTop = () => {
    if (currentTopTime !== TOP_TYPES.YESTERDAY) {
      setCurrentTopTime(TOP_TYPES.YESTERDAY);
      dispatch(getUsersTopClassic(TOP_TYPES.YESTERDAY));
    }
  };

  const handleShowLastYearTop = () => {
    if (currentTopTime !== TOP_TYPES.LAST_YEAR) {
      setCurrentTopTime(TOP_TYPES.LAST_YEAR);
      dispatch(getUsersTopClassic(TOP_TYPES.LAST_YEAR));
    }
  };

  return (
    <HeaderTopView
      currentTopTime={currentTopTime}
      handleShowLastYearTop={handleShowLastYearTop}
      handleShowYesterdayTop={handleShowYesterdayTop}
      handleShowTodayTop={handleShowTodayTop}
    />
  );
};

export default HeaderTop;
