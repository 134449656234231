import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SettingsUpdateEmailView } from "./View";

import {
  resetUpdateEmail,
  updateEmail,
} from "../../actions/settingsUpdateEmail";
import { sendCode, resetSendCode } from "../../actions/settingsSendCode";
import { SETTINGS } from "../../constants/fields";
import {
  getData,
  getErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../utils/store";
import { getUser } from "../../actions/user";

export const SettingsUpdateEmail = () => {
  const {
    isUpdateEmailLoading,
    isUpdateEmailSuccess,
    isUpdateEmailError,
    updateEmailErrorMessage,
    isSendCodeLoading,
    isSendCodeSuccess,
    isSendCodeError,
    sendCodeData,
    sendCodeErrorMessage,
    user,
  } = useSelector(
    ({ settingsUpdateEmail, settingsSendCode, user: userData }) => ({
      isUpdateEmailLoading: isRequestPending(settingsUpdateEmail.request),
      isUpdateEmailSuccess: isRequestSuccess(settingsUpdateEmail.request),
      isUpdateEmailError: isRequestError(settingsUpdateEmail.request),
      updateEmailErrorMessage: getErrorMessage(settingsUpdateEmail.request),
      isSendCodeLoading: isRequestPending(settingsSendCode.request),
      isSendCodeSuccess: isRequestSuccess(settingsSendCode.request),
      isSendCodeError: isRequestError(settingsSendCode.request),
      sendCodeData: getData(settingsSendCode.request),
      sendCodeErrorMessage: getErrorMessage(settingsSendCode.request),
      user: getData(userData.data),
    })
  );

  const dispatch = useDispatch();

  const defaultFields = {
    email: user.email,
  };

  const [fields, setFields] = useState(defaultFields);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateEmail());
      dispatch(resetSendCode());
    };
  }, []);

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const onSubmit = async ({ code }) => {
    const { success } = await dispatch(
      updateEmail({
        [SETTINGS.EMAIL]: fields.email,
        [SETTINGS.CONFIRM_CODE]: code,
      })
    );

    if (success) {
      dispatch(getUser());
      dispatch(resetSendCode());
    }
  };

  const submitSendCode = async (e) => {
    e.preventDefault();

    await dispatch(
      sendCode({
        type: "email",
      })
    );
  };

  const isFormDisabled = !fields.email || fields.email === user.email;

  return (
    <SettingsUpdateEmailView
      fields={fields}
      changeField={changeField}
      disabled={isFormDisabled}
      submitSendCode={submitSendCode}
      onSubmit={onSubmit}
      loading={isSendCodeLoading}
      error={isSendCodeError}
      errorMessage={sendCodeErrorMessage}
      success={isSendCodeSuccess}
      isUpdateEmailSuccess={isUpdateEmailSuccess}
      isUpdateEmailLoading={isUpdateEmailLoading}
      isUpdateEmailError={isUpdateEmailError}
      updateEmailErrorMessage={updateEmailErrorMessage}
      sendCodeData={sendCodeData}
    />
  );
};
