import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";

import { networkParams } from "../../utils/currencyOptions";
import {
  isRequestPending,
  isRequestError,
  getErrorMessage,
  isRequestSuccess,
  getData,
} from "../../utils/store";

import DepositView from "./View";

import { axios } from "../../utils/request";
import { API } from "../../constants/api";
import {
  getDepositData,
  createWalletsData,
  createDeposit,
} from "../../actions/depositCreate";

const Deposit = () => {
  const { i18n } = useTranslation();

  const [activeTab, setActiveTab] = useState("crypto");
  const [amount, setAmount] = useState("");
  const dispatch = useDispatch();

  const [selectedFiatCurrency, setSelectedFiatCurrency] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedNet, setSelectedNet] = useState(null);

  const copyRef = useRef(null);

  const [rangeAmountLoading, setRangeAmountLoading] = useState(null);

  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);

  const [fiatCurrencies, setFiatCurrencies] = useState([]);

  const { t } = useTranslation();

  const {
    balanceData,
    isRequestLoading,
    isLoaded,
    isError,
    errorMessage,
    depositData,
    requestError,
    requestErrorMessage,
  } = useSelector(({ balance, depositCreate }) => ({
    errorMessage: getErrorMessage(depositCreate.data),
    isRequestLoading: isRequestPending(depositCreate.request),
    requestError: isRequestError(depositCreate.data),
    requestErrorMessage: getErrorMessage(depositCreate.data),
    isLoaded: isRequestSuccess(depositCreate.data),
    isError: isRequestError(depositCreate.data),
    depositData: getData(depositCreate.data),
    balanceData: balance.data,
  }));

  const address = depositData.wallets?.find(
    (item) =>
      item.currency.toLowerCase() === selectedCurrency?.code?.toLowerCase() &&
      item.network.toLowerCase() === selectedNet?.code?.toLowerCase()
  )?.address;

  const fetchFiatCurrencies = async () => {
    const { data } = await axios.get(API.GET_FIAT_DEPOSIT_CURRENCIES);

    setFiatCurrencies(data.currencies);
  };

  useEffect(() => {
    fetchFiatCurrencies();
  }, []);

  useEffect(() => {
    if (activeTab) {
      setRangeAmountLoading(true);
      setSelectedCurrency(null);
      setSelectedFiatCurrency(null);
    }
  }, [activeTab]);

  useEffect(() => {
    if (!isLoaded && !isError) {
      dispatch(getDepositData());
    }
  }, []);

  useEffect(() => {
    if (isError && errorMessage === "USER_WALLETS_NOT_EXIST") {
      dispatch(createWalletsData());
    }
  }, [isError, errorMessage]);

  useEffect(() => {
    setSelectedCurrency(null);
    setSelectedNet(null);
  }, [activeTab]);

  useEffect(() => {
    if (selectedCurrency) {
      setRangeAmountLoading(true);
    }
  }, [selectedCurrency]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      currency: selectedCurrency,
      fiatCurrency: selectedFiatCurrency,
      value: Number(amount),
      lang: i18n.language,
    };

    const { success, data } = await dispatch(createDeposit(body));

    if (success) {
      window.location.href = data.link;
    }

    // handlePopupClose();
  };

  useEffect(() => {
    if (
      (activeTab === "crypto" && selectedNet && selectedCurrency) ||
      (activeTab === "fiat" && selectedFiatCurrency)
    ) {
      setRangeAmountLoading(true);

      setTimeout(() => {
        axios
          .get(
            API.RANGE_AMOUNT_DEPOSIT(
              activeTab === "crypto"
                ? selectedCurrency?.code
                : selectedFiatCurrency?.code,
              selectedNet?.code
            )
          )
          .then(({ data }) => {
            setMinAmount(data.min);
            setMaxAmount(data.max);
            setRangeAmountLoading(false);
          })
          .catch(() => {});
      }, 200);
    }
  }, [selectedCurrency, selectedFiatCurrency, selectedNet]);

  if (!balanceData?.length) return null;

  const handleSelectCurrency = (currency) => {
    setSelectedCurrency(currency);
    setSelectedNet(null);
    setAmount("");
  };

  const handleSelectFiatCurrency = (currency) => {
    setSelectedFiatCurrency(currency);
    setAmount("");
  };

  const handleSelectNet = (network) => {
    setSelectedNet(network);
  };

  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success(`${t("MAIN.MAIN.COPY")}`, {
        autoClose: 5000,
        className: "toast-message",
      });
    });
  };

  const currencies = [];
  const nets = [];

  balanceData.forEach((currency) => currencies.push(currency));

  if (selectedCurrency) {
    selectedCurrency.network.forEach((n) => {
      nets.push({ code: n, ...networkParams[n] });
    });
  }

  const isDisabled =
    !selectedFiatCurrency ||
    !selectedCurrency ||
    !amount ||
    isNaN(amount) ||
    !minAmount ||
    Number(amount) < minAmount ||
    !maxAmount ||
    Number(amount) > maxAmount;

  return (
    <DepositView
      handleSelectCurrency={handleSelectCurrency}
      selectedCurrency={selectedCurrency}
      handleSelectNet={handleSelectNet}
      handleCopyClick={handleCopyClick}
      selectedNet={selectedNet}
      currencies={currencies}
      fiatCurrencies={fiatCurrencies}
      copyRef={copyRef}
      minAmount={minAmount}
      maxAmount={maxAmount}
      nets={nets}
      loading={isRequestLoading}
      loaded={isLoaded}
      error={isError}
      errorMessage={errorMessage}
      requestError={requestError}
      requestErrorMessage={requestErrorMessage}
      rangeAmountLoading={rangeAmountLoading}
      depositData={depositData}
      address={address}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      amount={amount}
      setAmount={setAmount}
      selectedFiatCurrency={selectedFiatCurrency}
      handleSelectFiatCurrency={handleSelectFiatCurrency}
      handleSubmit={handleSubmit}
      disabled={isDisabled}
    />
  );
};

export default Deposit;
