import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getData } from "../../utils/store";

import SettingsPopUpView from "./View";

const SettingsPopUp = ({
  isSettingsPopUpOpen,
  setIsSettingsPopUpOpen,
  setOpacitySettings,
}) => {
  const { user } = useSelector(({ user: userData }) => ({
    user: getData(userData.data),
  }));

  const location = useLocation();

  const toggleSettingsPopUp = () => {
    setIsSettingsPopUpOpen(!isSettingsPopUpOpen);
    setOpacitySettings(isSettingsPopUpOpen ? 0 : 1);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isSettingsPopUpOpen &&
        !event.target.classList.contains("gear") &&
        event.target.closest(".settings") === null
      ) {
        setIsSettingsPopUpOpen(false);
        setOpacitySettings(0);
      }
    };

    if (isSettingsPopUpOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isSettingsPopUpOpen, setIsSettingsPopUpOpen, setOpacitySettings]);

  return (
    <SettingsPopUpView
      isSettingsPopUpOpen={isSettingsPopUpOpen}
      toggleSettingsPopUp={toggleSettingsPopUp}
      location={location}
      user={user}
    />
  );
};

export default SettingsPopUp;
