/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

import { NavLink } from "react-router-dom";
import { Text } from "../../utils/Text";
import { ROUTES } from "../../constants/routes";
import { TOP_TYPES } from "../../constants/top-type";

export const HeaderTopView = ({
  currentTopTime,
  handleShowLastYearTop,
  handleShowTodayTop,
  handleShowYesterdayTop,
}) => {
  return (
    <div className="load">
      <div className="load__container">
        <div style={{ width: "100%" }}>
          <div className="load__items">
            <div className="load__item-box">
              <NavLink
                exact
                to={ROUTES.HOME}
                className="load__item game"
                activeClassName="active"
              >
                <Text tid="CLASSIC.CLASSIC_TAB.CLASSIC_GAME" />
              </NavLink>
              <NavLink
                to={ROUTES.CLASSIC_HISTORY}
                className="load__item history"
                activeClassName="active"
              >
                <Text tid="MAIN.MAIN.HISTORY" />
              </NavLink>
              <NavLink
                to={ROUTES.CLASSIC_TOP}
                className="load__item top"
                activeClassName="active"
              >
                <Text tid="MAIN.MAIN.TOP" />
              </NavLink>
            </div>
            <div className="load__items-box">
              <div
                className={
                  currentTopTime === TOP_TYPES.TODAY
                    ? "load__item active"
                    : "load__item"
                }
                onClick={handleShowTodayTop}
              >
                <Text tid="MAIN.MAIN.TODAY" />
              </div>
              <div
                className={
                  currentTopTime === TOP_TYPES.YESTERDAY
                    ? "load__item active"
                    : "load__item"
                }
                onClick={handleShowYesterdayTop}
              >
                <Text tid="MAIN.MAIN.YESTERDAY" />
              </div>
              <div
                className={
                  currentTopTime === TOP_TYPES.LAST_YEAR
                    ? "load__item active"
                    : "load__item"
                }
                onClick={handleShowLastYearTop}
              >
                <Text tid="MAIN.MAIN.LAST_YEAR" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
