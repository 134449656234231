import {
  initRequestActions,
  topClassicBestUsersReducerDefaultData,
} from "../utils/store";

const initialState = topClassicBestUsersReducerDefaultData;

export const BEST_USERS_CLASSIC = initRequestActions("BEST_USERS_CLASSIC");

export const topClassicBestUsers = (state = initialState, action) => {
  switch (action.type) {
    case BEST_USERS_CLASSIC.LOADING:
      return { ...state, isLoading: true, isLoaded: false, data: null };

    case BEST_USERS_CLASSIC.LOADED:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.payload,
      };

    default:
      return state;
  }
};
