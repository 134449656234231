import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { sendRecoveryAccountReset } from "../../actions/recoveryAccountReset";
import { RECOVERY_ACCOUNT_RESET } from "../../constants/fields";
import {
  getErrorMessage,
  isRequestError,
  isRequestPending,
} from "../../utils/store";

import { RecoveryAccountResetView } from "./View";

export const RecoveryAccountReset = () => {
  const { isLoading, isError, errorMessage } = useSelector(
    ({ recoveryAccountReset }) => ({
      isLoading: isRequestPending(recoveryAccountReset.request),
      isError: isRequestError(recoveryAccountReset.request),
      errorMessage: getErrorMessage(recoveryAccountReset.request),
    })
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [fields, setFields] = useState({});

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    await dispatch(
      sendRecoveryAccountReset(
        {
          email: fields[RECOVERY_ACCOUNT_RESET.EMAIL],
        },
        navigate
      )
    );
  };

  const isFormDisabled = !fields[RECOVERY_ACCOUNT_RESET.EMAIL];

  return (
    <RecoveryAccountResetView
      fields={fields}
      changeField={changeField}
      onSubmit={onSubmit}
      isFormDisabled={isFormDisabled}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
    />
  );
};
