import { axios } from "../utils/request";

import { API } from "../constants/api";
import { ROUTES } from "../constants/routes";

export const SIGNUP = {
  LOADING: "SIGNUP.LOADING",
  SUCCESS: "SIGNUP.SUCCESS",
  ERROR: "SIGNUP.ERROR",
};

const setError = (message) => ({
  type: SIGNUP.ERROR,
  message,
});

const setSuccess = (token) => ({
  type: SIGNUP.SUCCESS,
  token,
});

const setLoading = () => ({
  type: SIGNUP.LOADING,
});

export const signupReset = (value) => ({
  type: SIGNUP.RESET,
  reset: value,
});

export const signup = (payload, navigate) => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .post(API.SIGNUP, payload)
      .then(() => {
        dispatch(setSuccess());
        navigate(ROUTES.SIGNUP_CONFIRM({ email: payload.email }));
      })
      .catch(({ response: { data } }) => {
        dispatch(setError(data.message));
      });
  };
};
