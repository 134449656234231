import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

import { useChatTrigger } from "../../hooks/use-chat-trigger";
import { GAME_STATUSES } from "../../constants/games";
import { classicConnect } from "../../actions/game";
import ClassicGameView from "./View";

const ClassicGame = ({
  handleBidPopUpClick,
  isChatOpened,
  setCurrency,
  currency,
}) => {
  const { socket: st } = useSelector(({ socket }) => ({ socket }));

  const { isOpenTrigger } = useChatTrigger({ isChatOpened });

  const { isConnecting, gameData, isFiat, auth, balanceData } = useSelector(
    ({ game, auth: authData, balance }) => ({
      isConnecting: game.connecting,
      balanceData: balance.data,
      isFiat: balance.isFiat,
      gameData: game.data,
      auth: authData,
    })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (gameData?.type && gameData?.type !== "CLASSIC")
      dispatch(classicConnect({ socket: st }));
  }, []);

  useEffect(() => {
    if (balanceData?.length && !currency) {
      setCurrency(balanceData[0]);
    }
  }, [balanceData?.length]);

  if (isConnecting || !gameData?._id) return <ClassicGameView loading />;

  const isGameFailed = gameData.status === GAME_STATUSES.FAILED;

  return (
    <ClassicGameView
      handleBidPopUpClick={handleBidPopUpClick}
      isOpenTrigger={isOpenTrigger}
      isChatOpened={isChatOpened}
      isGameFailed={isGameFailed}
      members={gameData.members}
      setCurrency={setCurrency}
      bets={gameData.bets}
      currency={currency}
      isFiat={isFiat}
      auth={auth}
    />
  );
};

export default ClassicGame;
