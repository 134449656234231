import React, { useState } from "react";

import { currencyCodeParams } from "../../utils/currencyOptions";
import { fix2, fix8 } from "../../utils/fixers";
import "./BetModalCurrency.scss";
import { Text } from "../../utils/Text";

export const BetModalCurrency = ({
  currency,
  setCurrency,
  balance,
  isFiat,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (selected) => {
    if (balance?.length) setOpen((v) => !v);
    if (selected) setCurrency(selected);
  };

  return (
    <div className="betmodalcurr">
      <div
        className={`betmodalcurr__opener ${open ? "opened" : ""}`}
        onClick={() => handleOpen()}
      >
        <div className="betmodalcurr__opener-badge">
          <Text tid="MAIN.MAIN.BALANCE" />
        </div>
        <div className="betmodalcurr__opener-title">
          <img
            src={currencyCodeParams[currency?.code]?.icon}
            className="double__bet__img"
            alt=""
          />
          <span>
            {isFiat ? `${fix2(currency?.sumUsdt)}$` : fix8(currency?.amount)}
          </span>
        </div>
        <div className="betmodalcurr__opener-arrow">
          <svg
            fill={open ? "white" : "#595959"}
            style={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              marginBottom: open ? "-2px" : "0px",
              transition: "0.15s",
            }}
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            color="white"
            height="20"
            width="20"
          >
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
          </svg>
        </div>
      </div>
      {!balance?.length ? null : (
        <div className={`betmodalcurr__modal ${open ? "opened" : ""}`}>
          {balance.map((b) => (
            <div
              className="betmodalcurr__modal__bet"
              onClick={() => handleOpen(b)}
            >
              <div
                className="betmodalcurr__modal__bet-amount"
                style={{ display: "flex", alignItems: "center", gap: 6 }}
              >
                {isFiat ? (
                  <React.Fragment>
                    {fix2(b.sumUsdt)}${" "}
                    <span style={{ fontSize: 12, color: "#666666" }}>
                      ({fix8(b.amount)} {b.code.toUpperCase()})
                    </span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {fix8(b.amount)} {b.code.toUpperCase()}{" "}
                    <span style={{ fontSize: 12, color: "#666666" }}>
                      ({fix2(b.sumUsdt)}$)
                    </span>
                  </React.Fragment>
                )}
              </div>
              <div className="betmodalcurr__modal__bet-name">
                <span>{b.name}</span>
                <img
                  src={currencyCodeParams[b?.code]?.icon}
                  className="double__bet__img"
                  alt=""
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
