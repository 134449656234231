import React from "react";
import "./index.scss";

import LineChance from "../../containers/LineChance/index";
import { UserProfile } from "../UserProfile";
import { fix2 } from "../../utils/fixers";

const Chance = ({ members, auth }) => {
  if (!members?.length) return null;

  return (
    <div className="chance">
      <div className="chance__container">
        <LineChance members={members} />
        <div className="chance__users">
          {members.map((member, i) => (
            <div className="chance__user" key={i}>
              <div
                style={{ border: `2px solid #${member.colorMain}` }}
                className={`chance__user-avatar ${
                  member._id === auth?.user?.id ? "you" : ""
                }`}
              >
                <UserProfile user={member}>
                  <img src={member?.photo} alt={member.name} />
                </UserProfile>
              </div>
              <div className="chance__user-info">
                <div
                  style={{ color: `#${member.colorMain}` }}
                  className="chance__user-info-amount"
                >
                  {fix2(member.totalBank)}$
                </div>
                <div className="chance__user-info-chance">{member.chance}%</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Chance;
