import React from "react";
import { PulseLoader } from "react-spinners";
import "./index.scss";

const LoaderPulse = ({ _color }) => {
  return (
    <PulseLoader
      color={_color || "#525252"}
      className="pulse-loader"
      speedMultiplier={1.0}
      size={5}
      loading
    />
  );
};

export default LoaderPulse;
