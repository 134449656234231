import React from "react";
import { useSelector } from "react-redux";
import BetView from "./View";

const Bet = ({ handleBidPopUpClick, isOpenTrigger, handleHashOpen }) => {
  const { isConnecting, gameData, auth } = useSelector(
    ({ game, auth: authData }) => ({
      isConnecting: game.connecting,
      gameData: game.data,
      auth: authData,
    })
  );

  if (isConnecting) return null;

  const user = gameData.members.find((member) => member._id === auth?.user?.id);

  return (
    <BetView
      handleBidPopUpClick={handleBidPopUpClick}
      handleHashOpen={handleHashOpen}
      roundHash={gameData.roundHash}
      isOpenTrigger={isOpenTrigger}
      user={user}
      auth={auth}
    />
  );
};

export default Bet;
