/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

import rg from "../../assets/img/rg.jpg";
import wallet from "../../assets/img/Wallet.svg";
import arrow from "../../assets/img/CaretLeft.svg";

import { Text } from "../../utils/Text";
import { ROUTES } from "../../constants/routes";
import CoinPopUP from "../CoinPopUP";
import { fix2, fix8 } from "../../utils/fixers";
import { currencyCodeParams } from "../../utils/currencyOptions";

const UserView = ({
  user,
  balances,
  selectedBalance,
  balanceIsFiat,
  handleWalletClick,
  handleWalletSettingsClick,
  handleToggleCoinPopUP,
  isCoinPopUPOpen,
  setIsCoinPopUPOpen,
  setOpacityCoinPopUP,
  setIsActive,
}) => {
  const usdt = fix2(selectedBalance?.sumUsdt || 0);

  const amount =
    selectedBalance.code === "usd" ? usdt : fix8(selectedBalance?.amount || 0);

  return (
    <div className="user">
      <div className="user__container">
        <Link to={ROUTES.SETTINGS_GENERAL} onClick={() => setIsActive(false)}>
          <div className="user__accaunt">
            <div className="user__avatar">
              <img
                src={user?.photo}
                alt="user`s avatar"
                className="user__avatar-img"
              />
            </div>
            <div className="user__name">
              <p className="user__name-text">{user?.nickname}</p>
            </div>
          </div>
        </Link>
        <div className="user__wallet">
          <div className="user__balance" onClick={handleToggleCoinPopUP}>
            <p
              className="user__balance-text"
              style={{ display: "flex", gap: 4, marginLeft: -2 }}
            >
              <Text tid="MAIN.MAIN.BALANCE" />
              <img
                src={arrow}
                style={{ width: 12, marginRight: 6, marginTop: -2 }}
                alt=""
              />
            </p>
            <div className="user__balance-amount">
              <div className="user__balance-amount-box coin">
                <p className="user__balance-value">
                  {balanceIsFiat ? `${usdt}$` : amount}
                </p>
                <img
                  className="user-coins__custom-option__icon"
                  src={currencyCodeParams[selectedBalance?.code]?.icon}
                  // style={{ height: 18, marginTop: -6 }}
                  style={{ maxWidth: "16px" }}
                  alt={selectedBalance?.name}
                />
              </div>
            </div>
          </div>
          <CoinPopUP
            balances={balances}
            setOpacityCoinPopUP={setOpacityCoinPopUP}
            isCoinPopUPOpen={isCoinPopUPOpen}
            setIsCoinPopUPOpen={setIsCoinPopUPOpen}
            handleWalletSettingsClick={handleWalletSettingsClick}
          />
          <div className="user__balance-icon" onClick={handleWalletClick}>
            <img src={wallet} alt="user`s avatar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserView;
