import React, { useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import iconcard1 from "../../assets/img/cardicon (1).svg";
import iconcard2 from "../../assets/img/cardicon (2).svg";
import iconcard3 from "../../assets/img/cardicon (3).svg";
import iconcard4 from "../../assets/img/cardicon (4).svg";
import iconcard5 from "../../assets/img/cardicon (5).svg";
import iconcard6 from "../../assets/img/cardicon (6).svg";
import iconcard7 from "../../assets/img/cardicon (7).svg";
import iconcard8 from "../../assets/img/cardicon (8).svg";
import iconcard9 from "../../assets/img/cardicon (9).svg";
import iconcard10 from "../../assets/img/cardicon (10).svg";
import iconcard11 from "../../assets/img/cardicon (11).svg";
import { getData } from "../../utils/store";
import { ROUTES } from "../../constants/routes";

const icons = [
  iconcard1,
  iconcard2,
  iconcard3,
  iconcard4,
  iconcard5,
  iconcard6,
  iconcard7,
  iconcard8,
  iconcard9,
  iconcard10,
  iconcard11,
];

const Cards = () => {
  const [cards, setCards] = useState([]);
  const [flippedCards, setFlippedCards] = useState([]);

  const navigate = useNavigate();

  const { user } = useSelector(({ user: userData }) => ({
    user: getData(userData.data),
  }));

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  useEffect(() => {
    const initialCards = Array.from(
      { length: 110 },
      () => Math.floor(Math.random() * 11) + 1
    );
    setCards(shuffleArray(initialCards));
  }, []);

  const flipCard = (index) => {
    setFlippedCards([...flippedCards, index]);
  };

  return (
    <div className="Cards">
      <div className="Cards__container">
        {cards.map((card, index) => (
          <div
            className={`Cards__card ${flippedCards.includes(index) ? "flipped no-hover" : ""}`}
            key={index}
            onClick={() => {
              if (!user?.id) {
                navigate(ROUTES.LOGIN);
              } else {
                flipCard(index);
              }
            }}
          >
            <div className="Cards__card__inner">
              <div className="Cards__card__front">
                <img
                  src={icons[card - 1]}
                  alt={`Card ${card}`}
                  className="Cards__card__img"
                />
              </div>
              <div className="Cards__card__back">
                <img
                  src={user?.photo}
                  alt={`User ${user?.name}`}
                  className="Cards__card__img"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cards;
