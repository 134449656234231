import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useChatTrigger } from "../../hooks/use-chat-trigger";
import HeaderCrash from "../../containers/HeaderCrash/index";
import sandclock from "../../assets/img/hourglass.png";
import { doubleConnect } from "../../actions/game";
import BetCrashGame from "../BetCrashGame";
import Loader from "../Loader";
import Crash from "../Crash";
import "./index.scss";

const CrashGame = ({ setCurrency, currency, isChatOpened }) => {
  const { socket: st } = useSelector(({ socket }) => ({ socket }));
  const game = useSelector(({ game: gameData }) => gameData);
  const { isOpenTrigger } = useChatTrigger({ isChatOpened });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(doubleConnect({ socket: st }));
  }, [game?.data?.type]);

  const isConnecting =
    game?.data?.type === "CRASH" || game.connecting || !game?.data?._id;

  return (
    <div className="CrashGame">
      <div className="comming-soon">
        <img src={sandclock} alt="sandclock" />
        Coming soon...
      </div>
      <HeaderCrash />
      <Routes>
        <Route
          path="/"
          element={
            !isConnecting ? (
              <>
                <div
                  className={`CrashGame__box ${
                    isOpenTrigger ? "CrashGame__box__opened" : ""
                  }`}
                >
                  <BetCrashGame setCurrency={setCurrency} currency={currency} />
                  <Crash isChatOpened={isChatOpened} />
                </div>
              </>
            ) : (
              <div className="to-connecting-block" style={{ marginTop: 45 }}>
                <Loader />
              </div>
            )
          }
        />
      </Routes>
    </div>
  );
};

export default CrashGame;
