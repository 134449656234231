import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import BurgerPopUpView from "./View";

const BurgerPopUp = ({
  handleWalletClick,
  setOpacity,
  handleWalletSettingsClick,
  isActive,
  setIsActive,
}) => {
  const { isLogged } = useSelector(({ auth }) => ({
    isLogged: auth.logged,
  }));

  const burgerStyles = {
    transform: isActive ? "translateX(0)" : "",
    zIndex: isActive ? "2" : "",
  };

  const handelSettingsClick = () => {
    setIsActive((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isActive &&
        !event.target.classList.contains("burger") &&
        event.target.closest(".header12") === null
      ) {
        setIsActive(false);
      }
    };

    const handleWindowResize = () => {
      if (window.innerWidth > 1143 && isActive) {
        setIsActive(false);
      }
    };

    if (isActive) {
      document.addEventListener("click", handleClickOutside);
      window.addEventListener("resize", handleWindowResize);
    } else {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleWindowResize);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isActive, setIsActive]);

  const [voiceOn, setVoiceOn] = useState(true);

  const handleVoiceClick = () => {
    setVoiceOn(!voiceOn);
  };

  const [activeGame, setActiveGame] = useState("");
  const handleGameClick = (game) => {
    setActiveGame(game);
  };

  return (
    <BurgerPopUpView
      handleVoiceClick={handleVoiceClick}
      voiceOn={voiceOn}
      activeGame={activeGame}
      handleGameClick={handleGameClick}
      burgerStyles={burgerStyles}
      isLogged={isLogged}
      handelSettingsClick={handelSettingsClick}
      handleWalletClick={handleWalletClick}
      setOpacity={setOpacity}
      handleWalletSettingsClick={handleWalletSettingsClick}
      setIsActive={setIsActive}
      isActive={isActive}
    />
  );
};

export default BurgerPopUp;
