import React from "react";

import { fix2 } from "../../utils/fixers";
import { Text } from "../../utils/Text";
import "./index.scss";

const Join = ({ gameData }) => {
  return (
    <div
      className="join"
      style={{ position: "relative", transition: "0.3s", height: "68px" }}
    >
      <div
        className="join__container"
        style={{ position: "absolute", height: 36 }}
      >
        <p className="join__main-text">
          <Text tid="CLASSIC.CLASSIC_TAB.JOIN_NOW" />:
        </p>
        <p className="join__value">{fix2(gameData?.totalBank)}$</p>
      </div>
    </div>
  );
};

export default Join;
