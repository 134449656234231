import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AUTH_USER } from "../../constants/fields";
import {
  getErrorMessage,
  isRequestError,
  isRequestPending,
} from "../../utils/store";
import { useQuery } from "../../utils/index";
import { confirmCode } from "../../actions/signupConfirmCode";

import { SignupConfirmCodeView } from "./View";
import { ROUTES } from "../../constants/routes";

export const SignupConfirmCode = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isLoading, isError, errorMessage } = useSelector(
    ({ signupConfirmCode }) => ({
      isLoading: isRequestPending(signupConfirmCode.request),
      isError: isRequestError(signupConfirmCode.request),
      errorMessage: getErrorMessage(signupConfirmCode.request),
    })
  );

  const email = query.get("email");

  useEffect(() => {
    if (!email) {
      navigate(ROUTES.LOGIN);
    }
  }, []);

  const [fields, setFields] = useState({});

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const submit = async (e) => {
    e.preventDefault();

    dispatch(
      confirmCode(
        {
          email,
          code: fields[AUTH_USER.CONFIRM_CODE],
        },
        navigate
      )
    );
  };

  const isFormDisabled = !fields[AUTH_USER.CONFIRM_CODE];

  const user = { email };

  return (
    <SignupConfirmCodeView
      fields={fields}
      changeField={changeField}
      onSubmit={submit}
      isFormDisabled={isFormDisabled}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      user={user}
    />
  );
};
