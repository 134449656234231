import { SOCK_REQUESTS } from "../constants/socket";

export const CHAT = {
  CONNECTING: "CHAT.CONNECTING",
  CONNECTED: "CHAT.CONNECTED",
  UPDATING: "CHAT.UPDATING",
  UPDATED: "CHAT.UPDATED",
};

const setConnecting = () => ({ type: CHAT.CONNECTING });
const setConnected = (payload) => ({ type: CHAT.CONNECTED, payload });
const setUpdated = (payload) => ({ type: CHAT.UPDATED, payload });

export const chatConnect = ({ socket }) => {
  return (dispatch) => {
    dispatch(setConnecting());
    socket.emit(SOCK_REQUESTS.CHAT_CONNECT);
  };
};

export const chatConnected = (chat) => {
  return (dispatch) => {
    dispatch(setConnected({ ...chat, messages: chat.messages.reverse() }));
  };
};

export const chatSendMessage = ({ message }) => {
  return (dispatch, getState) => {
    const { socket } = getState();

    socket.emit(SOCK_REQUESTS.CHAT_SEND_MESSAGE, {
      message,
    });
  };
};

export const chatUpdate = (message) => {
  return (dispatch, getState) => {
    const data = getState().chat.data || [];

    dispatch(
      setUpdated({ ...data, messages: [...(data?.messages || []), message] })
    );
  };
};
