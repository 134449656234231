import React from "react";
import "./index.scss";
import { NavLink } from "react-router-dom";
import { Text } from "../../utils/Text";
import { ROUTES } from "../../constants/routes";

const HeaderDoubleView = ({
  activeLink,
  handleLinkClick,
  handleMouseEnter,
  handleMouseLeave,
  opacity,
  showPopup,
}) => {
  return (
    <div className="load">
      <div className="load__container">
        <div style={{ width: "100%" }}>
          <div className="load__items">
            <div className="load__item-box">
              <NavLink
                exact
                to={ROUTES.DOUBLE}
                className={`load__item game double ${
                  activeLink === "/" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("/")}
              >
                <Text tid="DOUBLE.DOUBLE_TAB.DOUBLE_GAME" />
              </NavLink>
            </div>
            <div
              className="load__item help"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Text tid="MAIN.MAIN.HOW_TO_PLAY" />
            </div>
            {
              <>
                <div
                  className="popup"
                  style={{
                    opacity: opacity,
                    visibility: showPopup ? "visible" : "hidden",
                  }}
                >
                  <p>
                    <Text tid="MAIN.MAIN.HOW_TO_PLAY_TEXT" />:
                  </p>
                  <ul style={{ fontSize: 12 }}>
                    <li>
                      <Text tid="MAIN.MAIN.DOUBLE_STEP_1" />
                    </li>
                    <li>
                      <Text tid="MAIN.MAIN.DOUBLE_STEP_2" />
                    </li>
                    <li>
                      <Text tid="MAIN.MAIN.DOUBLE_STEP_3" />
                    </li>
                  </ul>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderDoubleView;
