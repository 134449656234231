/* eslint-disable no-plusplus */
import {
  GAME_RULET_TYPES,
  GAME_STATUSES,
  GAME_TYPES,
} from "../constants/games";
import { installUserInfoInGame } from "../utils/UserData";
import { audioPlay, registerAudio } from "../utils/media";
import { SOCK_REQUESTS } from "../constants/socket";
import { AUDIO } from "../constants/audio";

export const GAME_CLASSIC = {
  CONNECTING: "GAME_CLASSIC.CONNECTING",
  CONNECTED: "GAME_CLASSIC.CONNECTED",
  UPDATING: "GAME_CLASSIC.UPDATING",
  UPDATED: "GAME_CLASSIC.UPDATED",
};

const setConnecting = () => ({ type: GAME_CLASSIC.CONNECTING });
const setConnected = (payload) => ({ type: GAME_CLASSIC.CONNECTED, payload });
const setUpdated = (payload) => ({ type: GAME_CLASSIC.UPDATED, payload });

const makeBet = ({
  members: updatedMembers,
  bets: updatedBets,
  gameTotalBank,
}) => {
  return (dispatch, getState) => {
    const game = getState().game.data;

    const members = [...(game.members || [])];
    const bets = [...(game.bets || [])];

    for (let i = 0; i < updatedMembers.length; i++) {
      const mem = updatedMembers[i];
      let isExist = false;

      for (let j = 0; j < members.length; j++) {
        if (members[j]._id === mem._id) {
          members[j] = { ...members[j], ...mem };
          isExist = true;
        }
      }

      if (!isExist) members.push(mem);
    }

    for (let i = 0; i < updatedBets.length; i++) {
      const bet = updatedBets[i];
      let isExist = false;

      for (let j = 0; j < bets.length; j++) {
        if (bets[j]._id === bet._id) {
          bets[j] = { ...bets[j], ...bet };
          isExist = true;
        }
      }

      if (!isExist) bets.push(bet);
    }

    const data = installUserInfoInGame({
      ...game,
      totalBank: gameTotalBank,
      members,
      bets,
    });

    dispatch(setUpdated(data));

    const audio = registerAudio(AUDIO.BET_MAKE);
    audioPlay(audio);
  };
};

export const classicConnected = (game) => {
  return (dispatch) => {
    if (window.location.pathname.includes("double")) return null;
    if (game.winner) {
      const gameWithAdditionalInfo = installUserInfoInGame(game);

      const winner = gameWithAdditionalInfo.members.find((member) => {
        return String(member._id) === String(game?.winner?._id || game?.winner);
      });

      const data = { ...gameWithAdditionalInfo, winner };
      dispatch(setConnected(data));
    } else {
      dispatch(setConnected(installUserInfoInGame(game)));
    }
  };
};

export const doubleConnected = (game) => {
  return (dispatch) => {
    if (window.location.pathname.includes("classic")) return null;
    dispatch(setConnected(installUserInfoInGame(game)));
  };
};

export const classicConnect = () => {
  return (dispatch, getState) => {
    dispatch(setConnecting());
    getState()?.socket?.emit?.(SOCK_REQUESTS.CONNECT_CLASSIC_ROOM);
  };
};

export const gameReconnect = ({ type, subtype }) => {
  return async (dispatch, getState) => {
    const { socket } = getState();
    const gameType = getState()?.game?.data?.type;
    if (gameType !== type) return null;

    if (gameType === GAME_TYPES.CLASSIC)
      socket?.emit?.(SOCK_REQUESTS.CONNECT_CLASSIC_ROOM, { type, subtype });

    if (gameType === GAME_TYPES.DOUBLE)
      socket?.emit?.(SOCK_REQUESTS.CONNECT_DOUBLE_ROOM, { type, subtype });
  };
};

export const doubleConnect = () => {
  return (dispatch, getState) => {
    dispatch(setConnecting());
    getState()?.socket?.emit?.(SOCK_REQUESTS.CONNECT_DOUBLE_ROOM);
  };
};

export const gameUpdateTimer = ({ timer }) => {
  return (dispatch, getState) => {
    const game = getState().game.data;
    dispatch(setUpdated({ ...game, timer }));
  };
};

export const gameUpdateStatus = ({ status, finishData = {} }) => {
  return (dispatch, getState) => {
    const game = getState().game.data;

    if (
      game.status === GAME_STATUSES.RECRUITED &&
      status === GAME_STATUSES.SPIN
    ) {
      const audio = registerAudio(AUDIO.R_START);
      audioPlay(audio);
    }

    if (game.status === GAME_STATUSES.SPIN && status === GAME_STATUSES.PAUSED) {
      const audio = registerAudio(AUDIO.R_LOSE);
      audioPlay(audio);
    }

    if (game.type === GAME_TYPES.CLASSIC) {
      if (!finishData?.winner) return dispatch(setUpdated({ ...game, status }));

      const winner = game.members.find((member) => {
        return String(member._id) === String(finishData.winner._id);
      });

      return dispatch(setUpdated({ ...game, status, ...finishData, winner }));
    }

    if (isNaN(Number(finishData?.roundColorNumber)))
      return dispatch(setUpdated({ ...game, status }));

    dispatch(setUpdated({ ...game, status, ...finishData }));
  };
};

export const classicMakeBetRequest = ({
  sumToken,
  balance,
  gameId,
  isFiat,
}) => {
  return (dispatch, getState) => {
    const { socket } = getState();
    socket.emit(SOCK_REQUESTS.MAKE_BET_CLASSIC_ROOM, {
      gameRuletType: GAME_RULET_TYPES.DEFAULT,
      sumToken: Number(sumToken || 0),
      gameType: GAME_TYPES.CLASSIC,
      balance,
      gameId,
      isFiat,
    });
  };
};

export const doubleMakeBetRequest = ({
  sumToken,
  balance,
  color,
  gameId,
  isFiat,
}) => {
  return (dispatch, getState) => {
    const { socket } = getState();
    socket.emit(SOCK_REQUESTS.MAKE_BET_DOUBLE_ROOM, {
      gameRuletType: GAME_RULET_TYPES.DEFAULT,
      sumToken: Number(sumToken || 0),
      gameType: GAME_TYPES.DOUBLE,
      balance,
      gameId,
      color,
      isFiat,
    });
  };
};

export const classicMakeBetResponse = (v) => makeBet(v);
export const doubleMakeBetResponse = (v) => makeBet(v);
