import React from "react";

import "./index.scss";

import wallet from "../../assets/img/WalletB.svg";
import x from "../../assets/img/close.svg";

import { Text } from "../../utils/Text";

const WalletView = ({
  opacity,
  zIndex,
  handleBackgroundClick,
  handlePopupClose,
  setActiveTab,
  renderTabContent,
  activeTab,
}) => {
  return (
    <div
      className="bg"
      style={{ opacity, zIndex }}
      onClick={handleBackgroundClick}
      // onKeyDown={(event) => {
      //   if (event.key === "Enter" || event.key === " ") {
      //     handleBackgroundClick(event);
      //   }
      // }}
      role="button"
      tabIndex={0}
    >
      <div className="wallet" style={{ opacity, zIndex }}>
        <div
          className="wallet__container"
          style={
            activeTab === "deposit"
              ? { overflowY: "auto", overflowX: "hidden" }
              : { height: "max-content" }
          }
        >
          <div className="wallet__header">
            <div className="wallet__header-icon">
              <img src={wallet} alt="" />
              <p className="wallet__header-text">
                <Text tid="MAIN.MAIN.WALLET" />
              </p>
            </div>
            <div
              className="wallet__header-close"
              onClick={handlePopupClose}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  handlePopupClose();
                }
              }}
              role="button"
              tabIndex={0}
            >
              <img src={x} alt="" />
            </div>
          </div>
          <hr className="wallet__header-line" />
          <div className="wallet__tabs">
            <div
              className={`wallet__tab ${
                activeTab === "deposit" ? "wallet__tab-active" : ""
              }`}
              onClick={() => setActiveTab("deposit")}
            >
              <p className="wallet__tab-text">
                <Text tid="WALLET.WALLET_POPUP.DEPOSIT" />
              </p>
            </div>
            <div
              className={`wallet__tab ${
                activeTab === "withdraw" ? "wallet__tab-active" : ""
              }`}
              onClick={() => setActiveTab("withdraw")}
            >
              <p className="wallet__tab-text">
                <Text tid="WALLET.WALLET_POPUP.WITHDRAW" />
              </p>
            </div>

            {/* <div className="wallet__tab">
              <p className="wallet__tab-text">Buy Crypto</p>
            </div> */}
          </div>
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default WalletView;
