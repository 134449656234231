import React from "react";
import PrimaryButton from "../PrimaryButton";
import "./index.scss";
import { Text } from "../../utils/Text";

const PrizeAmount = ({ prizeAmount, setShowPopup, showPopup }) => {
  return (
    <div
      className="PrizeAmount"
      style={{
        opacity: showPopup ? 1 : 0,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        zIndex: 99999,
      }}
    >
      <div
        className="PrizeAmount__text"
        style={{
          fontSize: "10rem",
          fontWeight: "bold",
          color: "white",
          textAlign: "center",
          marginBottom: "2rem",
        }}
      >
        {prizeAmount}
      </div>
      <div
        className="PrizeAmount__congratulations"
        style={{
          fontSize: "4rem",
          fontWeight: "bold",
          color: "white",
          textAlign: "center",
          paddingBottom: "2rem",
        }}
      >
        <Text tid="AIRDROP.AIRDROP.CONGRATULATION" />!
      </div>
      <PrimaryButton className="PrizeAmount__button">
        <span
          onClick={() => setShowPopup(false)}
          style={{
            position: "relative",
            zIndex: 1,
            width: "205px",
            padding: "10px 30px",
          }}
        >
          <Text tid="AIRDROP.AIRDROP.CLAIM_THE_PRIZE" />
        </span>
      </PrimaryButton>
    </div>
  );
};

export default PrizeAmount;
