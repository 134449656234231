import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { sendRecoveryAccountConfirm } from "../../actions/recoveryAccountConfirm";
import { RECOVERY_ACCOUNT_CONFIRM } from "../../constants/fields";
import {
  getErrorMessage,
  isRequestError,
  isRequestPending,
  getData,
} from "../../utils/store";

import { RecoveryAccountConfirmView } from "./View";
import { ROUTES } from "../../constants/routes";
import { useTranslation } from "react-i18next";

export const RecoveryAccountConfirm = () => {
  const { isLoading, isError, errorMessage, resetData } = useSelector(
    ({ recoveryAccountConfirm, recoveryAccountReset }) => ({
      isLoading: isRequestPending(recoveryAccountConfirm.request),
      isError: isRequestError(recoveryAccountConfirm.request),
      errorMessage: getErrorMessage(recoveryAccountConfirm.request),
      resetData: getData(recoveryAccountReset.request),
    })
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [fields, setFields] = useState({});

  useEffect(() => {
    if (!resetData?.[RECOVERY_ACCOUNT_CONFIRM.EMAIL]) {
      navigate(ROUTES.RECOVERY_ACCOUNT_RESET);
    }
  }, []);

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    await dispatch(
      sendRecoveryAccountConfirm(
        {
          email: resetData[RECOVERY_ACCOUNT_CONFIRM.EMAIL],
          code: fields[RECOVERY_ACCOUNT_CONFIRM.CODE],
        },
        navigate
      )
    );
  };

  const isFormDisabled = !fields[RECOVERY_ACCOUNT_CONFIRM.CODE];

  const { t } = useTranslation();

  return (
    <RecoveryAccountConfirmView
      fields={fields}
      changeField={changeField}
      onSubmit={onSubmit}
      isFormDisabled={isFormDisabled}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      resetData={resetData}
      t={t}
    />
  );
};
