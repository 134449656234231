import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SettingsUpdatePhotoView } from "./View";

import {
  resetUpdatePhoto,
  updatePhoto,
} from "../../actions/settingsUpdatePhoto";
import { SETTINGS } from "../../constants/fields";
import {
  getData,
  getErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../utils/store";
import { getUser } from "../../actions/user";

export const SettingsUpdatePhoto = () => {
  const { isLoading, isSuccess, isError, errorMessage, user } = useSelector(
    ({ settingsUpdatePhoto, user: userData }) => ({
      isLoading: isRequestPending(settingsUpdatePhoto.request),
      isSuccess: isRequestSuccess(settingsUpdatePhoto.request),
      isError: isRequestError(settingsUpdatePhoto.request),
      errorMessage: getErrorMessage(settingsUpdatePhoto.request),
      user: getData(userData.data),
    })
  );

  const dispatch = useDispatch();

  const [photo, setPhoto] = useState({
    preview: user.photo,
    data: "",
  });

  useEffect(() => {
    return () => {
      dispatch(resetUpdatePhoto());
    };
  }, []);

  const changePhoto = async (e) => {
    try {
      const img = {
        preview: URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
      };

      setPhoto(img);
    } catch {}
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const base64Photo = await convertBase64(photo.data);

    const { success } = await dispatch(
      updatePhoto({
        [SETTINGS.PHOTO]: base64Photo,
      })
    );

    if (success) {
      dispatch(getUser());
      setPhoto({
        ...photo,
        data: "",
      });
    }
  };

  const isFormDisabled = !photo.data;

  return (
    <SettingsUpdatePhotoView
      photo={photo}
      changePhoto={changePhoto}
      disabled={isFormDisabled}
      onSubmit={onSubmit}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      success={isSuccess}
    />
  );
};
