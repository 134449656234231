import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

import { Text } from "../../utils/Text";
import { ROUTES } from "../../constants/routes";
import { HELP } from "../../constants/help";

const QuestionsPopUpView = ({
  isSettingsPopUpOpen,
  toggleSettingsPopUp,
  location,
  isMob,
}) => {
  return (
    <div
      className={`settings settings-${isMob ? "mob" : "pc"}`}
      style={{
        opacity: isSettingsPopUpOpen ? 1 : 0,
        visibility: isSettingsPopUpOpen ? "visible" : "hidden",
      }}
    >
      <div className="settings__container">
        <div className="settings__header">
          <div className="settings__header-icon">
            <div className="help" />
            <p className="settings__header-text" style={{ marginLeft: -12 }}>
              <Text tid="SETTINGS.SETTINGS_TAB.HELP" />
            </p>
          </div>
        </div>
        <hr className="settings__header-line" />
        <div className="settings__list">
          <Link
            to={ROUTES.POLICY}
            className={`settings__list-item ${
              location.pathname === "/settings/general" ? "active" : ""
            }`}
            onClick={toggleSettingsPopUp}
          >
            <p className="settings__list-item-text">
              <Text tid="AUTH.AUTH.POLICY" />
            </p>
          </Link>
          <Link
            to={ROUTES.TERMS_OF_USE}
            className={`settings__list-item ${
              location.pathname === "/settings/general" ? "active" : ""
            }`}
            onClick={toggleSettingsPopUp}
          >
            <p className="settings__list-item-text">
              <Text tid="AUTH.AUTH.TERMS_OF_USE" />
            </p>
          </Link>
          <Link
            to={ROUTES.ABOUT}
            className={`settings__list-item ${
              location.pathname === "/settings/general" ? "active" : ""
            }`}
            onClick={toggleSettingsPopUp}
          >
            <p className="settings__list-item-text">
              <Text tid="AUTH.AUTH.FAQ" />
            </p>
          </Link>
        </div>
        <hr
          className="settings__header-line"
          style={{ opacity: 0.35, height: 1 }}
        />
        <div className="settings__header">
          <a href={`mailto:${HELP.SUPPORT}`}>
            <div className="settings__header-icon">
              <div />
              <p
                className="settings__header-text"
                style={{ marginLeft: -12, color: "rgb(96 145 255)" }}
              >
                {HELP.SUPPORT}
              </p>
            </div>
          </a>

          <a href={`mailto:${HELP.OFFERS}`}>
            <div className="settings__header-icon">
              <div />
              <p
                className="settings__header-text"
                style={{ marginLeft: -12, color: "rgb(96 145 255)" }}
              >
                {HELP.OFFERS}
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default QuestionsPopUpView;
