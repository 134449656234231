import defaultAxios from "axios";
import jwtDecode from "jwt-decode";

import { getBrowserCookie, setCookie } from "./cookie";
import { COOKIE_AUTH } from "../constants";
import { logOut } from "../actions/login";

const transformResponse = (data, headers) => {
  if (headers["content-type"] === "text/plain; charset=UTF-8") return data;
  if (!data) {
    return null;
  }
  data = JSON.parse(data);
  return data;
};

export const axios = defaultAxios.create({
  timeout: 100000,
  transformResponse: [transformResponse],
});

export const authDecode = (raw) => jwtDecode(raw);

export const AUTH_HEADER = "Authorization";
export const setAuthorizationHeader = (tokenHash) => {
  const token = `Bearer ${tokenHash}`;

  axios.defaults.headers.common[AUTH_HEADER] = token;
};

export const setAuthorization = (token) => {
  setAuthorizationHeader(token);
  setCookie(COOKIE_AUTH, token);
};

const onResponseSuccess = (response) => {
  return response;
};

const onResponseError = (error) => {
  if (!error.response) {
    error.response = { data: { message: "" } };
  }
  if (error.response) {
    // if (error.response.status === 403) {
    //   return redirect(ROUTES.ACCESS_DENIED);
    // }
    if (error.response.status === 401) {
      logOut();
      // return redirect(ROUTES.ACCESS_DENIED);
    }
    // if (error.response.status === 500) {
    //   return redirect(ROUTES.SERVER_ERROR);
    // }
    // if (error.response.status === 404) {
    //   return redirect(ROUTES.NOT_FOUND);
    // }
  }

  return Promise.reject(error);
};

const authToken = getBrowserCookie(COOKIE_AUTH);
if (authToken) {
  setAuthorizationHeader(authToken);
}

axios.interceptors.response.use(onResponseSuccess, onResponseError);
