/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// import flagen from "../../assets/img/united-kingdom.svg";
// import flaguk from "../../assets/img/flag-ukraine.svg";

import { i18n } from "../../utils/i18n";
import HeaderView from "./View";
import { audioSetOff, audioSetOn } from "../../utils/media";

export const languages = [
  { value: "en", label: "English" },
  { value: "ru", label: "Russian" },
  { value: "ua", label: "Ukrainian" },
  { value: "es", label: "Spanish" },
  { value: "tr", label: "Turkish" },
  { value: "ind", label: "Indian" },
];

const Header = ({
  handleWalletClick,
  setOpacity,
  handleWalletSettingsClick,
  isActive,
  setIsActive,
}) => {
  const { isLogged } = useSelector(({ auth }) => ({
    isLogged: auth.logged,
  }));

  const [isQuestionsPopUpOpen, setIsQuestionsPopUpOpen] = useState(false);
  const [isSettingsPopUpOpen, setIsSettingsPopUpOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [isQuestionsOpen, setIsQuestionsOpen] = useState(false);
  const [opacityQuestions, setOpacityQuestions] = useState(0);
  const [opacitySettings, setOpacitySettings] = useState(0);

  useEffect(() => {
    const lang = languages.find((item) => item.value === i18n.language);

    if (lang) setSelectedLanguage(lang);
  }, []);

  const handleBurgerClick = (e) => {
    e.stopPropagation();
    setIsActive((prevState) => !prevState);
  };

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    i18n.changeLanguage(selectedOption.value);
  };

  const handleToggleSettingsPopUp = (e) => {
    e.preventDefault();
    setIsSettingsPopUpOpen((prevState) => !prevState);
    setOpacitySettings((prevState) => (prevState === 0 ? 1 : 0));
  };

  const handleQuestiongOpen = (e) => {
    e.preventDefault();
    setIsQuestionsOpen((prevState) => !prevState);
    setOpacityQuestions((prevState) => (prevState === 0 ? 1 : 0));
  };

  const [voiceOn, setVoiceOn] = useState(true);

  const handleVoiceClick = () => {
    const newValue = !voiceOn;

    if (newValue) audioSetOn();
    else audioSetOff();

    setVoiceOn(newValue);
  };

  useEffect(() => {
    setVoiceOn(!JSON.parse(localStorage.getItem("muted") || "false"));
  }, []);

  return (
    <HeaderView
      handleWalletClick={handleWalletClick}
      setOpacity={setOpacity}
      handleWalletSettingsClick={handleWalletSettingsClick}
      isActive={isActive}
      setIsActive={setIsActive}
      handleBurgerClick={handleBurgerClick}
      handleLanguageChange={handleLanguageChange}
      languages={languages}
      selectedLanguage={selectedLanguage}
      handleToggleSettingsPopUp={handleToggleSettingsPopUp}
      isSettingsPopUpOpen={isSettingsPopUpOpen}
      opacitySettings={opacitySettings}
      handleVoiceClick={handleVoiceClick}
      voiceOn={voiceOn}
      isLogged={isLogged}
      handleQuestiongOpen={handleQuestiongOpen}
      isQuestionsPopUpOpen={isQuestionsPopUpOpen}
      setIsQuestionsPopUpOpen={setIsQuestionsPopUpOpen}
      isQuestionsOpen={isQuestionsOpen}
      opacityQuestions={opacityQuestions}
      setIsSettingsPopUpOpen={setIsSettingsPopUpOpen}
      setOpacityQuestions={setOpacityQuestions}
      setOpacitySettings={setOpacitySettings}
    />
  );
};

export default Header;
