import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import QRCode from "qrcode.react";

import CurrencySelect from "../CurrencySelect";
import NetworkSelect from "../NetworkSelect";
import copy from "../../assets/img/Copy.svg";
import { Text } from "../../utils/Text";
import LoaderPulse from "../../components/LoaderPulse";
import { currencyCodeParams as codeParams } from "../../utils/currencyOptions";
import PrimaryButton from "../../components/PrimaryButton";
import { AlertMessage } from "../../components/AlertMessage";

const DepositView = ({
  handleSelectCurrency,
  selectedCurrency,
  handleSelectNet,
  rangeAmountLoading,
  minAmount,
  maxAmount,
  selectedNet,
  currencies,
  fiatCurrencies,
  copyRef,
  nets,
  handleSubmit,
  handleCopyClick,
  depositData,
  loaded,
  address,
  activeTab,
  setActiveTab,
  amount,
  setAmount,
  selectedFiatCurrency,
  handleSelectFiatCurrency,
  disabled,
  loading,
  errorMessage,
  error,
  requestError,
  requestErrorMessage,
}) => {
  const { t } = useTranslation();

  const minError = Number(amount) < Number(minAmount) && amount !== "";
  const maxError = Number(amount) > Number(maxAmount) && amount !== "";

  return (
    <form onSubmit={handleSubmit}>
      <div className="wallet__box">
        {loaded && (
          <React.Fragment>
            <hr className="walletSettings__header-line" />
            <div className="wallet__tabs small">
              <div
                className={`wallet__tab ${
                  activeTab === "crypto" ? "wallet__tab-active" : ""
                }`}
                onClick={() => setActiveTab("crypto")}
              >
                <p className="wallet__tab-text">
                  <Text tid="WALLET.WALLET_POPUP.CRYPTO" />
                </p>
              </div>
              <div
                className={`wallet__tab ${
                  activeTab === "fiat" ? "wallet__tab-active" : ""
                }`}
                onClick={() => setActiveTab("fiat")}
              >
                <p className="wallet__tab-text">
                  <Text tid="WALLET.WALLET_POPUP.FIAT" />
                </p>
              </div>

              {/* <div className="wallet__tab">
              <p className="wallet__tab-text">Buy Crypto</p>
            </div> */}
            </div>
            <div className="wallet__currency-box">
              <div className="wallet__currency">
                <p className="wallet__currency-text">
                  <Text tid="MAIN.MAIN.CURRENCY" />
                </p>
                <CurrencySelect
                  handleSelect={
                    activeTab === "crypto"
                      ? handleSelectCurrency
                      : handleSelectFiatCurrency
                  }
                  selectedBalance={
                    activeTab === "crypto"
                      ? selectedCurrency
                      : selectedFiatCurrency
                  }
                  balances={
                    activeTab === "crypto" ? currencies : fiatCurrencies
                  }
                />
              </div>
              {activeTab === "crypto" ? (
                <div className="wallet__currency">
                  <p className="wallet__currency-text">
                    <Text tid="MAIN.MAIN.NETWORK" />
                  </p>
                  <NetworkSelect
                    handleSelectNet={handleSelectNet}
                    selectedNet={selectedNet}
                    nets={nets}
                  />
                </div>
              ) : (
                <div className="wallet__currency">
                  <p className="wallet__currency-text">
                    <Text tid="WALLET.WALLET_POPUP.CURRENCY_DEPOSIT" />
                  </p>
                  <CurrencySelect
                    handleSelect={handleSelectCurrency}
                    selectedBalance={selectedCurrency}
                    balances={currencies}
                  />
                </div>
              )}
            </div>
            {activeTab === "crypto" ? (
              <div className="wallet__adress">
                <p className="wallet__adress-text">
                  <Text tid="WALLET.WALLET_POPUP.DEPOSIT_ADDRESS" />
                </p>
                <div
                  className="wallet__adress-box"
                  ref={copyRef}
                  onClick={() => handleCopyClick(address)}
                  role="button"
                  tabIndex={0}
                >
                  {address ? (
                    <p className="wallet__adress-box-text">{address}</p>
                  ) : (
                    <LoaderPulse />
                  )}
                  <img src={copy} alt="" />
                </div>
                <div
                  className="wallet__currencyBox"
                  style={{ marginTop: "8px" }}
                >
                  <span className="wallet__currencyText">
                    <Text tid="WALLET.WALLET_POPUP.MIN_AMOUNT_WARNING" />
                    &nbsp;{" "}
                    {rangeAmountLoading ? <LoaderPulse /> : <>{minAmount}</>}
                    <img
                      src={
                        codeParams[selectedCurrency?.code?.toLowerCase?.()]
                          ?.icon
                      }
                      className="currency-icon"
                      alt=""
                    />
                  </span>
                  <span className="wallet__currencyText">
                    <Text tid="WALLET.WALLET_POPUP.MAX_AMOUNT_WARNING" />
                    &nbsp;{" "}
                    {rangeAmountLoading ? <LoaderPulse /> : <>{maxAmount}</>}
                    <img
                      src={
                        codeParams[selectedCurrency?.code?.toLowerCase?.()]
                          ?.icon
                      }
                      className="currency-icon"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            ) : (
              <div className="wallet__adress">
                <p className="wallet__adress-text">
                  <Text tid="WALLET.WALLET_POPUP.DEPOSIT_AMOUNT" />
                </p>
                <input
                  value={amount}
                  onChange={(e) => {
                    const v = e.target.value;
                    if (isNaN(Number(v))) e.preventDefault();
                    else setAmount(v);
                  }}
                  className="wallet__adress-box input-with-max-button"
                  ref={copyRef}
                  role="button"
                  tabIndex={0}
                  placeholder={t("WALLET.WALLET_POPUP.ENTER_DEPOSIT_AMOUNT")}
                />

                <div
                  className="wallet__currencyBox"
                  style={{ marginTop: "8px" }}
                >
                  <span className="wallet__currencyText">
                    <Text tid="WALLET.WALLET_POPUP.MIN_AMOUNT_WARNING" />
                    &nbsp;{" "}
                    {rangeAmountLoading ? <LoaderPulse /> : <>{minAmount}</>}
                    <img
                      src={
                        codeParams[selectedFiatCurrency?.code?.toLowerCase?.()]
                          ?.icon
                      }
                      className="currency-icon"
                      alt=""
                    />
                  </span>
                  <span className="wallet__currencyText">
                    <Text tid="WALLET.WALLET_POPUP.MAX_AMOUNT_WARNING" />
                    &nbsp;{" "}
                    {rangeAmountLoading ? <LoaderPulse /> : <>{maxAmount}</>}
                    <img
                      src={
                        codeParams[selectedFiatCurrency?.code?.toLowerCase?.()]
                          ?.icon
                      }
                      className="currency-icon"
                      alt=""
                    />
                  </span>
                </div>
                {minError ? (
                  <span style={{ color: "red", marginTop: 6, fontSize: 14 }}>
                    <Text
                      tid="WALLET.WALLET_POPUP.MIN_AMOUNT_ERROR"
                      values={{ min: minAmount }}
                    />
                  </span>
                ) : maxError ? (
                  <span style={{ color: "red", marginTop: 6, fontSize: 14 }}>
                    <Text
                      tid="WALLET.WALLET_POPUP.MAX_AMOUNT_ERROR"
                      values={{ max: maxAmount }}
                    />
                  </span>
                ) : null}
              </div>
            )}

            <p className="wallet__currency-text">
              {activeTab === "crypto" ? (
                <Text tid="WALLET.WALLET_POPUP.DEPOSIT_HELPER" />
              ) : (
                <Text tid="WALLET.WALLET_POPUP.CURRENCY_DEPOSIT_HELPER" />
              )}
            </p>
            {address && activeTab === "crypto" && (
              <div className="wallet__qr">
                <QRCode
                  value={address}
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "10px",
                    backgroundColor: "#fff",
                  }}
                />
                <p className="wallet__qr-text">
                  <br />
                  <Text
                    tid="WALLET.WALLET_POPUP.SEND_ONLY"
                    values={{
                      amount: depositData?.amount,
                      currency: selectedCurrency?.name,
                      network: selectedNet?.label,
                    }}
                  />
                  {/* <Text tid="WALLET.WALLET_POPUP.CONFIRMATIONS" /> */}
                </p>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      {activeTab === "fiat" && (
        <div
          className="wallet__footer"
          style={{
            boxSizing: "border-box",
          }}
        >
          <PrimaryButton
            className="wallet__footer-btn"
            disabled={disabled}
            loading={loading}
            type="submit"
          >
            <p className="wallet__footer-btn-text">
              <Text tid="WALLET.WALLET_POPUP.ENABLE" />
            </p>
          </PrimaryButton>
          {(error || requestError) && (
            <AlertMessage
              message={errorMessage || requestErrorMessage}
              type="error"
              style={{ marginTop: "16px" }}
            />
          )}
        </div>
      )}
    </form>
  );
};

export default DepositView;
