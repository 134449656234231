import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { BetModalCurrency } from "../BetModal/BetModalCurrency";
import { currencyCodeParams } from "../../utils/currencyOptions";
import PrimaryButton from "../PrimaryButton";
import { fix2, fix8 } from "../../utils/fixers";
import { CardHashPopup } from "../CardHash";
import { Text } from "../../utils/Text";
import { BetModal } from "../BetModal/BetModal";
import { GAME_TYPES } from "../../constants/games";
import { getData } from "../../utils/store";

const BetCrashGame = ({ setCurrency, currency }) => {
  const { balanceData, gameData, isFiat, user } = useSelector(
    ({ game, balance, gameData, auth, user: userData }) => ({
      gameTotalBank: game?.data?.totalBank,
      balanceData: balance?.data,
      isFiat: balance?.isFiat,
      isHide: balance?.isHide,
      gameData: game?.data,
      user: getData(userData.data),
    })
  );

  const isCrash = true;

  const betItems = [
    {
      photo: user?.photo,
      nickname: user?.nickname,
      bet: <Text tid="CRASH.CRASH_TAB.BETTING" />,
      cashOut: <Text tid="CRASH.CRASH_TAB.BETTING" />,
      amount: 5454,
      currencyIcon: currencyCodeParams[currency?.code]?.icon,
    },
    {
      photo: user?.photo,
      nickname: "123123312123132123132312132",
      cashOut: 0.12345678,
      bet: <Text tid="CRASH.CRASH_TAB.BETTING" />,
      amount: 5454,
      currencyIcon: currencyCodeParams[currency?.code]?.icon,
    },
    {
      photo: user?.photo,
      nickname: "grergergeerger",
      cashOut: 0.12345678,
      bet: <Text tid="CRASH.CRASH_TAB.BETTING" />,
      amount: 213,
      currencyIcon: currencyCodeParams[currency?.code]?.icon,
    },
    {
      photo: user?.photo,
      nickname: "Dima",
      cashOut: 0.12345678,
      bet: <Text tid="CRASH.CRASH_TAB.BETTING" />,
      amount: 33,
      currencyIcon: currencyCodeParams[currency?.code]?.icon,
    },
  ];

  const lastWinners = [
    { multiplier: "1.5x", class: "minWin" },
    { multiplier: "2.5x", class: "midWin" },
    { multiplier: "8.5x", class: "midWin" },
    { multiplier: "15.5x", class: "midWin" },
    { multiplier: "70.5x", class: "maxWin" },
    { multiplier: "170.5x", class: "maxWin" },
    { multiplier: "1.5x", class: "minWin" },
    { multiplier: "2.5x", class: "midWin" },
    { multiplier: "8.5x", class: "midWin" },
    { multiplier: "15.5x", class: "midWin" },
    { multiplier: "70.5x", class: "maxWin" },
    { multiplier: "170.5x", class: "maxWin" },
  ];

  return (
    <div className="BetCrashGame">
      <div className="BetCrashGame__container">
        <div className="BetCrashGame__lastWinners">
          {lastWinners.map((winner, index) => (
            <span
              className={`BetCrashGame__lastWinnersBet ${winner.class}`}
              key={index}
            >
              {winner.multiplier}
            </span>
          ))}
        </div>
        <div className="BetCrashGame__betBox">
          <BetModal
            setCurrency={setCurrency}
            currency={currency}
            isCrash={isCrash}
          />
        </div>
        <div className="BetCrashGame__blue-bg">
          <div className="BetCrashGame__user">
            <div className="BetCrashGame__user--title">
              <p>
                <Text tid="DOUBLE.DOUBLE_TAB.TOTAL_BETS" />:
              </p>
              <p className="blue-c">30$</p>
            </div>
            <div className="BetCrashGame__userbet-container">
              <div className="BetCrashGame__userbet-list">
                <div className="BetCrashGame__userbet-item-title">
                  <div className="BetCrashGame__userbet-column">
                    <p className="BetCrashGame__userbet-column-title">
                      <Text tid="CRASH.CRASH_TAB.PLAYER" />
                    </p>
                  </div>
                  <div
                    className="BetCrashGame__userbet-column"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className="BetCrashGame__userbet-column-title"
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Text tid="CRASH.CRASH_TAB.CHASHOUT" />
                    </p>
                  </div>
                  <div
                    className="BetCrashGame__userbet-column"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className="BetCrashGame__userbet-column-title"
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Text tid="CRASH.CRASH_TAB.AMOUNT" />
                    </p>
                  </div>
                  <div className="BetCrashGame__userbet-column profit">
                    <p className="BetCrashGame__userbet-column-title profit">
                      <Text tid="CRASH.CRASH_TAB.PROFIT" />
                    </p>
                  </div>
                </div>
                <div className="BetCrashGame__userbet-itemList">
                  {betItems.map((item, index) => (
                    <div className="BetCrashGame__userbet-item" key={index}>
                      <div className="BetCrashGame__userbet-column">
                        <div className="BetCrashGame__userbet-column-user">
                          <img
                            src={item.photo}
                            alt="User Avatar"
                            className="BetCrashGame__userbet-column-user-avatar"
                          />
                          <span className="BetCrashGame__userbet-column-user-nickname">
                            {item.nickname}
                          </span>
                        </div>
                      </div>
                      <div
                        className="BetCrashGame__userbet-column"
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: "5px",
                          flexDirection: "row",
                        }}
                      >
                        <span className="BetCrashGame__userbet-column-amount">
                          {item.bet}
                        </span>
                      </div>
                      <div
                        className="BetCrashGame__userbet-column"
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: "5px",
                          flexDirection: "row",
                        }}
                      >
                        <span className="BetCrashGame__userbet-column-amount BetCrashGame__userbet-column-amount--white">
                          {item.amount}
                        </span>
                        <img src={item.currencyIcon} alt="Icon" />
                      </div>
                      <div
                        className="BetCrashGame__userbet-column "
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "flex-end",
                        }}
                      >
                        <span
                          className={`BetCrashGame__userbet-column-amount ${typeof item.cashOut === "number" ? "amount-number" : ""}`}
                        >
                          {typeof item.cashOut === "number"
                            ? `x ${item.cashOut}`
                            : item.cashOut}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetCrashGame;
