import React from "react";
import { useSelector } from "react-redux";

import Deposit from "../Deposite";
import Withdraw from "../Withdraw";

import WalletView from "./View";

const Wallet = ({ opacity, setOpacity, activeTab, setActiveTab }) => {
  const { auth } = useSelector(({ auth: authData }) => ({
    auth: authData,
  }));

  const zIndex = opacity === 1 ? 999999 : -1;

  const handleBackgroundClick = (event) => {
    if (event.target.classList.contains("bg")) {
      setOpacity(0);
    }
  };

  const handlePopupClose = () => {
    setOpacity(0);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "deposit":
        return <Deposit handlePopupClose={handlePopupClose} />;
      case "withdraw":
        return <Withdraw handlePopupClose={handlePopupClose} />;
      default:
        return <Deposit handlePopupClose={handlePopupClose} />;
    }
  };

  if (!auth.logged) {
    return null;
  }

  return (
    <WalletView
      opacity={opacity}
      zIndex={zIndex}
      handleBackgroundClick={handleBackgroundClick}
      handlePopupClose={handlePopupClose}
      setActiveTab={setActiveTab}
      renderTabContent={renderTabContent}
      activeTab={activeTab}
    />
  );
};

export default Wallet;
