import { PolicyView } from "./View";


export const Policy = () => {
  return (
    <div className="body">
      <PolicyView />
    </div>
  );
};
