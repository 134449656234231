import React from "react";
import { gameGetTimeLeft } from "../../utils/game-get-time-left";
import LoadLineView from "./View";

const LoadLine = ({ gameData }) => {
  const membersCount = gameData?.members?.length;
  const timeLeft = gameGetTimeLeft(gameData);
  const total = 50;

  const lineWidth = (membersCount / total) * 100;

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return (
    <LoadLineView
      formattedTime={formattedTime}
      membersCount={membersCount}
      lineWidth={lineWidth}
      timeLeft={timeLeft}
      total={total}
    />
  );
};

export default LoadLine;
