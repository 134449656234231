import React from "react";

import Ticket from "../Ticket";
import Bet from "../Bet";

export const ClassicGameMakeBet = ({
  handleBidPopUpClick,
  handleHashOpen,
  isOpenTrigger,
  isShowWinner,
  isRecruit,
  currency,
}) => {
  return (
    <div
      className="classic-wrapper__make-bet"
      style={{ display: "grid", gap: 6 }}
    >
      <Bet
        handleBidPopUpClick={handleBidPopUpClick}
        handleHashOpen={handleHashOpen}
        isOpenTrigger={isOpenTrigger}
        isShowWinner={isShowWinner}
        isRecruit={isRecruit}
        currency={currency}
      />
      {isShowWinner ? <Ticket /> : null}
    </div>
  );
};
