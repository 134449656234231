export const ROUTES = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  SIGNUP_CONFIRM: ({ email } = { email: "" }) =>
    `/signup/confirm${email ? `?email=${email}` : ""}`,
  HOME: "/",
  RECOVERY_ACCOUNT_RESET: "/recovery-account",
  RECOVERY_ACCOUNT_UPDATE: "/recovery-account/update",
  RECOVERY_ACCOUNT_CONFIRM: "/recovery-account/confirm",
  AIR_DROP: "/airdrop",
  SETTINGS_GENERAL: "/settings/general",
  CLASSIC: "/classic",
  CLASSIC_HISTORY: "/classic/history",
  CLASSIC_TOP: "/classic/top",
  DOUBLE: "/double",
  DOUBLE_HISTORY: "/double/history",
  DOUBLE_TOP: "/double/top",
  CARDS: "/cards",
  CARDS_HISTORY: "/cards/history",
  CARDS_TOP: "/cards/top",
  CRASH: "/crash",
  CRASH_HISTORY: "/crash/history",
  CRASH_TOP: "/crash/top",
  SETTINGS_SECURITY: "/settings/security",
  SETTINGS_SESSIONS: "/settings/sessions",
  SETTINGS_REFERRAL: "/settings/referral",
  SETTINGS_PARTNER: "/settings/partner",
  SETTINGS_DEPOSIT: "/settings/deposit",
  SETTINGS_WITHDRAW: "/settings/withdraw",
  SETTINGS_IGNORED_USERS: "/settings/ignored-users",
  SETTINGS_OFFERS: "/settings/offers",
  TERMS_OF_USE: "/terms-of-conditions",
  POLICY: "/policy",
  ABOUT: "/faq",
};
