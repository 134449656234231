import React from "react";
import Select from "react-select";
import "./index.scss";
import globe from "../../assets/img/globe.png";

const LanguageSelect = ({ languages, selectedLanguage, ...selectProps }) => {
  const customStyles = {
    singleValue: (provided, state) => ({
      ...provided,
      background: window.innerWidth <= 322 ? null : `url(${globe}) left center no-repeat`,
      display: `flex`,
      paddingLeft: window.innerWidth <= 322 ? null : "40px",
      paddingTop: "4px",
      backgroundSize: window.innerWidth <= 322 ? `20px` : `24px`,
      height: `24px`,
      color: "#fff",
    }),
  };

  const customOptionComponent = ({ innerProps, label, data }) => (
    <div
      {...innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        backgroundColor: "#1C1C1C",
        color: "#fff",
        margin: "0 0 0 0",
        padding: "000",
        transition: "0.3s ease-in-out",
        opacity: 1,
        cursor: "pointer",
      }}
      className="custom-option"
    >
      <span
        style={{
          backgroundSize: "28px",
          backgroundRepeat: "no-repeat",
          width: "28px",
          height: "28px",
          alignItems: "center",
          display: "flex",
          paddingLeft: "10px",
          marginTop: "3px",
        }}
        className="custom-option__flag"
      >
        {label}
      </span>
    </div>
  );

  return (
    <Select
      isSearchable={false}
      options={languages}
      components={{ Option: customOptionComponent }}
      styles={customStyles}
      value={selectedLanguage}
      {...selectProps}
    />
  );
};

export default LanguageSelect;
