import { WITHDRAW_PARTNER_CREATE } from "../actions/withdrawPartnerCreate";
import {
  initRequestState,
  setRequestError,
  setRequestPending,
  setRequestSuccess,
} from "../utils/store";

const initialState = {
  request: initRequestState(),
};

export const withdrawPartnerCreate = (state = initialState, action) => {
  switch (action.type) {
    case WITHDRAW_PARTNER_CREATE.SUCCESS:
      return {
        ...state,
        request: setRequestSuccess(state.request, action.data),
      };

    case WITHDRAW_PARTNER_CREATE.LOADING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };

    case WITHDRAW_PARTNER_CREATE.ERROR:
      return {
        ...state,
        request: setRequestError(state.request, action.message),
      };

    default:
      return state;
  }
};
