const fix = (n, f) => {
  if (String(n).indexOf(".") !== -1)
    return String(n).slice(0, String(n).indexOf(".") + f + 1);
  return String(n);
};

export const fix8 = (n) => Number(fix(n || 0, 8)).toFixed(8);
export const fix2 = (n) => Number(fix(n || 0, 2)).toFixed(2);
export const fix1 = (n) => Number(fix(n || 0, 1)).toFixed(1);
export const fix0 = (n) => Number(fix(n || 0, 0)).toFixed(0);

const fixDec8 = (n) => Number(fix0(Number(fix8(n)) * 10 ** 8));
const fixDec2 = (n) => Number(fix0(Number(fix2(n)) * 10 ** 2));
const fixInt8 = (n) => Number(fix8(n / 10 ** 8));
const fixInt2 = (n) => Number(fix2(n / 10 ** 2));

export const fixDec = (tokenCode) => {
  const codes8 = ["btc", "eth", "ltc"];
  if (codes8.includes(tokenCode.toLowerCase())) return fixDec8;
  return fixDec2;
};

export const fixInt = (tokenCode) => {
  const codes8 = ["btc", "eth", "ltc"];
  if (codes8.includes(tokenCode.toLowerCase())) return fixInt8;
  return fixInt2;
};
