import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "../../utils/Text";
import { PARTNER_APPLICATION } from "../../constants/fields";
import PrimaryButton from "../../components/PrimaryButton";
import { AlertMessage } from "../../components/AlertMessage";

export const SettingsPartnerApplicationView = ({
  fields,
  changeField,
  disabled,
  onSubmit,
  loading,
  error,
  errorMessage,
  success,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div>
        <p className="settings-security__password-box-title">
          <Text tid="SETTINGS.SETTINGS_TAB.PARTNER_APPLICATION" />
        </p>
        <p className="text" style={{ marginBottom: "16px" }}>
          <Text tid="SETTINGS.SETTINGS_TAB.PARTNER_APPLICATION_DESCRIPTION" />
        </p>
        <form className="settings-security__password-box" onSubmit={onSubmit}>
          <div>
            <p className="settings-security__password-box-text text">
              <Text tid="SETTINGS.SETTINGS_TAB.PARTNER_DESCRIPTION" />
            </p>
            <div className="settings-security__password-box-input-wrapper">
              <textarea
                className="settings-security__password-box-input input textarea"
                type="text"
                placeholder={t(
                  "SETTINGS.SETTINGS_TAB.ENTER_PARTNER_DESCRIPTION"
                )}
                value={fields[PARTNER_APPLICATION.DESCRIPTION]}
                onChange={(e) =>
                  changeField(PARTNER_APPLICATION.DESCRIPTION, e.target.value)
                }
                required
                minLength={4}
                maxLength={200}
              />
            </div>
          </div>
          <div>
            <p className="settings-security__password-box-text text">
              <Text tid="SETTINGS.SETTINGS_TAB.TELEGRAM" />
            </p>
            <div className="settings-security__password-box-input-wrapper">
              <input
                className="settings-security__password-box-input input"
                type="text"
                placeholder={t("SETTINGS.SETTINGS_TAB.ENTER_TELEGRAM")}
                value={fields[PARTNER_APPLICATION.TELEGRAM]}
                onChange={(e) =>
                  changeField(PARTNER_APPLICATION.TELEGRAM, e.target.value)
                }
                required
                minLength={4}
                maxLength={30}
              />
            </div>
          </div>

          <PrimaryButton
            type="submit"
            disabled={disabled}
            loading={loading}
            // className="settings-security__password-box-button button"
          >
            <span>
              <Text tid="MAIN.MAIN.SAVE" />
            </span>
          </PrimaryButton>

          {error && <AlertMessage message={errorMessage} type="error" />}
          {success && (
            <AlertMessage
              message="SETTINGS.SETTINGS_TAB.SEND_APPLICATION_SUCCESS"
              type="success"
            />
          )}
        </form>
      </div>
    </React.Fragment>
  );
};
