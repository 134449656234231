import cookie from "js-cookie";

export const setCookie = (key, value) => {
  cookie.set(key, value);
};

export const getCookie = (key) => {
  const token = cookie.get(key);
  return token === "null" ? null : token;
};

export const getBrowserCookie = (key) => {
  const token = cookie.get(key);
  return token === "null" ? null : token;
};
