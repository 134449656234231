const sys = (v) => `SYS_${v}`;
const req = (v) => `REQ_${v}`;
const res = (v) => `RES_${v}`;

export const SOCK_RESPONSES = {
  MAKE_BET_CLASSIC_ROOM: res("MAKE_BET_CLASSIC_ROOM"),
  WINNER_BALANCE_UPDATE: res("WINNER_BALANCE_UPDATE"),
  MAKE_BET_DOUBLE_ROOM: res("MAKE_BET_DOUBLE_ROOM"),
  CONNECT_CLASSIC_ROOM: res("CONNECT_CLASSIC_ROOM"),
  USER_BALANCE_UPDATE: res("USER_BALANCE_UPDATE"),
  CONNECT_DOUBLE_ROOM: res("CONNECT_DOUBLE_ROOM"),
  GAME_UPDATE_MEMBERS: res("GAME_UPDATE_MEMBERS"),
  GAME_UPDATE_STATUS: res("GAME_UPDATE_STATUS"),
  GAME_UPDATE_TIMER: res("GAME_UPDATE_TIMER"),
  PING: res("PING"),

  MAKE_BET_CLASSIC_ROOM_LIMIT_EXCEEDED: res(
    "MAKE_BET_CLASSIC_ROOM_LIMIT_EXCEEDED"
  ),

  CHAT_CONNECT: res("CHAT_CONNECT"),
  CHAT_SEND_MESSAGE: res("CHAT_SEND_MESSAGE"),
  CHAT_UPDATE: res("CHAT_UPDATE"),
};

export const SOCK_REQUESTS = {
  MAKE_BET_CLASSIC_ROOM: req("MAKE_BET_CLASSIC_ROOM"),
  WINNER_BALANCE_UPDATE: req("WINNER_BALANCE_UPDATE"),
  MAKE_BET_DOUBLE_ROOM: req("MAKE_BET_DOUBLE_ROOM"),
  CONNECT_CLASSIC_ROOM: req("CONNECT_CLASSIC_ROOM"),
  USER_BALANCE_UPDATE: req("USER_BALANCE_UPDATE"),
  CONNECT_DOUBLE_ROOM: req("CONNECT_DOUBLE_ROOM"),
  GAME_UPDATE_MEMBERS: req("GAME_UPDATE_MEMBERS"),
  GAME_UPDATE_STATUS: req("GAME_UPDATE_STATUS"),
  GAME_UPDATE_TIMER: req("GAME_UPDATE_TIMER"),
  PING: req("PING"),

  MAKE_BET_CLASSIC_ROOM_LIMIT_EXCEEDED: req(
    "MAKE_BET_CLASSIC_ROOM_LIMIT_EXCEEDED"
  ),

  CHAT_CONNECT: req("CHAT_CONNECT"),
  CHAT_SEND_MESSAGE: req("CHAT_SEND_MESSAGE"),
};

export const SOCK_SYSTEM = {
  UPDATE_TOTAL_ONLINE: sys("UPDATE_TOTAL_ONLINE"),
  ROOM_DESTROYED: sys("ROOM_DESTROYED"),
  TOKENS_UPDATED: sys("TOKENS_UPDATED"),
  GAME_CREATED: sys("GAME_CREATED"),
};

export const CLASSIC_ROOM_TYPES = {
  DEFAULT: "DEFAULT",
};
