/* eslint-disable no-return-assign */
import { audioPlay, registerAudio } from "../utils/media";
import { SOCK_REQUESTS } from "../constants/socket";
import { AUDIO } from "../constants/audio";
import { axios } from "../utils/request";
import { API } from "../constants/api";

export const BALANCE = {
  CONNECTING: "BALANCE.CONNECTING",
  HIDE_ZERO: "BALANCE.HIDE_ZERO",
  CONNECTED: "BALANCE.CONNECTED",
  UPDATING: "BALANCE.UPDATING",
  UPDATED: "BALANCE.UPDATED",
  SELECT: "BALANCE.SELECT",
  FIAT: "BALANCE.FIAT",
};

const setConnected = (payload) => ({ type: BALANCE.CONNECTED, payload });
const setConnecting = () => ({ type: BALANCE.CONNECTING });

const selectHideSuccess = (payload) => ({ type: BALANCE.HIDE_ZERO, payload });
const selectFiatSuccess = (payload) => ({ type: BALANCE.FIAT, payload });
const selectSuccess = (payload) => ({ type: BALANCE.SELECT, payload });

export const updateTokens = () => {
  return async (dispatch, getState) => {
    const { data: oldBns, selected: selectedBns } = getState()?.balance || {};
    if (!oldBns?.length) return null;
    const codes = {};

    oldBns.forEach((b) => (codes[b.code] = b.amount));
    const { data } = await axios.post(API.UPDATE_TOKENS, codes);
    const { codes: newCodes, totalUsdt } = data;

    const buffer = [];
    oldBns.forEach((b) => {
      buffer.push({ ...b, sumUsdt: newCodes[b.code] });
    });

    dispatch(setConnected({ balances: buffer, totalUsdt }));

    if (selectedBns?.code) {
      if (selectedBns.code === "usd") {
        const amount = totalUsdt;
        const buff = { ...selectedBns, amount, sumUsdt: totalUsdt };
        dispatch(selectSuccess(buff));
      } else {
        const finded = buffer.find((b) => b.code === selectedBns.code);
        dispatch(selectSuccess(finded));
      }
    }
  };
};

export const getBalanceRequest = () => {
  return async (dispatch, getState) => {
    getState()?.socket?.emit?.(SOCK_REQUESTS.USER_BALANCE_UPDATE);
    dispatch(setConnecting());
  };
};

export const selectBalance = ({ balance }) => {
  return async (dispatch) => {
    dispatch(selectSuccess(balance));
    localStorage.setItem("bnce-selected", balance.code);
  };
};

export const setBalanceResponse = (response) => {
  return async (dispatch, getState) => {
    const { loadInfo, result } = response;
    const { amounts, totalUsdt } = result || {};

    if (amounts) {
      const { data: oldBns, selected: selectedBns } = getState()?.balance || {};
      const bnsBuff = [...oldBns];

      Object.keys(amounts).forEach((code) => {
        let bnsIndex = 0;
        oldBns.forEach(({ code: oldCode }, i) => {
          if (oldCode === code) bnsIndex = i;
        });
        bnsBuff[bnsIndex].sumUsdt = amounts[code].usdt;
        bnsBuff[bnsIndex].amount = amounts[code].value;
      });

      dispatch(setConnected({ balances: bnsBuff, totalUsdt }));

      if (selectedBns?.code) {
        if (selectedBns.code === "usd") {
          const amount = totalUsdt;
          const buff = { ...selectedBns, amount, sumUsdt: totalUsdt };
          dispatch(selectSuccess(buff));
        } else {
          const finded = bnsBuff.find((b) => b.code === selectedBns.code);
          dispatch(selectSuccess(finded));
        }
      }
    } else {
      const { balances: bns, totalUsdt: sumUsdt } = loadInfo;
      const selected = getState()?.balance?.selected;

      dispatch(setConnected({ balances: bns, totalUsdt: sumUsdt }));

      if (!selected || selected?.code === "usd") {
        const isFiat = localStorage.getItem("bnce-is-fiat") || "false";
        const fiat = JSON.parse(isFiat);

        const isHide = localStorage.getItem("bnce-is-hide") || "false";
        const hide = JSON.parse(isHide);

        const isSelected = localStorage.getItem("bnce-selected") || "";
        const bnsCurrCode = String(isSelected);

        const bnsCurr = bns.find((b) => b.code === bnsCurrCode) || bns[0];

        const b = { name: "Total USD", amount: sumUsdt, code: "usd", sumUsdt };

        dispatch(selectBalance({ balance: b }));
        dispatch(selectSuccess(bnsCurr));
        dispatch(selectFiatSuccess(fiat));
        dispatch(selectHideSuccess(hide));
      } else {
        const balance = bns.find((b) => b.code === selected.code);
        if (balance) {
          dispatch(selectSuccess(balance));
          dispatch(selectBalance({ balance }));
          if (Number(balance.amount) > selected.amount)
            audioPlay(registerAudio(AUDIO.BALANCE_UP));
        }
      }
    }
  };
};

export const selectFiatBalance = () => {
  return async (dispatch, getState) => {
    const isFiat = getState()?.balance?.isFiat || false;
    dispatch(selectFiatSuccess(!isFiat));
    localStorage.setItem("bnce-is-fiat", String(!isFiat));
  };
};

export const selectHideZeroBalance = () => {
  return async (dispatch, getState) => {
    const isHide = getState()?.balance?.isHide || false;
    dispatch(selectHideSuccess(!isHide));
    localStorage.setItem("bnce-is-hide", String(!isHide));
  };
};
