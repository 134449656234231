/* eslint-disable react/button-has-type */
import React from "react";

export const InputMaxButton = ({ handle, children }) => {
  return (
    <div style={{ position: "relative" }}>
      {children}
      <div
        style={{
          alignItems: "center",
          position: "absolute",
          display: "flex",
          height: 40,
          right: 4,
          top: 0,
        }}
      >
        <button
          onClick={handle}
          type="button"
          style={{
            background: "#2d2d2d",
            padding: "6px 12px",
            cursor: "pointer",
            borderRadius: 6,
            outline: "none",
            fontWeight: 500,
            color: "white",
            border: 0,
          }}
        >
          Max
        </button>
      </div>
    </div>
  );
};
