import React from "react";
import { FaqView } from "./View";

export const Faq = () => {
  return (
    <div className="body">
      <FaqView />
    </div>
  );
};
