import React from "react";

import { Text } from "../../../../utils/Text";
import gear from "../../../../assets/img/gear.svg";
import "./index.scss";

export const SettingsLayout = ({ children, title }) => {
  return (
    <div className="settings-general" id="general">
      <div className="settings-general__container">
        <div className="settings-general__header">
          <div className="settings-general__header-icon">
            <img src={gear} alt="" />
            <p className="settings-general__header-text">
              <Text tid={`SETTINGS.SETTINGS_TAB.SETTINGS_${title}`} />
            </p>
          </div>
        </div>
        <hr className="settings-general__header-line" />
        <div className="settings-general__content">{children}</div>
      </div>
    </div>
  );
};
