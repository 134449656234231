import { useTranslation } from "react-i18next";
import Select from "react-select";
import React, { useEffect } from "react";
import "./index.scss";

import { networkParams } from "../../utils/currencyOptions";

const CustomOption = ({ innerProps, data }) => {
  return (
    <div
      {...innerProps}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "95%",
        padding: "2.5px 3px",
        gap: "10px",
        fontSize: "13px",
        color: "#fff",
        cursor: "pointer",
        marginRight: "0px",
      }}
      className="option"
    >
      {data?.label}
      <img
        src={networkParams[data.code]?.icon}
        className="currency-icon"
        alt=""
      />
    </div>
  );
};

const CustomSingleValue = ({ innerProps, data }) => (
  <div
    className="custom-single-value"
    {...innerProps}
    style={{
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%",
      gap: "10px",
      position: "relative",
      top: "-8px",
      color: "#fff",
    }}
  >
    <p>{data?.label}</p>
    <img
      src={networkParams[data.code]?.icon}
      style={{ paddingBottom: "2px" }}
      className="currency-icon"
      alt=""
    />
  </div>
);

const NetworkSelect = ({ handleSelectNet, selectedNet, nets, ...props }) => {
  const { t } = useTranslation();
  useEffect(() => {}, [nets]);

  return (
    <Select
      styles={{
        control: (base) => ({
          ...base,
          width: "100%",
          backgroundColor: "#242424",
          border: "none",
          "&:hover": {
            opacity: "0.8",
          },
        }),
        menu: (base) => ({
          ...base,
          borderRadius: "5px",
          marginTop: "10px",
          boxShadow: "none",
          border: "solid 1px #367AFF",
          backgroundColor: "#242424",
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isSelected ? "#F5F5F5" : "#F5F5F5",
          color: "#000000",
          "&:hover": {
            opacity: "0.8",
            color: "#367AFF",
          },
          "&:active": {
            backgroundColor: "#F5F5F5",
            color: "#000000",
          },
        }),
        placeholder: (base) => ({
          ...base,
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          height: "100%",
          gap: "10px",
        }),
      }}
      options={nets}
      className="wallet__currency-select-box"
      onChange={handleSelectNet}
      placeholder={t("CLASSIC.CLASSIC_TAB.SELECT")}
      value={selectedNet}
      isSearchable={false}
      components={{
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
      {...props}
    />
  );
};

export default NetworkSelect;
