import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

import { Tabs, Tab } from "@mui/material";

import { Text } from "../../utils/Text";
import copy from "../../assets/img/Copy.svg";
import PrimaryButton from "../../components/PrimaryButton";
import WithdrawPartner from "../withdraw-partner";
import { fix0 } from "../../utils/fixers";
import { PaymentList } from "../payment-list";
import { AlertMessage } from "../../components/AlertMessage";

export const SettingsPartnerDataView = ({
  copyRef,
  handleCopyClick,
  link,
  loaded,
  data,
  opacityWithdraw,
  setOpacityWithdraw,
  handleWithdrawClick,
  MIN_WITHDRAW,
}) => {
  const { t } = useTranslation();

  const [value, setValue] = React.useState("link");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Tabs
        className="settings__tabs"
        value={value}
        onChange={handleChange}
        style={{ marginTop: "-10px", marginBottom: "-16px" }}
      >
        <Tab value="link" label={t("SETTINGS.SETTINGS_TAB.LINK")} />
        <Tab value="balance" label={t("SETTINGS.SETTINGS_TAB.BALANCE")} />
        <Tab value="referrals" label={t("SETTINGS.SETTINGS_TAB.REFERRALS")} />
      </Tabs>
      <WithdrawPartner
        opacity={opacityWithdraw}
        setOpacity={setOpacityWithdraw}
      />
      <div>
        {value === "link" && (
          <React.Fragment>
            <p className="wallet__adress-text">
              <Text tid="SETTINGS.SETTINGS_TAB.PARTNER_LINK_DESCRIPTION" />
            </p>
            <div className="wallet__adress">
              <p className="wallet__adress-text">
                <Text tid="SETTINGS.SETTINGS_TAB.PARTNER_LINK" />
              </p>
              <div
                className="wallet__adress-box"
                ref={copyRef}
                style={{ maxWidth: "355px" }}
                onClick={handleCopyClick}
                onKeyDown={(event) => {
                  if (event.key === "Enter" || event.key === " ") {
                    handleCopyClick();
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <p className="wallet__adress-box-text">{link}</p>
                <img src={copy} alt="" />
              </div>
            </div>
          </React.Fragment>
        )}
        {loaded && value === "balance" && (
          <div>
            <p className="wallet__adress-text">
              <Text tid="SETTINGS.SETTINGS_TAB.BALANCE_TITLE" />
            </p>
            <div className="best_winner-container">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div className="best__winner">
                  <div className="best__user">
                    <div className="best__winner-title">
                      <p className="best__winner-title-text">
                        <Text tid="SETTINGS.SETTINGS_TAB.BALANCE_CURRENT" />
                      </p>
                    </div>
                    <p
                      className="best__winner-title-text big"
                      style={{ color: "#367aff" }}
                    >
                      {/* {fix0(data.totalUsdt)}$ */}
                      {data.totalUsdt}$
                    </p>
                  </div>
                </div>
              </div>

              <div className="best__winner">
                <div className="best__user">
                  <div className="best__winner-title">
                    <p className="best__winner-title-text">
                      <Text tid="SETTINGS.SETTINGS_TAB.PARTNER_PERCENT" />
                    </p>
                  </div>
                  <p
                    className="best__winner-title-text big"
                    style={{ color: "#367aff" }}
                  >
                    {data.partnerPercent}%
                  </p>
                </div>
              </div>
              <div className="best__winner">
                <div className="best__user">
                  <div className="best__winner-title">
                    <p className="best__winner-title-text">
                      <Text tid="SETTINGS.SETTINGS_TAB.TOTAL_EARNED" />
                    </p>
                  </div>
                  <p className="best__winner-title-text big">
                    {fix0(data.totalEarned)}$
                  </p>
                </div>
              </div>
              <div className="best__winner">
                <div className="best__user">
                  <div className="best__winner-title">
                    <p className="best__winner-title-text">
                      <Text tid="SETTINGS.SETTINGS_TAB.TOTAL_WITHDRAWN" />
                    </p>
                  </div>
                  <p className="best__winner-title-text big">
                    {fix0(data.totalWithdraw)}$
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: "20px",
              }}
            >
              <p className="wallet__adress-text">
                <Text tid="WALLET.WALLET_POPUP.WITHDRAW" />
              </p>
              <PrimaryButton
                style={{ width: "200px" }}
                onClick={handleWithdrawClick}
                disabled={data.totalUsdt < MIN_WITHDRAW}
              >
                <span>
                  <Text tid="WALLET.WALLET_POPUP.CONCLUSION" />
                </span>
              </PrimaryButton>

              {data.totalUsdt < MIN_WITHDRAW && (
                <AlertMessage
                  type="error"
                  message={t("WALLET.WALLET_POPUP.MIN_AMOUNT_ERROR", {
                    min: `${MIN_WITHDRAW}$`,
                  })}
                  style={{ marginTop: "12px" }}
                />
              )}
            </div>
            <PaymentList type="WITHDRAW_PARTNER" />
          </div>
        )}

        {loaded && value === "referrals" && (
          <React.Fragment>
            <p className="wallet__adress-text">
              <Text tid="SETTINGS.SETTINGS_TAB.REFERRALS_TITLE" />
            </p>
            <div className="best_winner-container">
              <div className="best__winner">
                <div className="best__user">
                  <div className="best__winner-title">
                    <p className="best__winner-title-text">
                      <Text tid="SETTINGS.SETTINGS_TAB.REFERRAL_STATISTICS_TOTAL" />
                    </p>
                  </div>
                  <p className="best__winner-title-text big">
                    {data.totalInvited}
                  </p>
                </div>
              </div>
              <div className="best__winner">
                <div className="best__user">
                  <div className="best__winner-title">
                    <p className="best__winner-title-text">
                      <Text tid="SETTINGS.SETTINGS_TAB.INVITED_WITH_DEPOSIT" />
                    </p>
                  </div>
                  <p className="best__winner-title-text big">
                    {data.invitedWithDeposit}
                  </p>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {loaded && value === "referrals" && (
          <React.Fragment>
            <p className="wallet__adress-text">
              <Text tid="SETTINGS.SETTINGS_TAB.REFERRALS_STATISTIC_TITLE" />
            </p>
            <div className="best_winner-container">
              <div className="best__winner">
                <div className="best__user">
                  <div className="best__winner-title">
                    <p className="best__winner-title-text">
                      <Text tid="SETTINGS.SETTINGS_TAB.PARTNER_USERS_TOTAL_TURNOVER" />
                    </p>
                  </div>
                  <p className="best__winner-title-text big">
                    {fix0(data.totalTurnover)}$
                  </p>
                </div>
              </div>
              <div className="best__winner">
                <div className="best__user">
                  <div className="best__winner-title">
                    <p className="best__winner-title-text">
                      <Text tid="SETTINGS.SETTINGS_TAB.PARTNER_USERS_TOTAL_DEPOSIT" />
                    </p>
                  </div>
                  <p className="best__winner-title-text big">
                    {fix0(data.totalDeposit)}$
                  </p>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
