import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DoubleHistoryView from "./View";
import "./index.scss";

const DoubleHistory = () => {
  const [redCount, setRedCount] = useState(0);
  const [blackCount, setBlackCount] = useState(0);
  const [zeroCount, setZeroCount] = useState(0);

  const { rollsHistory } = useSelector(({ historyDouble }) => ({
    rollsHistory: historyDouble.request.data,
  }));

  const countRolls = () => {
    setRedCount(0);
    setBlackCount(0);
    setZeroCount(0);

    rollsHistory.forEach((roll) => {
      if (roll.roundColor === "RED") {
        setRedCount((prevCount) => prevCount + 1);
      }
      if (roll.roundColor === "BLACK") {
        setBlackCount((prevCount) => prevCount + 1);
      }
      if (roll.roundColor === "ZERO") {
        setZeroCount((prevCount) => prevCount + 1);
      }
    });
  };

  useEffect(() => {
    if (rollsHistory) {
      countRolls();
    }
  }, [rollsHistory]);

  if (!rollsHistory) {
    return <div className="double-history">No data about previous rolls</div>;
  }

  const rolls = rollsHistory.map((roll) => {
    let color;
    if (roll.roundColor === "RED") {
      color = "#c73434";
    }
    if (roll.roundColor === "BLACK") {
      color = "#242424";
    }
    if (roll.roundColor === "ZERO") {
      color = "#34c8ad";
    }

    return { ...roll, color };
  });

  return (
    <DoubleHistoryView
      rolls={rolls}
      redCount={redCount}
      blackCount={blackCount}
      zeroCount={zeroCount}
    />
  );
};

export default DoubleHistory;
