import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";
import gear from "../../assets/img/gear.svg";

import { Text } from "../../utils/Text";
import { ROUTES } from "../../constants/routes";
import { USER_ROLE } from "../../constants/user-role";

const SettingsPopUpView = ({
  isSettingsPopUpOpen,
  toggleSettingsPopUp,
  location,
  user,
}) => {
  return (
    <div
      className="settings"
      style={{
        opacity: isSettingsPopUpOpen ? 1 : 0,
        visibility: isSettingsPopUpOpen ? "visible" : "hidden",
      }}
    >
      <div className="settings__container">
        <div className="settings__header">
          <div className="settings__header-icon">
            <img src={gear} alt="" />
            <p className="settings__header-text">
              <Text tid="SETTINGS.SETTINGS_TAB.SETTINGS" />
            </p>
          </div>
        </div>
        <hr className="settings__header-line" />
        <div className="settings__list">
          <Link
            to={ROUTES.SETTINGS_GENERAL}
            className={`settings__list-item ${
              location.pathname === "/settings/general" ? "active" : ""
            }`}
            onClick={toggleSettingsPopUp}
          >
            <p className="settings__list-item-text">
              <Text tid="SETTINGS.SETTINGS_TAB.GENERAL" />
            </p>
          </Link>

          <Link
            to={ROUTES.SETTINGS_SECURITY}
            className={`settings__list-item ${
              location.pathname === "/settings/security" ? "active" : ""
            }`}
            onClick={toggleSettingsPopUp}
          >
            <p className="settings__list-item-text">
              <Text tid="SETTINGS.SETTINGS_TAB.SECURITY" />
            </p>
          </Link>

          {user?.role !== USER_ROLE.PARTNER && (
            <Link
              to={ROUTES.SETTINGS_REFERRAL}
              className={`settings__list-item ${
                location.pathname === "/settings/referral" ? "active" : ""
              }`}
              onClick={toggleSettingsPopUp}
            >
              <p className="settings__list-item-text">
                <Text tid="SETTINGS.SETTINGS_TAB.REFERRAL" />
              </p>
            </Link>
          )}
          <Link
            to={ROUTES.SETTINGS_PARTNER}
            className={`settings__list-item ${
              location.pathname === "/settings/referral" ? "active" : ""
            }`}
            onClick={toggleSettingsPopUp}
          >
            <p className="settings__list-item-text">
              <Text tid="SETTINGS.SETTINGS_TAB.PARTNER" />
            </p>
          </Link>
          <Link
            to={ROUTES.SETTINGS_DEPOSIT}
            className={`settings__list-item ${
              location.pathname === "/settings/deposit" ? "active" : ""
            }`}
            onClick={toggleSettingsPopUp}
          >
            <p className="settings__list-item-text">
              <Text tid="SETTINGS.SETTINGS_TAB.DEPOSIT" />
            </p>
          </Link>
          <Link
            to={ROUTES.SETTINGS_WITHDRAW}
            className={`settings__list-item ${
              location.pathname === "/settings/withdraw" ? "active" : ""
            }`}
            onClick={toggleSettingsPopUp}
          >
            <p className="settings__list-item-text">
              <Text tid="SETTINGS.SETTINGS_TAB.WITHDRAW" />
            </p>
          </Link>
          {/* <Link
            to={ROUTES.SETTINGS_SESSIONS}
            className={`settings__list-item ${
              location.pathname === "/settings/sessions" ? "active" : ""
            }`}
            onClick={toggleSettingsPopUp}
          >
            <p className="settings__list-item-text">
              <Text tid="SETTINGS.SETTINGS_TAB.SESSION" />
            </p>
          </Link>
          <Link
            to={ROUTES.SETTINGS_IGNORED_USERS}
            className={`settings__list-item ${
              location.pathname === "/settings/ignored-users" ? "active" : ""
            }`}
            onClick={toggleSettingsPopUp}
          >
            <p className="settings__list-item-text">
              <Text tid="SETTINGS.SETTINGS_TAB.IGNORE" />
            </p>
          </Link>
          <Link
            to={ROUTES.SETTINGS_OFFERS}
            className={`settings__list-item ${
              location.pathname === "/settings/offers" ? "active" : ""
            }`}
            onClick={toggleSettingsPopUp}
          >
            <p className="settings__list-item-text">
              <Text tid="SETTINGS.SETTINGS_TAB.OFFERS" />
            </p>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default SettingsPopUpView;
