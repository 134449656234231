import React from "react";
import { Text } from "../../utils/Text";
import { useTranslation } from "react-i18next";

import { AUTH_USER } from "../../constants/fields";
import { AlertMessage } from "../../components/AlertMessage";
import PrimaryButton from "../../components/PrimaryButton";

export const SignupConfirmCodeView = ({
  fields,
  changeField,
  onSubmit,
  isFormDisabled,
  loading,
  error,
  errorMessage,
  user,
}) => {
  const { t } = useTranslation();

  return (
    <form className="LogIn" onSubmit={onSubmit}>
      <h1 className="SignIn__title">
        <Text tid="AUTH.AUTH.EMAIL_VERIFICATION" />
      </h1>
      <div className="LogIn__formBox">
        <p className="LogIn__helperText">
          <Text
            tid="AUTH.AUTH.RECOVERY_EMAIL_HELPER"
            values={{ email: user.email }}
          />
        </p>
        <div className="LogIn__inputBox">
          <span className="LogIn__inputTitle">
            <Text tid="AUTH.AUTH.CODE" />
          </span>
          <input
            className="LogIn__input"
            type="text"
            placeholder={t("AUTH.AUTH.ENTER_CODE")}
            value={fields[AUTH_USER.CONFIRM_CODE]}
            onChange={(e) =>
              changeField(AUTH_USER.CONFIRM_CODE, e.target.value)
            }
            required
            minLength={4}
            maxLength={30}
          />
        </div>
        <PrimaryButton
          className="LogIn__button"
          type="submit"
          disabled={isFormDisabled}
          loading={loading}
        >
          <span>
            <Text tid="AUTH.AUTH.NEXT" />
          </span>
        </PrimaryButton>

        {error && <AlertMessage message={errorMessage} type="error" />}
      </div>
    </form>
  );
};
