/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";

import { BetModal } from "../../components/BetModal/BetModal";
import { GAME_STATUSES } from "../../constants/games";
import { fix2 } from "../../utils/fixers";
import { Text } from "../../utils/Text";
import "./index.scss";

const DoubleBetView = ({
  isAlreadyInGame,
  winNumber,
  isRecruit,
  winColor,
  userBets,
  status,
}) => {
  const userWinBets = userBets?.filter?.((b) => b.color === winColor);
  const [timer, setTimer] = useState(null);

  const userWinSum = userWinBets?.reduce?.((acc, curr) => {
    if (curr?.color === winColor) return acc + (curr?.sumUsdt || 0);
    return acc;
  }, 0);

  const isPause = status === GAME_STATUSES.PAUSED || !isAlreadyInGame;

  useEffect(() => {
    if (timer) clearTimeout(timer);
    return () => setTimer(null);
  }, []);

  return (
    <div className="double__box">
      {!isRecruit && userBets?.length ? (
        <div
          className="double__win"
          style={{
            transition: "0.15s",
            maxHeight: `${isPause ? 78 : 0}px`,
            marginBottom: `${isPause ? 16 : 0}px`,
            padding: `${isPause ? 20 : 0}px 20px ${isPause ? 20 : 0}px 20px`,
            opacity: `${isPause ? 0 : 1}px`,
            overflow: "hidden",
          }}
        >
          <div className="double__win__title">
            {!userWinSum ? (
              <div className="double__win__title__win__fail">
                <Text tid="DOUBLE.DOUBLE_TAB.LOSE" />
              </div>
            ) : (
              <div className="double__win__title__win__success">
                <Text tid="DOUBLE.DOUBLE_TAB.WIN" />{" "}
                {winColor === "ZERO"
                  ? fix2(userWinSum * 14)
                  : fix2(userWinSum * 2)}
                $
              </div>
            )}
            <div
              className="double__win__title__win"
              style={{ opacity: `${isPause ? 0 : 1}px`, transition: "0.15s" }}
            >
              <Text tid="DOUBLE.DOUBLE_TAB.WIN_NUMBER" />{" "}
              <span
                className={`double__win__title__win-${winColor?.toLowerCase?.()}`}
                style={{ fontSize: 17, marginLeft: -4, marginBottom: -3 }}
              >
                &nbsp;{winNumber}
              </span>
            </div>
          </div>
        </div>
      ) : null}

      <BetModal />
    </div>
  );
};

export default DoubleBetView;
