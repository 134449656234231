import React, { useState } from "react";
import HeaderCrashView from "./View";

const HeaderCrash = () => {
  const [activeLink, setActiveLink] = useState("/");

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const [showPopup, setShowPopup] = useState(false);

  const [opacity, setOpacity] = useState(0);

  const handleMouseEnter = () => {
    setShowPopup(true);
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
    setOpacity(0);
  };

  return (
    <HeaderCrashView
      activeLink={activeLink}
      handleLinkClick={handleLinkClick}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      opacity={opacity}
      showPopup={showPopup}
    />
  );
};

export default HeaderCrash;
