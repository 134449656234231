/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import "./index.scss";
import AirdropLogin from "../AirdropLogin";
import AirdropWheel from "../AirdropWheel";
import parachute from "../../assets/img/ParachuteL.svg";
import Loader from "../../components/Loader";

const AirdropView = ({
  authorization,

  status,
  fetchData,
}) => {
  return (
    <div className="Airdrop">
      {!status && <Loader />}
      {status && (
        <React.Fragment>
          {authorization ? (
            <>
              <div className="Airdrop__title">
                <img src={parachute} alt="parachute" />
                <h1>Airdrop</h1>
              </div>
              <AirdropWheel />
            </>
          ) : (
            <AirdropLogin status={status} fetchData={fetchData} />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default AirdropView;
