import React from "react";
import { Text } from "../../utils/Text";
import "./index.scss";

export const GameHashPopup = ({ roundHash, isChatOpened, handleOpen }) => {
  return (
    <div
      className="classic-view-hash"
      style={{
        alignItems:
          window.innerWidth <= 1590 && isChatOpened ? "center" : "center",
      }}
    >
      <p onClick={handleOpen} style={{ cursor: "pointer" }}>
        <Text tid="CLASSIC.CLASSIC_TAB.OPEN_HASH" />
      </p>
      <p onClick={handleOpen} style={{ cursor: "pointer" }}>
        {roundHash}
      </p>
    </div>
  );
};
