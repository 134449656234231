import React, { useEffect, useState } from "react";

import AirdropWheelView from "./View";

const AirdropWheel = () => {
  const [animation, setAnimation] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [availableSpins, setAvailableSpins] = useState(2);
  const [prizeAmount, setPrizeAmount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  const Prizes = [
    {
      amount: "0.5$",
      number: 0,
    },
    {
      amount: "1$",
      number: 1,
    },
    {
      amount: "2$",
      number: 2,
    },
    {
      amount: "5$",
      number: 3,
    },
    {
      amount: "10$",
      number: 4,
    },
    {
      amount: "20$",
      number: 5,
    },
    {
      amount: "50$",
      number: 6,
    },
    {
      amount: "0.5$",
      number: 7,
    },
    {
      amount: "1$",
      number: 8,
    },
    {
      amount: "2$",
      number: 9,
    },
    {
      amount: "5$",
      number: 10,
    },
    {
      amount: "10$",
      number: 11,
    },
  ];

  const tamanyoRuleta = `${window.innerWidth > 540 ? 360 : 280}`;
  const numeroCasillas = Prizes.length;
  const anguloCasillas = 360 / numeroCasillas;
  const grados = (180 - anguloCasillas) / 2;
  const alturaCasilla = Math.tan((grados * Math.PI) / 180) * tamanyoRuleta;

  const getRandomColor = (i) => {
    if (i % 2 === 0) {
      return "#C73434";
    } else {
      return "#282828";
    }
  };

  useEffect(() => {
    const ruleta = document.querySelector(".ruleta");

    if (animation) {
      const randomNumber = `${Math.floor(Math.random() * numeroCasillas)}`;

      const numID = `number-${randomNumber}`;
      setPrizeAmount(Prizes[randomNumber].amount);
      const animacionRuleta = document.querySelector("#animacionRuleta");
      if (animacionRuleta) {
        animacionRuleta.remove();
      }
      const head = document.querySelector("head");
      if (head) {
        const animacionRuletaElement = document.createElement("style");
        animacionRuletaElement.id = "animacionRuleta";
        const num = numID.split("-")[1];
        const randomValue = Math.floor(Math.random() * 21) - 10 ?? 0;
        animacionRuletaElement.innerHTML = `#number-${num} { animation-name: number-${num}; animation-duration: 10s; animation-timing-function: ease-in-out; 
        ; } @keyframes number-${num} { from { transform: rotate(0); } to { transform: rotate(${
          360 * (numeroCasillas - 1) -
          anguloCasillas * num +
          Number(randomValue)
        }deg); }}`;
        head.appendChild(animacionRuletaElement);
        if (ruleta) {
          ruleta.removeAttribute("id");
          ruleta.setAttribute("id", numID);
        }
      }
      setAnimation(false);
    }
  }, [animation]);

  const startAnimation = () => {
    if (!isSpinning && availableSpins > 0) {
      setIsSpinning(true);
      setAnimation(true);
      setAvailableSpins(availableSpins - 1);
    }
  };

  const handleAnimationEnd = () => {
    setIsSpinning(false);
    setShowPopup(true);
  };

  return (
    <AirdropWheelView
      Prizes={Prizes}
      tamanyoRuleta={tamanyoRuleta}
      numeroCasillas={numeroCasillas}
      anguloCasillas={anguloCasillas}
      alturaCasilla={alturaCasilla}
      getRandomColor={getRandomColor}
      isSpinning={isSpinning}
      availableSpins={availableSpins}
      prizeAmount={prizeAmount}
      showPopup={showPopup}
      handleAnimationEnd={handleAnimationEnd}
      startAnimation={startAnimation}
      setShowPopup={setShowPopup}
    />
  );
};

export default AirdropWheel;
