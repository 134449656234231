import React from "react";

import "./index.scss";

import { Text } from "../../utils/Text";
import { fix8 } from "../../utils/fixers";
import PrimaryButton from "../../components/PrimaryButton";
import { currencyCodeParams } from "../../utils/currencyOptions";

export const PaymentListView = ({
  loading,
  loaded,
  error,
  list,
  isButtonExists,
  handleClickButton,
  type,
}) => {
  return (
    <React.Fragment>
      {isButtonExists && (
        <PrimaryButton onClick={handleClickButton} style={{ width: "200px" }}>
          <span>
            <Text tid={`WALLET.WALLET_POPUP.${type}`} />
          </span>
        </PrimaryButton>
      )}

      {/* {loaded && !list?.length && (
        <p className="payment-list-empty">
          <Text tid="MAIN.PAYMENT_LIST.EMPTY_LIST" />
        </p>
      )} */}
      {loaded && list?.length ? (
        <div className="payment-list-container">
          <h2 className="payment-list-title">
            <Text tid={`MAIN.PAYMENT_LIST.TITLE_${type}`} />
          </h2>
          <div className="payment-list-row heading">
            <span className="payment-list-heading">
              <Text tid="MAIN.PAYMENT_LIST.DATE" />
            </span>
            <span className="payment-list-heading">
              <Text tid="MAIN.PAYMENT_LIST.AMOUNT" />
            </span>
            <span className="payment-list-heading">
              <Text tid="MAIN.PAYMENT_LIST.STATUS" />
            </span>
          </div>
          <div className="payment-list-content">
            {list.map((item) => (
              <div className="payment-list-row">
                <span className="payment-list-item">
                  {new Date(item.createdAt).toLocaleString()}
                </span>
                <div className="payment-list-amount">
                  <span className="payment-list-item">{fix8(item.value)}</span>
                  <img
                    src={
                      currencyCodeParams[item.currency?.code?.toLowerCase?.()]
                        ?.icon
                    }
                    className="currency-icon"
                    alt=""
                  />
                </div>

                <span
                  className={`payment-list-item ${item.status.toLowerCase()}`}
                >
                  <Text tid={`MAIN.PAYMENT_LIST.STATUS_${item.status}`} />
                </span>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};
