/* eslint-disable no-plusplus */
import React, { useState, useEffect } from "react";
import RouletteView from "./View";

const Roulette = ({ gameData, isAlredyInGame, shouldMove }) => {
  const members = gameData?.members;
  const winner = gameData?.winner;

  const [transformWinner, setTransformWinner] = useState(140);
  const [usersToRender, setUsersToRender] = useState([]);

  let winnerIndexId = -1;

  useEffect(() => {
    if (winner?._id) {
      const renderUsers = [];

      for (let i = 0; i < members.length; i++) {
        const user = members[i];
        let chance = Math.round(Number(user.chance / 2));
        if (chance <= 1) chance = 1;
        for (let j = 0; j < chance; j++) renderUsers.push(user);
      }

      renderUsers.sort(() => (Math.random() <= 0.5 ? 1 : -1));

      if (winner?._id) {
        for (let j = 0; j < renderUsers.length; j++) {
          const member = renderUsers[j];
          if (String(winner?._id) === String(member._id)) {
            winnerIndexId = j;
            break;
          }
        }
      }

      setUsersToRender([...renderUsers, ...renderUsers, ...renderUsers]);
      if (winnerIndexId > -1) setTransformWinner(winnerIndexId + 50);
    }
  }, [gameData?.members?.length, winner?._id]);

  return (
    <RouletteView
      transformWinner={transformWinner}
      isAlredyInGame={isAlredyInGame}
      usersToRender={usersToRender}
      shouldMove={shouldMove}
    />
  );
};

export default Roulette;
