import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getData } from "../../utils/store";

import { SettingsNavigationView } from "./View";

export const SettingsNavigation = () => {
  const { user } = useSelector(({ user: userData }) => ({
    user: getData(userData.data),
  }));

  const location = useLocation();

  return <SettingsNavigationView location={location} user={user} />;
};
