import { combineReducers } from "redux";

import { auth } from "./auth";
import { login } from "./login";
import { signup } from "./signup";
import { user } from "./user";
import { recoveryAccountReset } from "./recoveryAccountReset";
import { recoveryAccountConfirm } from "./recoveryAccountConfirm";
import { recoveryAccountUpdate } from "./recoveryAccountUpdate";
import { settingsUpdatePassword } from "./settingsUpdatePassword";
import { settingsSendCode } from "./settingsSendCode";
import { settingsUpdateNickname } from "./settingsUpdateNickname";
import { settingsUpdatePhoto } from "./settingsUpdatePhoto";
import { settingsUpdateEmail } from "./settingsUpdateEmail";
import { game } from "./game";

import { topClassic } from "./topClassic";
import { socket } from "./socket";
import { online } from "./online";
import { topClassicBestUsers } from "./topClassicBestUsers";
import { historyClassic } from "./historyClassic";
import { signupConfirmCode } from "./signupConfirmCode";
import { settingsReferralLink } from "./settingsReferralLink";
import { settingsPartnerApplication } from "./settingsPartnerApplication";
import { balance } from "./balance";
import { settingsPartnerData } from "./settingsPartnerData";
import { historyDouble } from "./historyDouble";
import { withdrawCreate } from "./withdrawCreate";
import { withdrawPartnerCreate } from "./withdrawPartnerCreate";
import { depositCreate } from "./depositCreate";

import { withdrawList } from "./withdrawList";
import { withdrawPartnerList } from "./withdrawPartnerList";
import { depositList } from "./depositList";

import { chat } from "./chat";

export default combineReducers({
  auth,
  login,
  signup,
  balance,
  user,
  recoveryAccountReset,
  recoveryAccountConfirm,
  recoveryAccountUpdate,
  settingsUpdatePassword,
  settingsSendCode,
  settingsUpdateNickname,
  settingsUpdatePhoto,
  settingsUpdateEmail,
  game,
  topClassic,
  socket,
  online,
  topClassicBestUsers,
  historyClassic,
  signupConfirmCode,
  settingsReferralLink,
  settingsPartnerApplication,
  settingsPartnerData,
  historyDouble,
  withdrawCreate,
  withdrawPartnerCreate,
  depositCreate,
  withdrawList,
  withdrawPartnerList,
  depositList,
  chat,
});
