import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import crashBgImg from "../../assets/img/cardbg.svg";
import "./index.scss";

import iconcard1 from "../../assets/img/cardicon (1).svg";
import iconcard2 from "../../assets/img/cardicon (2).svg";
import iconcard3 from "../../assets/img/cardicon (3).svg";
import iconcard4 from "../../assets/img/cardicon (4).svg";
import iconcard5 from "../../assets/img/cardicon (5).svg";
import iconcard6 from "../../assets/img/cardicon (6).svg";
import iconcard7 from "../../assets/img/cardicon (7).svg";
import iconcard8 from "../../assets/img/cardicon (8).svg";
import iconcard9 from "../../assets/img/cardicon (9).svg";
import iconcard10 from "../../assets/img/cardicon (10).svg";
import iconcard11 from "../../assets/img/cardicon (11).svg";
import { getData } from "../../utils/store";

const icons = [
  iconcard1,
  iconcard2,
  iconcard3,
  iconcard4,
  iconcard5,
  iconcard6,
  iconcard7,
  iconcard8,
  iconcard9,
  iconcard10,
  iconcard11,
];

const Crash = () => {
  return (
    <div className="Crash">
      <div className="Crash__container">
        <span className="Crash__x">x1.00</span>
      </div>
    </div>
  );
};

export default Crash;
