const API_SLUG = "api";

const getDomain = () =>
  window.location.hostname.replace("www.", "").split(".").slice(-2).join(".");

export const getApiServer = () => {
  if (process.env.NODE_ENV === "development") return process.env.REACT_APP_API;

  const domain = getDomain();

  return `https://${API_SLUG}.${domain}`;
};

export const getWsServer = () => {
  if (process.env.NODE_ENV === "development") return process.env.REACT_APP_WS;

  const domain = getDomain();

  return `https://${API_SLUG}.${domain}`;
};

const API_SERVER = getApiServer();

export const API = {
  LOGIN: `${API_SERVER}/auth/login`,
  SIGNUP: `${API_SERVER}/auth/signup`,
  SIGNUP_CONFIRM: `${API_SERVER}/auth/signup/confirm`,
  USER_DATA: `${API_SERVER}/auth/user`,
  USER_REFERRAL_DATA: `${API_SERVER}/auth/user/referral`,
  USER_PARTNER_APPLICATION_SUBMIT: `${API_SERVER}/partner/application/submit`,
  USER_PARTNER_DATA: `${API_SERVER}/partner/info`,
  RECOVERY_ACCOUNT_UPDATE: `${API_SERVER}/auth/recovery/update-password`,
  RECOVERY_ACCOUNT_RESET: `${API_SERVER}/auth/recovery/send-code`,
  RECOVERY_ACCOUNT_CONFIRM: `${API_SERVER}/auth/recovery/check-code`,
  SETTINGS_SEND_CODE: `${API_SERVER}/settings/send-code`,
  SETTINGS_UPDATE_PASSWORD: `${API_SERVER}/settings/password`,
  SETTINGS_UPDATE_PHOTO: `${API_SERVER}/settings/photo`,
  SETTINGS_UPDATE_NICKNAME: `${API_SERVER}/settings/nickname`,
  SETTINGS_UPDATE_EMAIL: `${API_SERVER}/settings/email`,
  GET_TOP: `${API_SERVER}/top/classic`,
  GET_HISTORY_DOUBLE: `${API_SERVER}/history-double`,
  GET_HISTORY_CLASSIC: `${API_SERVER}/history/classic-last-game`,
  GET_HISTORY_CLASSIC_BEST_USERS: `${API_SERVER}/top/classic/three-best`,
  DEPOSIT_CREATE: `${API_SERVER}/deposit/create`,
  DEPOSIT_CANCEL: `${API_SERVER}/deposit/cancel`,
  DEPOSIT_LIST: `${API_SERVER}/deposit/list`,
  GET_DEPOSIT_DATA: `${API_SERVER}/deposit/data`,
  CREATE_WALLETS_DATA: `${API_SERVER}/deposit/wallets/create`,
  WITHDRAW_CREATE: `${API_SERVER}/withdraw/create`,
  WITHDRAW_LIST: `${API_SERVER}/withdraw/list`,
  WITHDRAW_PARTNER_CREATE: `${API_SERVER}/withdraw/partner/create`,
  WITHDRAW_PARTNER_LIST: `${API_SERVER}/withdraw/partner/list`,
  CONVERT_TOKEN_TO_USDT: `${API_SERVER}/user-balance/convert-token-to-usdt`,
  GET_FIAT_DEPOSIT_CURRENCIES: `${API_SERVER}/currencies/fiat/deposit`,
  RANGE_AMOUNT_WITHDRAW: (currencyCode, network) =>
    `${API_SERVER}/currencies/withdraw-range-amount/${currencyCode}/${network}`,
  RANGE_AMOUNT_DEPOSIT: (currencyCode, network) =>
    `${API_SERVER}/currencies/deposit-range-amount/${currencyCode}/${network}`,
  RANGE_AMOUNT_BET: (currencyCode) =>
    `${API_SERVER}/currencies/bet-range-amount/${currencyCode}`,
  AIRDROP_GET_STATUS: () => `${API_SERVER}/airdrop/status/get`,
  TELEGRAM_AUTH: () => `${API_SERVER}/airdrop/telegram/callback`,
  DISCORD_AUTH: () => `${API_SERVER}/airdrop/discord/callback`,
  TWITTER_AUTH: () => `${API_SERVER}/airdrop/twitter/callback`,
  SOCIALS_CHECK: () => `${API_SERVER}/airdrop/socials/check`,
  GET_USER_PROFILE: (_id) => `${API_SERVER}/public/users/get-profile/${_id}`,
  UPDATE_TOKENS: `${API_SERVER}/user-balance/update-usdt`,
};
