import React from "react";
import "./index.scss";
import TopOf from "../TopOf";
import HeaderTop from "../HeaderTop";
import TopWinners from "../../containers/TopWinners/index";

const TopTab = () => {
  return (
    <div className="body">
      <HeaderTop />
      <TopOf />
      <TopWinners />
    </div>
  );
};

export default TopTab;
