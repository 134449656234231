import {
  initRequestState,
  setRequestPending,
  setRequestSuccess,
  initRequestActions,
} from "../utils/store";

const initialState = {
  request: initRequestState(),
};

export const DOUBLE_HISTORY = initRequestActions("DOUBLE_HISTORY");

export const historyDouble = (state = initialState, action) => {
  switch (action.type) {
    case DOUBLE_HISTORY.LOADING:
      return { ...state, request: setRequestPending(state.request) };

    case DOUBLE_HISTORY.LOADED:
      return {
        ...state,
        request: setRequestSuccess(state.request, action.payload),
      };

    default:
      return state;
  }
};
