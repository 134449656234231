import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { gameIsRecruiting, gameIsSpin } from "../../utils/game-get-view-status";
import {
  gameGetTimeBeforePause,
  gameGetTimeLeft,
} from "../../utils/game-get-time-left";
import { getDoubleHistory } from "../../actions/historyDouble";
import { GAME_STATUSES } from "../../constants/games";
import WheelView from "./View";

const Wheel = () => {
  const dispatch = useDispatch();
  const { gameData } = useSelector(({ game }) => ({
    gameData: game.data,
  }));

  const [isAlreadyInGame, setIsAlreadyInGame] = useState(false);
  const [shouldMove, setShouldMove] = useState(false);

  const getRandomColor = (i) => {
    if (i === 0) return "#34C8AD";
    if (i % 2 === 0) return "#C73434";
    return "#282828";
  };

  const tamanyoRuleta = 360;
  const numeroCasillas = 13;
  const anguloCasillas = useMemo(() => 360 / numeroCasillas, [numeroCasillas]);
  const grados = useMemo(() => (180 - anguloCasillas) / 2, [anguloCasillas]);
  const alturaCasilla = useMemo(
    () => Math.tan((grados * Math.PI) / 180) * tamanyoRuleta,
    [grados, tamanyoRuleta]
  );

  const isRecruit = gameIsRecruiting(gameData);
  const timeLeft = gameGetTimeLeft(gameData);

  useEffect(() => {
    if (gameData.status === GAME_STATUSES.RECRUITED) setShouldMove(false);
    if (gameData.status === GAME_STATUSES.RECRUITED) setIsAlreadyInGame(true);
    if (gameData.status === GAME_STATUSES.SPIN && gameData?.members?.length) {
      setShouldMove(true);
    }
  }, [timeLeft, gameData.status]);

  useEffect(() => {
    if (gameData?.type === "DOUBLE") dispatch(getDoubleHistory());
  }, [gameData?._id]);

  const timeBeforePause = gameGetTimeBeforePause(gameData);
  const isSpin = gameIsSpin(gameData);

  return (
    <WheelView
      gameData={gameData}
      isSpin={isSpin}
      timeBeforePause={timeBeforePause}
      timeLeft={timeLeft}
      isRecruit={isRecruit}
      winNumber={gameData.roundColorNumber}
      numeroCasillas={numeroCasillas}
      anguloCasillas={anguloCasillas}
      getRandomColor={getRandomColor}
      tamanyoRuleta={tamanyoRuleta}
      alturaCasilla={alturaCasilla}
      isAlreadyInGame={isAlreadyInGame}
      shouldMove={shouldMove}
    />
  );
};

export default Wheel;
