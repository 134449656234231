import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Text } from "../../utils/Text";

import { getBestUsersClassic } from "../../actions/topClassicBestUsers";
import crown from "../../assets/img/CrownSimple.svg";
import trophy from "../../assets/img/Trophy.svg";
import dice from "../../assets/img/DiceSix.svg";
import { UserProfile } from "../UserProfile";
import { fix2 } from "../../utils/fixers";
import "./index.scss";

const Best = () => {
  const dispatch = useDispatch();
  const [needUpdate, setNeedUpdate] = useState(false);

  const { users, game } = useSelector(
    ({ topClassicBestUsers, game: gameData }) => ({
      users: topClassicBestUsers.data,
      game: gameData.data,
    })
  );

  useEffect(() => {
    if (game?._id) {
      if (game?.status !== "RECRUITED") setNeedUpdate(true);
      else if (needUpdate) {
        dispatch(getBestUsersClassic());
        setNeedUpdate(false);
      }
    }
  }, [game?._id, game?.status]);

  useEffect(() => {
    dispatch(getBestUsersClassic());
  }, []);

  if (!users) return null;

  return (
    <div className="best">
      <div className="best__container">
        <div className="best__winners">
          <div className="best__winner">
            <div className="best__user">
              <div className="best__winner-title">
                <img src={trophy} alt="" />
                <p className="best__winner-title-text">
                  <Text tid="CLASSIC.CLASSIC_TAB.LAST_WINNER" />
                </p>
              </div>
              {!users.lastWinner ? (
                <div className="best__no-data">
                  <Text tid="CLASSIC.CLASSIC_TAB.NO_DATA" />
                </div>
              ) : (
                <div style={{ maxWidth: "100%" }}>
                  <div className="best__winner-avatar">
                    <UserProfile user={users.lastWinner}>
                      <img src={users.lastWinner.photo} alt="avatar" />
                    </UserProfile>
                  </div>
                  <div className="best__winner-name">
                    <p className="best__winner-name-text">
                      {users.lastWinner && users.lastWinner.name}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="best__winner-amount">
              {users.lastWinner && (
                <>
                  <p className="best__winner-amount-value">
                    <Text tid="MAIN.MAIN.WIN" />:{" "}
                    <p>{fix2(users.lastWinner.win)}$</p>
                  </p>
                  <p className="best__winner-amount-chance">
                    <Text tid="MAIN.MAIN.CHANCE" />:
                    <p>{users.lastWinner.chance}%</p>
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="best__winner">
            <div className="best__user bg-crown">
              <div className="best__winner-title">
                <img src={crown} alt="" />
                <p className="best__winner-title-text">
                  <Text tid="CLASSIC.CLASSIC_TAB.TOP_WINNER_OF_THE_DAY" />
                </p>
              </div>
              {!users.todayTopWinner ? (
                <div className="best__no-data">
                  <Text tid="CLASSIC.CLASSIC_TAB.NO_DATA" />
                </div>
              ) : (
                <div style={{ maxWidth: "100%" }}>
                  <div className="best__winner-avatar">
                    <UserProfile user={users.todayTopWinner}>
                      <img src={users.todayTopWinner.photo} alt="avatar" />
                    </UserProfile>
                  </div>
                  <div className="best__winner-name">
                    <p className="best__winner-name-text">
                      {users.todayTopWinner && users.todayTopWinner.name}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="best__winner-amount">
              {users.todayTopWinner && (
                <>
                  <p className="best__winner-amount-value">
                    <Text tid="MAIN.MAIN.WIN" />:{" "}
                    <p>{fix2(users.todayTopWinner.win)}$</p>
                  </p>
                  <p className="best__winner-amount-chance">
                    <Text tid="MAIN.MAIN.CHANCE" />:
                    <p>{fix2(users.todayTopWinner.chance)}%</p>
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="best__winner">
            <div className="best__user bg-dice">
              <div className="best__winner-title">
                <img src={dice} alt="" />
                <p className="best__winner-title-text">
                  <Text tid="CLASSIC.CLASSIC_TAB.LUCKY_NUMBER" />
                </p>
              </div>
              {!users.todayLuckyWinner ? (
                <div className="best__no-data">
                  <Text tid="CLASSIC.CLASSIC_TAB.NO_DATA" />
                </div>
              ) : (
                <div style={{ maxWidth: "100%" }}>
                  <div className="best__winner-avatar">
                    <UserProfile user={users.todayLuckyWinner}>
                      <img src={users.todayLuckyWinner.photo} alt="avatar" />
                    </UserProfile>
                  </div>
                  <div className="best__winner-name">
                    <p className="best__winner-name-text">
                      {users.todayLuckyWinner && users.todayLuckyWinner.name}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="best__winner-amount">
              {users.todayLuckyWinner && (
                <>
                  <p className="best__winner-amount-value">
                    <Text tid="MAIN.MAIN.WIN" />:
                    <p>{fix2(users.todayLuckyWinner.win)}$</p>
                  </p>
                  <p className="best__winner-amount-chance">
                    <Text tid="MAIN.MAIN.CHANCE" />:
                    <p>{fix2(users.todayLuckyWinner.chance)}%</p>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Best;
