import React from "react";
import { Text } from "../../utils/Text";
import { useTranslation } from "react-i18next";

import "./index.scss";

import { RECOVERY_ACCOUNT_UPDATE } from "../../constants/fields";
import { AlertMessage } from "../../components/AlertMessage";
import PrimaryButton from "../../components/PrimaryButton";

import EyeOpen from "../../assets/img/eye-open-2.svg";
import EyeClosed from "../../assets/img/eye-close-3.svg";

export const RecoveryAccountUpdateView = ({
  fields,
  changeField,
  onSubmit,
  isFormDisabled,
  loading,
  error,
  errorMessage,
  passwordVisibility,
  setPasswordVisibility,
}) => {
  const { t } = useTranslation();

  return (
    <form className="LogIn" onSubmit={onSubmit}>
      <h1 className="LogIn__title">
        <Text tid="AUTH.AUTH.REPASSWORD" />
      </h1>
      <div className="LogIn__formBox">
        <div className="LogIn__form">
          <div className="LogIn__inputBox">
            <span className="LogIn__inputTitle">
              <Text tid="AUTH.AUTH.NEW_PASSWORD" />
            </span>
            <div style={{ position: "relative" }}>
              <input
                className="LogIn__input"
                type={
                  passwordVisibility[RECOVERY_ACCOUNT_UPDATE.PASSWORD]
                    ? "text"
                    : "password"
                }
                placeholder={t("AUTH.AUTH.ENTER_PASSWORDPL")}
                value={fields[RECOVERY_ACCOUNT_UPDATE.PASSWORD]}
                onChange={(e) =>
                  changeField(RECOVERY_ACCOUNT_UPDATE.PASSWORD, e.target.value)
                }
                required
                minLength={8}
                maxLength={30}
              />
              <span
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setPasswordVisibility((prevState) => ({
                    ...prevState,
                    [RECOVERY_ACCOUNT_UPDATE.PASSWORD]:
                      !prevState[RECOVERY_ACCOUNT_UPDATE.PASSWORD],
                  }))
                }
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={
                    passwordVisibility[RECOVERY_ACCOUNT_UPDATE.PASSWORD]
                      ? EyeOpen
                      : EyeClosed
                  }
                  alt="toggle visibility"
                />
              </span>
            </div>
          </div>
          <div className="LogIn__inputBox">
            <span className="LogIn__inputTitle">
              <Text tid="AUTH.AUTH.REPEAT_NEW_PASSWORD" />
            </span>
            <div style={{ position: "relative" }}>
              <input
                className="LogIn__input"
                type={
                  passwordVisibility[RECOVERY_ACCOUNT_UPDATE.REPEAT_PASSWORD]
                    ? "text"
                    : "password"
                }
                placeholder={t("AUTH.AUTH.REPEAT_PASSWORD")}
                value={fields[RECOVERY_ACCOUNT_UPDATE.REPEAT_PASSWORD]}
                onChange={(e) =>
                  changeField(
                    RECOVERY_ACCOUNT_UPDATE.REPEAT_PASSWORD,
                    e.target.value
                  )
                }
                required
                minLength={8}
                maxLength={30}
              />
              <span
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setPasswordVisibility((prevState) => ({
                    ...prevState,
                    [RECOVERY_ACCOUNT_UPDATE.REPEAT_PASSWORD]:
                      !prevState[RECOVERY_ACCOUNT_UPDATE.REPEAT_PASSWORD],
                  }))
                }
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={
                    passwordVisibility[RECOVERY_ACCOUNT_UPDATE.REPEAT_PASSWORD]
                      ? EyeOpen
                      : EyeClosed
                  }
                  alt="toggle visibility"
                />
              </span>
            </div>
          </div>
          <PrimaryButton
            className="LogIn__button"
            type="submit"
            disabled={isFormDisabled}
            loading={loading}
          >
            <span>
              <Text tid="AUTH.AUTH.NEXT" />
            </span>
          </PrimaryButton>

          {error && <AlertMessage message={errorMessage} type="error" />}
        </div>
      </div>
    </form>
  );
};
