import React from "react";

import { logOut } from "../../actions/login";

import { SettingsLogoutView } from "./View";

export const SettingsLogout = () => {
  const onSubmit = async (e) => {
    e.preventDefault();

    logOut();
  };

  return <SettingsLogoutView onSubmit={onSubmit} />;
};
