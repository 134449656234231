import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  isRequestPending,
  isRequestError,
  getErrorMessage,
  getData,
} from "../../utils/store";

import WithdrawView from "./View";
import { createWithdrawPartner } from "../../actions/withdrawPartnerCreate";
import { getPartnerInfo } from "../../actions/settingsPartnerData";
import { getWithdrawPartnerList } from "../../actions/withdrawPartnerList";

const WithdrawPartner = ({ opacity, setOpacity }) => {
  const dispatch = useDispatch();

  const copyRef = useRef(null);
  const [address, setAddress] = useState("");

  const { isLoading, isError, errorMessage, data } = useSelector(
    ({ withdrawPartnerCreate, settingsPartnerData }) => ({
      isLoading: isRequestPending(withdrawPartnerCreate.request),
      isError: isRequestError(withdrawPartnerCreate.request),
      errorMessage: getErrorMessage(withdrawPartnerCreate.request),
      data: getData(settingsPartnerData.data),
    })
  );

  const zIndex = opacity === 1 ? 999999 : -1;

  const handleBackgroundClick = (event) => {
    if (event.target.classList.contains("bg")) {
      setOpacity(0);
    }
  };

  const handlePopupClose = () => {
    setOpacity(0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      address,
    };

    const { success } = await dispatch(createWithdrawPartner(body));

    if (success) {
      await dispatch(getPartnerInfo());
      await dispatch(getWithdrawPartnerList());
      handlePopupClose();
    }
  };

  const isDisabled = !address;

  return (
    <WithdrawView
      setAddress={setAddress}
      address={address}
      copyRef={copyRef}
      handleSubmit={handleSubmit}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      disabled={isDisabled}
      opacity={opacity}
      zIndex={zIndex}
      handleBackgroundClick={handleBackgroundClick}
      data={data}
    />
  );
};

export default WithdrawPartner;
