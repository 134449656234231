import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";

import { useChatTrigger } from "../../hooks/use-chat-trigger";
import HeaderCards from "../../containers/HeaderCards/index";
import sandclock from "../../assets/img/hourglass.png";
import { doubleConnect } from "../../actions/game";
import BetCardsGame from "../BetCardsGame";
import Loader from "../Loader";
import Cards from "../Cards";
import "./index.scss";

const CardsGame = ({ isChatOpened, setCurrency, currency }) => {
  const { chatTimer, isOpenTrigger } = useChatTrigger({ isChatOpened });
  const { socket: st } = useSelector(({ socket }) => ({ socket }));
  const game = useSelector(({ game: gameData }) => gameData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(doubleConnect({ socket: st }));
  }, [game?.data?.type]);

  const isConnecting =
    game?.data?.type === "CLASSIC" || game.connecting || !game?.data?._id;

  return (
    <div
      className={`CardsGame ${
        isOpenTrigger || chatTimer ? "CardsGame-chat-opened" : ""
      }`}
    >
      <div className="comming-soon">
        <img src={sandclock} alt="sandclock" />
        Coming soon...
      </div>

      <HeaderCards />
      <Routes>
        <Route
          path="/"
          element={
            !isConnecting ? (
              <>
                <div className="CardsGame__box">
                  <BetCardsGame setCurrency={setCurrency} currency={currency} />
                  <Cards />
                </div>
              </>
            ) : (
              <div className="to-connecting-block" style={{ marginTop: 45 }}>
                <Loader />
              </div>
            )
          }
        />
      </Routes>
    </div>
  );
};

export default CardsGame;
