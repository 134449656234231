/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import "./index.scss";

import { BetModal } from "../../components/BetModal/BetModal";

const BidPopUpView = ({
  handleBackgroundClick,
  setOpacityBidPopUp,
  opacityBidPopUp,
  zIndex,
}) => {
  return (
    <div
      className="bg"
      style={{ opacity: opacityBidPopUp, zIndex, transition: "0.15s" }}
      onClick={handleBackgroundClick}
    >
      <div className="bidPopUp" style={{ opacity: opacityBidPopUp, zIndex }}>
        {opacityBidPopUp ? (
          <BetModal listenHandleBet={() => setOpacityBidPopUp(0)} />
        ) : null}
      </div>
    </div>
  );
};

export default BidPopUpView;
