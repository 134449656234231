import { axios } from "../utils/request";
import { API } from "../constants/api";

export const SETTINGS_PARTNER_APPLICATION = {
  LOADING: "SETTINGS_PARTNER_APPLICATION.LOADING",
  SUCCESS: "SETTINGS_PARTNER_APPLICATION.SUCCESS",
  ERROR: "SETTINGS_PARTNER_APPLICATION.ERROR",
  RESET: "SETTINGS_PARTNER_APPLICATION.RESET",
};

const setLoading = () => ({
  type: SETTINGS_PARTNER_APPLICATION.LOADING,
});

const setSuccess = (data) => ({
  type: SETTINGS_PARTNER_APPLICATION.SUCCESS,
  data,
});

const setError = (message) => ({
  type: SETTINGS_PARTNER_APPLICATION.ERROR,
  message,
});

export const resetSubmitApplication = () => ({
  type: SETTINGS_PARTNER_APPLICATION.RESET,
});

export const submitApplication = (payload) => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .post(API.USER_PARTNER_APPLICATION_SUBMIT, payload)
      .then(({ data }) => {
        dispatch(setSuccess(data));
      })
      .catch(({ response: { data } }) => {
        dispatch(setError(data.message));
      });
  };
};
