/* eslint-disable react/no-array-index-key */
import React from "react";
import { Text } from "../../utils/Text";
import "./index.scss";

import { currencyCodeParams } from "../../utils/currencyOptions";
import { fix8, fix2 } from "../../utils/fixers";
import { UserProfile } from "../UserProfile";

const PlayerBet = ({ bets, isFiat }) => {
  let ticketCounter = 0;

  const performedBets = [];

  bets.forEach((bet) => {
    const toTicket = Number((ticketCounter + bet.sumUsdt * 100).toFixed(0));
    const prevTicket = ticketCounter;
    ticketCounter = toTicket;

    performedBets.push({ ...bet, prevTicket: prevTicket + 1, toTicket });
  });

  performedBets.reverse();

  return (
    <div className="players">
      <div className="players__container">
        <p className="players__title">
          <Text tid="CLASSIC.CLASSIC_TAB.PLAYRES_BET" />
        </p>
        <div className="players__bets-container">
          {performedBets?.length ? (
            performedBets.map((bet, i) => {
              return (
                <div
                  className="players__bet"
                  key={i}
                  style={{
                    border: `2px solid #${bet.user.colorMain}75`,
                    backgroundColor: `#${bet.user.colorMain}50`,
                  }}
                >
                  <UserProfile user={bet.user}>
                    <img
                      className="players__bet-avatar"
                      alt={bet.user.nickname}
                      src={bet.user.photo}
                    />
                  </UserProfile>

                  <div className="players__bet-title">
                    <div className="players__bet-title-name">
                      {bet.user.nickname}
                    </div>
                    <div className="players__bet-title-tickets">
                      <Text tid="CLASSIC.CLASSIC_TAB.TICKET" /> #
                      {bet.prevTicket}
                      &nbsp;
                      <Text tid="CLASSIC.CLASSIC_TAB.TO" /> #{bet.toTicket}
                    </div>
                    <div className="players__bet-amount mobile">
                      <p className="players__main-text">
                        {isFiat ? `${fix2(bet.sumUsdt)}$` : fix8(bet.sumToken)}
                      </p>
                      <img
                        src={currencyCodeParams[bet?.tokenCode]?.icon}
                        alt="USDT"
                      />
                    </div>
                  </div>
                  <div className="players__bet-amount pc">
                    <p className="players__main-text">
                      {isFiat ? `${fix2(bet.sumUsdt)}$` : fix8(bet.sumToken)}
                    </p>
                    <img
                      src={currencyCodeParams[bet?.tokenCode]?.icon}
                      alt="USDT"
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <p
              style={{
                color: "white",
                opacity: 0.6,
                textAlign: "center",
                margin: "0 auto",
              }}
            >
              <Text tid="CLASSIC.CLASSIC_TAB.NO_BETS" />
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerBet;
