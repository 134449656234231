/* eslint-disable react/button-has-type */
import { Link } from "react-router-dom";
import React from "react";

import { Text } from "../../utils/Text";
import { GameHashPopup } from "../../components/GameHash";
import { ROUTES } from "../../constants/routes";
import { fix2 } from "../../utils/fixers";
import "./index.scss";

const BetView = ({
  handleBidPopUpClick,
  handleHashOpen,
  isOpenTrigger,
  roundHash,
  user,
  auth,
}) => {
  return (
    <div className="bet">
      <div className="bet__container">
        <ul
          className="bet__list"
          style={{
            flexDirection:
              window.innerWidth <= 1590 && isOpenTrigger ? "column" : "row",
            justifyContent:
              window.innerWidth <= 1590 && isOpenTrigger
                ? "center"
                : "space-between",
            alignItems:
              window.innerWidth <= 1590 && isOpenTrigger ? "center" : "center",
            gap: window.innerWidth <= 1590 && isOpenTrigger ? "20px" : "0px",
          }}
        >
          {user ? (
            <>
              <li className="bet__item">
                <span className="bet__item-title">
                  <Text tid="CLASSIC.CLASSIC_TAB.BID" />:{" "}
                  <span className="bet__item-value">
                    {fix2(user.totalBank)}$
                  </span>
                </span>
              </li>
              <li className="bet__item">
                <span className="bet__item-text media">
                  <Text tid="CLASSIC.CLASSIC_TAB.HIGHER" />
                  <br />
                  <Text tid="MAIN.MAIN.WINNING_CHANCE" />
                </span>
              </li>
              <li className="bet__item">
                <span className="bet__item-title">
                  <Text tid="CLASSIC.CLASSIC_TAB.CHANCE_OF_WINNING" />:{" "}
                  <span className="bet__item-value">{user.chance}%</span>
                </span>
              </li>
            </>
          ) : (
            <>
              <li className="bet__item">
                <span className="bet__item-title">
                  <Text tid="CLASSIC.CLASSIC_TAB.BID" />:{" "}
                  <span className="bet__item-value">0$</span>
                </span>
              </li>
              <li className="bet__item">
                <div className="bet__item-title">
                  <GameHashPopup
                    handleOpen={handleHashOpen}
                    isChatOpened={isOpenTrigger}
                    roundHash={roundHash}
                  />
                </div>
              </li>
            </>
          )}
          <li className="bet__item">
            {!auth?.user?.id ? (
              <Link to={ROUTES.LOGIN}>
                <button className="bet__make">
                  <span>
                    <Text tid="CLASSIC.CLASSIC_TAB.MAKE_BID" />
                  </span>
                </button>
              </Link>
            ) : (
              <button className="bet__make" onClick={handleBidPopUpClick}>
                <span>
                  <Text tid="CLASSIC.CLASSIC_TAB.MAKE_BID" />
                </span>
              </button>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BetView;
