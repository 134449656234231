import { SIGNUP_CONFIRM_CODE } from "../actions/signupConfirmCode";
import {
  initRequestState,
  setRequestError,
  setRequestPending,
  setRequestSuccess,
} from "../utils/store";

const initialState = {
  request: initRequestState(),
};

export const signupConfirmCode = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_CONFIRM_CODE.SUCCESS:
      return {
        ...state,
        request: setRequestSuccess(state.request, action.data),
      };

    case SIGNUP_CONFIRM_CODE.LOADING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };

    case SIGNUP_CONFIRM_CODE.ERROR:
      return {
        ...state,
        request: setRequestError(state.request, action.message),
      };

    default:
      return state;
  }
};
