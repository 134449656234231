import { axios } from "../utils/request";
import { API } from "../constants/api";

export const WITHDRAW_CREATE = {
  LOADING: "WITHDRAW_CREATE.LOADING",
  SUCCESS: "WITHDRAW_CREATE.SUCCESS",
  ERROR: "WITHDRAW_CREATE.ERROR",
};

const setLoading = () => ({
  type: WITHDRAW_CREATE.LOADING,
});

const setSuccess = (data) => ({
  type: WITHDRAW_CREATE.SUCCESS,
  data,
});

const setError = (message) => ({
  type: WITHDRAW_CREATE.ERROR,
  message,
});

export const createWithdraw = (payload) => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .post(API.WITHDRAW_CREATE, payload)
      .then(({ data }) => {
        dispatch(setSuccess(data));
        return { success: true };
      })
      .catch(({ response: { data } }) => {
        dispatch(setError(data.message));
        return { success: false };
      });
  };
};
