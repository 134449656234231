import {
  RECOVERY_ACCOUNT_RESET,
  RECOVERY_ACCOUNT_UPDATE,
  RECOVERY_ACCOUNT_CONFIRM,
} from "../constants/fields";

export const convertRecoveryAccountResetData = ({ email }) => ({
  [RECOVERY_ACCOUNT_RESET.EMAIL]: email,
});

export const convertRecoveryAccountConfirmData = ({ email, code }) => ({
  [RECOVERY_ACCOUNT_CONFIRM.EMAIL]: email,
  [RECOVERY_ACCOUNT_CONFIRM.CODE]: code,
});

export const convertRecoveryAccountUpdateData = ({
  email,
  code,
  password,
}) => ({
  [RECOVERY_ACCOUNT_UPDATE.EMAIL]: email,
  [RECOVERY_ACCOUNT_UPDATE.CODE]: code,
  [RECOVERY_ACCOUNT_UPDATE.PASSWORD]: password,
});
