import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getData } from "../../utils/store";

import QuestionsPopUpView from "./View";

const QuestionsPopUp = ({
  setIsSettingsPopUpOpen,
  isSettingsPopUpOpen,
  setOpacitySettings,
  isMob = false,
}) => {
  const { user } = useSelector(({ user: userData }) => ({
    user: getData(userData.data),
  }));

  const location = useLocation();

  const toggleSettingsPopUp = () => {
    // setIsSettingsPopUpOpen(!isSettingsPopUpOpen);
    // setOpacitySettings(isSettingsPopUpOpen ? 0 : 1);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isSettingsPopUpOpen && !event.target.classList.contains("help")) {
        setIsSettingsPopUpOpen(false);
        if (setOpacitySettings) setOpacitySettings(0);
      }
    };

    if (isSettingsPopUpOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isSettingsPopUpOpen, setIsSettingsPopUpOpen, setOpacitySettings]);

  return (
    <QuestionsPopUpView
      isSettingsPopUpOpen={isSettingsPopUpOpen}
      toggleSettingsPopUp={toggleSettingsPopUp}
      location={location}
      isMob={isMob}
      user={user}
    />
  );
};

export default QuestionsPopUp;
