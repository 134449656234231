/* eslint-disable react/no-array-index-key */
import React from "react";
import { performElementEndAmination } from "./AnimMath";
import arrow from "../../assets/img/CaretDoubleUp.svg";
import { RouletteMediaView } from "./Media";
import "./index.scss";

const RouletteView = ({
  transformWinner,
  isAlredyInGame,
  usersToRender,
  shouldMove,
}) => {
  const rouletteStatus = shouldMove ? "roulette-move" : "roulette-stopped";

  let END_ANIM = 0;
  let START_ANIM = 0;

  if (isAlredyInGame) START_ANIM = performElementEndAmination(9);
  else START_ANIM = performElementEndAmination(transformWinner);

  END_ANIM = performElementEndAmination(transformWinner);

  START_ANIM = `translateX(${START_ANIM}%)`;
  END_ANIM = `translateX(${END_ANIM}%)`;

  return (
    <RouletteMediaView>
      <div className="roulette">
        <div className="roulette__container">
          <div className="roulette__container-wrapper">
            <div
              className={`roulette__users ${rouletteStatus}`}
              style={{
                "--anim-timer": isAlredyInGame ? "13s" : "2s",
                "--anim-start-transform": START_ANIM,
                "--anim-end-transform": END_ANIM,
              }}
            >
              {usersToRender.map((user, i) => (
                <img
                  className={`roulette__user-avatar ${user.color} roulette__user-avatar-key-${i}`}
                  src={user?.photo}
                  alt="avatar"
                  key={i}
                />
              ))}
            </div>
          </div>
          <div className="roulette__arrow">
            <div className="roulette__arrow-container">
              <div
                className="roulette__arrow-line"
                style={{
                  background: "linear-gradient(45deg, #326fe600, #326fe650)",
                }}
              />
              <img
                src={arrow}
                alt="arrow"
                style={{ borderRadius: 32, height: 24 }}
              />
              <div
                className="roulette__arrow-line"
                style={{
                  background: "linear-gradient(45deg, #326fe650, #326fe600)",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </RouletteMediaView>
  );
};

export default RouletteView;
