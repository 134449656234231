import { axios } from "../utils/request";

import { API } from "../constants/api";
import { ROUTES } from "../constants/routes";
import { convertRecoveryAccountConfirmData } from "../api/recovery";

export const RECOVERY_ACCOUNT_CONFIRM = {
  LOAD_SUCCESS: "RECOVERY_ACCOUNT_CONFIRM.LOAD_SUCCESS",
  LOAD_PENDING: "RECOVERY_ACCOUNT_CONFIRM.LOAD_PENDING",
  LOAD_FAIL: "RECOVERY_ACCOUNT_CONFIRM.LOAD_FAIL",
};

const setFail = (message) => ({
  type: RECOVERY_ACCOUNT_CONFIRM.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: RECOVERY_ACCOUNT_CONFIRM.LOAD_SUCCESS,
  data,
});

const setLoading = () => ({
  type: RECOVERY_ACCOUNT_CONFIRM.LOAD_PENDING,
});

export const sendRecoveryAccountConfirm = (payloadData, navigate) => {
  const payload = convertRecoveryAccountConfirmData(payloadData);

  return async (dispatch) => {
    dispatch(setLoading());
    try {
      await axios.post(API.RECOVERY_ACCOUNT_CONFIRM, payload);
      dispatch(setSuccess(payload));
      navigate(ROUTES.RECOVERY_ACCOUNT_UPDATE);
    } catch ({ response: { data = {} } = { response: { data: {} } } }) {
      dispatch(setFail(data.message));
    }
  };
};
