import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SettingsUpdateNicknameView } from "./View";

import {
  resetUpdateNickname,
  updateNickname,
} from "../../actions/settingsUpdateNickname";
import { SETTINGS } from "../../constants/fields";
import {
  getData,
  getErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from "../../utils/store";
import { getUser } from "../../actions/user";

export const SettingsUpdateNickname = () => {
  const { isLoading, isSuccess, isError, errorMessage, user } = useSelector(
    ({ settingsUpdateNickname, user: userData }) => ({
      isLoading: isRequestPending(settingsUpdateNickname.request),
      isSuccess: isRequestSuccess(settingsUpdateNickname.request),
      isError: isRequestError(settingsUpdateNickname.request),
      errorMessage: getErrorMessage(settingsUpdateNickname.request),
      user: getData(userData.data),
    })
  );

  const dispatch = useDispatch();

  const [fields, setFields] = useState({ nickname: user.nickname });

  useEffect(() => {
    return () => {
      dispatch(resetUpdateNickname());
    };
  }, []);

  const changeField = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { success } = await dispatch(
      updateNickname({
        [SETTINGS.NICKNAME]: fields.nickname,
      })
    );

    if (success) {
      dispatch(getUser());
    }
  };

  const isFormDisabled =
    !fields.nickname ||
    fields.nickname === user.nickname ||
    fields.nickname.length > 20;

  return (
    <SettingsUpdateNicknameView
      fields={fields}
      changeField={changeField}
      disabled={isFormDisabled}
      onSubmit={onSubmit}
      loading={isLoading}
      error={isError}
      errorMessage={errorMessage}
      success={isSuccess}
    />
  );
};
