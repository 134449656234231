import { axios } from "../utils/request";
import { API } from "../constants/api";

export const SETTINGS_UPDATE_EMAIL = {
  LOADING: "SETTINGS_UPDATE_EMAIL.LOADING",
  SUCCESS: "SETTINGS_UPDATE_EMAIL.SUCCESS",
  ERROR: "SETTINGS_UPDATE_EMAIL.ERROR",
  RESET: "SETTINGS_UPDATE_EMAIL.RESET",
};

const setLoading = () => ({
  type: SETTINGS_UPDATE_EMAIL.LOADING,
});

const setSuccess = () => ({
  type: SETTINGS_UPDATE_EMAIL.SUCCESS,
});

const setError = (message) => ({
  type: SETTINGS_UPDATE_EMAIL.ERROR,
  message,
});

export const resetUpdateEmail = () => ({
  type: SETTINGS_UPDATE_EMAIL.RESET,
});

export const updateEmail = (payload) => {
  return (dispatch) => {
    dispatch(setLoading());

    return axios
      .patch(API.SETTINGS_UPDATE_EMAIL, payload)
      .then(() => {
        dispatch(setSuccess());
        return { success: true };
      })
      .catch(({ response: { data } }) => {
        dispatch(setError(data.message));
        return { sucess: false };
      });
  };
};
