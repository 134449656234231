import { useSelector } from "react-redux";
import React from "react";

import { gameGetTimeBeforeBegin } from "../../utils/game-get-time-left";
import TicketView from "./View";

const Ticket = () => {
  const { isConnecting, gameData } = useSelector(({ game }) => ({
    isConnecting: game.connecting,
    gameData: game.data,
  }));

  const timeLeftBegin = gameGetTimeBeforeBegin(gameData);

  if (isConnecting) return null;

  const totalBank = gameData?.totalBank;
  const winner = gameData?.winner;

  const minutes = Math.floor(timeLeftBegin / 60);
  const seconds = timeLeftBegin % 60;

  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return (
    <TicketView
      winTicket={gameData.winTicket}
      formattedTime={formattedTime}
      totalBank={totalBank}
      winner={winner}
    />
  );
};

export default Ticket;
