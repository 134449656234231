import React from "react";
import { Text } from "../../utils/Text";

const DoubleHistoryView = ({ rolls, redCount, blackCount, zeroCount }) => {
  return (
    <div className="double-history">
      <div className="double-history__previous-rolls">
        <div className="double-history__previous-rolls__title">
          <Text tid="MAIN.MAIN.PREVIOUS_ROLLS" />
        </div>
        <div className="double-history__previous-rolls__rolls">
          {rolls.map((roll, index) => {
            if (index >= 10) return null;
            return (
              <div
                key={index}
                style={{
                  backgroundColor: roll.color,
                }}
                className="double-history__previous-rolls__roll"
              >
                <span>{roll?.roundColorNumber}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="double-history__last-100">
        <div className="double-history__last-100__title">
          <Text tid="MAIN.MAIN.LAST_100" />
        </div>
        <div className="double-history__last-100__rolls">
          <div className="double-history__last-100__roll">
            <div
              className="double-history__last-100__roll__image"
              style={{
                backgroundColor: "#242424",
              }}
            />
            <div className="double-history__last-100__roll__number">
              {blackCount}
            </div>
          </div>
          <div className="double-history__last-100__roll">
            <div
              className="double-history__last-100__roll__image"
              style={{
                backgroundColor: "#34c8ad",
              }}
            />
            <div className="double-history__last-100__roll__number">
              {zeroCount}
            </div>
          </div>
          <div className="double-history__last-100__roll">
            <div
              className="double-history__last-100__roll__image"
              style={{
                backgroundColor: "#c73434",
              }}
            />
            <div className="double-history__last-100__roll__number">
              {redCount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoubleHistoryView;
