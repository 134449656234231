import React from "react";

import "./index.scss";

import { Text } from "../../utils/Text";
import copy from "../../assets/img/Copy.svg";

export const SettingsReferralLinkView = ({
  copyRef,
  handleCopyClick,
  link,
  loaded,
  data,
  withReferralInfo,
}) => {
  return (
    <React.Fragment>
      <div style={{}}>
        <div className="wallet__adress">
          <p className="wallet__adress-text">
            <Text tid="SETTINGS.SETTINGS_TAB.REFERRAL_LINK_DESCRIPTION" />
          </p>
          <p className="wallet__adress-text">
            <Text tid="SETTINGS.SETTINGS_TAB.REFERRAL_LINK" />
          </p>
          <div
            className="wallet__adress-box"
            ref={copyRef}
            style={{ maxWidth: "355px" }}
            onClick={handleCopyClick}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                handleCopyClick();
              }
            }}
            role="button"
            tabIndex={0}
          >
            <p className="wallet__adress-box-text">{link}</p>
            <img src={copy} alt="" />
          </div>
        </div>
        {loaded && withReferralInfo && (
          <div className="best_winner-container">
            <div className="best__winner">
              <div className="best__user">
                <div className="best__winner-title">
                  <p className="best__winner-title-text">
                    <Text tid="SETTINGS.SETTINGS_TAB.REFERRAL_STATISTICS_TOTAL" />
                  </p>
                </div>
                <p className="best__winner-title-text big">
                  {data.totalInvited}
                </p>
              </div>
            </div>
            {data.referrer && (
              <div className="best__winner">
                <div className="best__user">
                  <div className="best__winner-title">
                    <p className="best__winner-title-text">
                      <Text tid="SETTINGS.SETTINGS_TAB.REFERRAL_STATISTICS_REFERRAL" />
                    </p>
                  </div>
                  <p
                    className="best__winner-title-text big"
                    style={{ fontSize: "16px" }}
                  >
                    {data.referrer}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
