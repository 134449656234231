/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
import React from "react";

import { Text } from "../../utils/Text";
import "./index.scss";

export const ChatRulePopup = ({ isRulesOpen, setIsRulesOpen }) => {
  const handleClose = () => setIsRulesOpen(false);

  const handleBackgroundClick = (event) => {
    if (event.target.classList.contains("bg")) handleClose();
  };

  return (
    <>
      <div
        className="bg hashContainer"
        style={{
          opacity: isRulesOpen ? 1 : 0,
          transition: "0.3s ease-in-out",
          zIndex: isRulesOpen ? 10000 : -1,
        }}
        onClick={handleBackgroundClick}
      >
        <div
          className="rulePopUp bidPopUp "
          style={{
            opacity: isRulesOpen ? 1 : 0,
            visibility: isRulesOpen ? "visible" : "hidden",
            zIndex: isRulesOpen ? 10000 : -1,
            background: "rgb(32, 32, 32)",
            padding: "18px 16px 24px",
            maxWidth: "600px",
            width: "80%",
            transition: "0.3s ease-in-out",
          }}
        >
          <div className="bidPopUp__container" style={{ height: "auto" }}>
            <div
              style={{ textAlign: "center", marginBottom: 12, color: "#fff" }}
            >
              <Text tid="MAIN.CHAT.RULE" />
            </div>
            <hr className="bidPopUp__header-line" style={{ opacity: 0.25 }} />
            <br />
          </div>
          <div
            style={{
              textAlign: "center",
              color: "#CACACA",
              fontWeight: 400,
              fontSize: 13,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                textAlign: "left",
              }}
            >
              <p>
                <Text tid="MAIN.CHAT.RULE_1" />
              </p>
              <p>
                <Text tid="MAIN.CHAT.RULE_2" />
              </p>
              <p>
                <Text tid="MAIN.CHAT.RULE_3" />
              </p>
              <p>
                <Text tid="MAIN.CHAT.RULE_4" />
              </p>
              <p>
                <Text tid="MAIN.CHAT.RULE_5" />
              </p>
              <p>
                <Text tid="MAIN.CHAT.RULE_6" />
              </p>
              <p>
                <Text tid="MAIN.CHAT.RULE_7" />
              </p>
              <p>
                <Text tid="MAIN.CHAT.RULE_8" />
              </p>
              <p>
                <Text tid="MAIN.CHAT.RULE_9" />
              </p>
              <p>
                <Text tid="MAIN.CHAT.RULE_10" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
