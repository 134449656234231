import usdt from "../assets/img/USDT.svg";
import usdc from "../assets/img/USDC.svg";
import usd from "../assets/img/USD.svg";
import btc from "../assets/img/BTC.svg";
import eth from "../assets/img/ETH.svg";
import ltc from "../assets/img/LTC.svg";
import trx from "../assets/img/TRX.svg";
import uah from "../assets/img/uah.svg";
import eur from "../assets/img/eur.svg";
import kzt from "../assets/img/kzt.svg";
import inr from "../assets/img/inr.svg";

export const currencyCodeParams = {
  usdt: { icon: usdt },
  usd: { icon: usd },
  usdc: { icon: usdc },
  ltc: { icon: ltc },
  btc: { icon: btc },
  eth: { icon: eth },
  uah: { icon: uah },
  eur: { icon: eur },
  kzt: { icon: kzt },
  inr: { icon: inr },
};

export const networkParams = {
  litecoin: { icon: ltc, label: "Litecoin" },
  bitcoin: { icon: btc, label: "Bitcoin" },
  trc20: { icon: trx, label: "TRC20" },
  bep20: { icon: btc, label: "BEP20" },
  erc20: { icon: eth, label: "ERC20" },
};
