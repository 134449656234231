import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import "./index.scss";
import HeaderDouble from "../../containers/HeaderDouble/index";
import Wheel from "../../containers/Wheel/index";
import DoubleUsersBet from "../DoubleUsersBet";
import DoubleBet from "../../containers/DoubleBet/index";
import DoubleHistory from "../../containers/DoubleHistory";
import { doubleConnect } from "../../actions/game";
import Loader from "../Loader";

const DoubleGame = () => {
  const { socket: st } = useSelector(({ socket }) => ({ socket }));
  const game = useSelector(({ game: gameData }) => gameData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (game?.data?.type && game?.data?.type !== "DOUBLE")
      dispatch(doubleConnect({ socket: st }));
  }, [game?.data?.type]);

  const isConnecting =
    game?.data?.type === "CLASSIC" || game.connecting || !game?.data?._id;

  return (
    // <div className="DoubleGame">
    <div className="body">
      <HeaderDouble />
      <Routes>
        <Route
          path="/"
          element={
            !isConnecting ? (
              <>
                <div className="doubleGame__box">
                  <Wheel />
                  <DoubleBet />
                </div>
                <DoubleHistory />
                <DoubleUsersBet />
              </>
            ) : (
              <div className="to-connecting-block" style={{ marginTop: 45 }}>
                <Loader />
              </div>
            )
          }
        />
      </Routes>
    </div>
  );
};

export default DoubleGame;
