import { ROUTES } from "./routes";

export const HELP = {
  SUPPORT: "support@crypto-fast.com",
  OFFERS: "offers@crypto-fast.com",
  POLICY_DATE: "01.01.2024 00:00",
  TERMS: ROUTES.TERMS_OF_USE,
  POLICY: ROUTES.POLICY,
  FAQ: ROUTES.FAQ,
};

export const EXTERNAL_LINKS = {
  TELEGRAM: "https://google.com",
  TWITTER: "https://google.com",
  DISCORD: "https://google.com",
};
