import { axios } from "../utils/request";

import { API } from "../constants/api";
import { ROUTES } from "../constants/routes";
import { convertRecoveryAccountResetData } from "../api/recovery";

export const RECOVERY_ACCOUNT_RESET = {
  LOAD_SUCCESS: "RECOVERY_ACCOUNT_RESET.LOAD_SUCCESS",
  LOAD_PENDING: "RECOVERY_ACCOUNT_RESET.LOAD_PENDING",
  LOAD_FAIL: "RECOVERY_ACCOUNT_RESET.LOAD_FAIL",
};

const setFail = (message) => ({
  type: RECOVERY_ACCOUNT_RESET.LOAD_FAIL,
  message,
});

const setSuccess = (data) => ({
  type: RECOVERY_ACCOUNT_RESET.LOAD_SUCCESS,
  data,
});

const setLoading = () => ({
  type: RECOVERY_ACCOUNT_RESET.LOAD_PENDING,
});

export const sendRecoveryAccountReset = (payloadData, navigate) => {
  const payload = convertRecoveryAccountResetData(payloadData);

  return async (dispatch) => {
    try {
      dispatch(setLoading());
      await axios.post(API.RECOVERY_ACCOUNT_RESET, payload);
      dispatch(setSuccess(payload));
      navigate(ROUTES.RECOVERY_ACCOUNT_CONFIRM);
    } catch ({ response: { data = {} } = { response: { data: {} } } }) {
      dispatch(setFail(data.message));
    }
  };
};
