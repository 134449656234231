import React from "react";
import { useTranslation } from "react-i18next";

import { Text } from "../../utils/Text";
import { SETTINGS } from "../../constants/fields";
import PrimaryButton from "../../components/PrimaryButton";
import { AlertMessage } from "../../components/AlertMessage";
import { SettingsConfirmCode } from "../settings-confirm-code";

export const SettingsUpdateEmailView = ({
  fields,
  changeField,
  disabled,
  onSubmit,
  submitSendCode,
  loading,
  error,
  errorMessage,
  success,
  isUpdateEmailSuccess,
  isUpdateEmailLoading,
  isUpdateEmailError,
  updateEmailErrorMessage,
  sendCodeData,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div>
        <p className="settings-security__password-box-title">
          <Text tid="SETTINGS.SETTINGS_TAB.EMAIL" />
        </p>
        {success && sendCodeData.type === "email" ? (
          <SettingsConfirmCode
            onSubmit={onSubmit}
            isLoading={isUpdateEmailLoading}
            isError={isUpdateEmailError}
            errorMessage={updateEmailErrorMessage}
          />
        ) : (
          <form
            onSubmit={submitSendCode}
            className="settings-security__password-box"
          >
            <div>
              <p className="settings-security__password-box-text text">
                <Text tid="SETTINGS.SETTINGS_TAB.EMAIL" />
              </p>
              <div className="settings-security__password-box-input-wrapper">
                <input
                  className="settings-security__password-box-input input"
                  type="email"
                  placeholder={t("SETTINGS.SETTINGS_TAB.ENTER_EMAIL")}
                  value={fields[SETTINGS.EMAIL]}
                  onChange={(e) => changeField(SETTINGS.EMAIL, e.target.value)}
                  required
                  minLength={4}
                  maxLength={30}
                />
              </div>
            </div>

            <PrimaryButton
              type="submit"
              disabled={disabled}
              loading={loading && sendCodeData.type === "email"}
              // className="settings-security__password-box-button button"
            >
              <span>
                <Text tid="MAIN.MAIN.SAVE" />
              </span>
            </PrimaryButton>

            {error && sendCodeData.type === "email" && (
              <AlertMessage message={errorMessage} type="error" />
            )}
            {isUpdateEmailSuccess && (
              <AlertMessage
                message="SETTINGS.SETTINGS_TAB.UPDATE_EMAIL_SUCCESS"
                type="success"
              />
            )}
          </form>
        )}
      </div>
    </React.Fragment>
  );
};
