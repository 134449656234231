import { SETTINGS_PARTNER_APPLICATION } from "../actions/settingsPartnerApplication";
import {
  initRequestState,
  setRequestError,
  setRequestPending,
  setRequestSuccess,
} from "../utils/store";

const initialState = {
  request: initRequestState(),
};

export const settingsPartnerApplication = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_PARTNER_APPLICATION.SUCCESS:
      return {
        ...state,
        request: setRequestSuccess(state.request),
      };

    case SETTINGS_PARTNER_APPLICATION.LOADING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };

    case SETTINGS_PARTNER_APPLICATION.ERROR:
      return {
        ...state,
        request: setRequestError(state.request, action.message),
      };

    case SETTINGS_PARTNER_APPLICATION.RESET:
      return {
        ...state,
        request: initRequestState(),
      };

    default:
      return state;
  }
};
