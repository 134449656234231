import React from "react";

import cad from "../../assets/img/CAD.png";
import cny from "../../assets/img/CNY.png";
import ddk from "../../assets/img/DKK.png";
import eur from "../../assets/img/EUR.png";
import idr from "../../assets/img/IDR.png";
import inr from "../../assets/img/INR.png";
import jpy from "../../assets/img/JPY.png";
import krw from "../../assets/img/KRW.png";
import mxn from "../../assets/img/MXN.png";
import php from "../../assets/img/PHP.png";
import rub from "../../assets/img/RUB.png";
import usd from "../../assets/img/USD.png";
import r$ from "../../assets/img/R$.png";

import WalletSettingsView from "./View";

const WalletSettings = ({
  opacityWalletSettings,
  setOpacityWalletSettings,
}) => {
  return null;

  const currencyOptions = [
    { value: "cad", label: "CAD", icon: cad },
    { value: "cny", label: "CNY", icon: cny },
    { value: "ddk", label: "DDK", icon: ddk },
    { value: "eur", label: "EUR", icon: eur },
    { value: "idr", label: "IDR", icon: idr },
    { value: "inr", label: "INR", icon: inr },
    { value: "jpy", label: "JPY", icon: jpy },
    { value: "krw", label: "KRW", icon: krw },
    { value: "mxn", label: "MXN", icon: mxn },
    { value: "php", label: "PHP", icon: php },
    { value: "rub", label: "RUB", icon: rub },
    { value: "usd", label: "USD", icon: usd },
    { value: "r$", label: "R$", icon: r$ },
  ];

  const zIndex = opacityWalletSettings === 1 ? 99999 : -1;

  const handleBackgroundClick = (event) => {
    if (event.target.classList.contains("bg")) {
      setOpacityWalletSettings(0);
    }
  };

  const handlePopupClose = () => {
    setOpacityWalletSettings(0);
  };

  return (
    <WalletSettingsView
      opacityWalletSettings={opacityWalletSettings}
      handleBackgroundClick={handleBackgroundClick}
      handlePopupClose={handlePopupClose}
      currencyOptions={currencyOptions}
      zIndex={zIndex}
    />
  );
};

export default WalletSettings;
