import { ONLINE } from "../actions/online";
import { initSocketState } from "../utils/store";

const initialState = initSocketState;

export const online = (state = initialState, action) => {
  switch (action.type) {
    case ONLINE.CONNECTED:
      return state;
    case ONLINE.UPDATING_TOTAL:
      return state;
    case ONLINE.CONNECTING:
      return state;
    case ONLINE.UPDATED_TOTAL:
      return { ...state, data: { ...state.data, total: action.payload } };
    default:
      return state;
  }
};
