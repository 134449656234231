import { BALANCE } from "../actions/balance";
import { initSocketState } from "../utils/store";

const initialState = initSocketState;

export const balance = (state = initialState, action) => {
  switch (action.type) {
    case BALANCE.CONNECTING:
      return { ...state, connecting: true, connected: false };
    case BALANCE.CONNECTED:
      return {
        ...state,
        connected: true,
        connecting: false,
        data: action.payload.balances,
        totalUsdt: action.payload.totalUsdt,
      };
    case BALANCE.UPDATING:
      return { ...state, updating: true, updated: false };
    case BALANCE.UPDATED:
      return { ...state, updating: false, updated: true, data: action.payload };
    case BALANCE.SELECT:
      return { ...state, selected: action.payload };
    case BALANCE.HIDE_ZERO:
      return { ...state, isHide: action.payload };
    case BALANCE.FIAT:
      return { ...state, isFiat: action.payload };
    default:
      return state;
  }
};
