import { RECOVERY_ACCOUNT_RESET } from "../actions/recoveryAccountReset";
import {
  initRequestWithDataState,
  setRequestError,
  setRequestPending,
  setRequestSuccess,
} from "../utils/store";

const initialState = {
  request: initRequestWithDataState(),
};

export const recoveryAccountReset = (state = initialState, action) => {
  switch (action.type) {
    case RECOVERY_ACCOUNT_RESET.LOAD_SUCCESS:
      return {
        ...state,
        request: setRequestSuccess(state.request, action.data),
      };

    case RECOVERY_ACCOUNT_RESET.LOAD_PENDING:
      return {
        ...state,
        request: setRequestPending(state.request),
      };

    case RECOVERY_ACCOUNT_RESET.LOAD_FAIL:
      return {
        ...state,
        request: setRequestError(state.request, action.message),
      };
    default:
      return state;
  }
};
