import { CHAT } from "../actions/chat";
import { initSocketState } from "../utils/store";

const initialState = initSocketState;

export const chat = (state = initialState, action) => {
  switch (action.type) {
    case CHAT.CONNECTED:
      return {
        ...state,
        connected: true,
        connecting: false,
        data: action.payload,
      };
    case CHAT.UPDATING:
      return { ...state, updating: true, updated: false };
    case CHAT.CONNECTING:
      return { ...state, connecting: true, connected: false };
    case CHAT.UPDATED:
      return { ...state, updating: false, updated: true, data: action.payload };
    default:
      return state;
  }
};
